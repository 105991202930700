import {
  Autocomplete,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { InputForm } from "./InputForm";
import { organizationTypeItems } from "constants";
import { codeDocumentsType } from "constants";
import { getSearchSiigoCities } from "services";
import { EmailInput, PhoneNumberInput } from "commons/inputs";
import { PHONE_NUMBER_VALIDATOR } from "utils";
import { SearchIcon } from "assets";

export const FormBilling = ({
  errors,
  formValues,
  setValue,
  register,
  isEditable,
  isCreateBilling,
  inputAccountUser = null,
}) => {
  const [siigoCities, setSiigoCities] = useState([]);

  const handlGetSiigoCities = (value) => {
    getSearchSiigoCities(value).then(setSiigoCities);
  };

  return (
    <Stack gap={2}>
      <InputForm
        label="Tipo de Organización"
        name="organization_type"
        errors={errors}
        required
        customInput={
          <Select
            name="organization_type"
            size="small"
            variant="outlined"
            displayEmpty={true}
            style={{ fontSize: "14px" }}
            value={formValues.organization_type || ""}
            disabled={!isEditable}
            placeholder="Elige aquí"
            {...register("organization_type", {
              required: true,
              onChange: (e) => setValue("organization_type", e.target.value),
            })}
            sx={{
              "& .MuiMenuItem-root": {
                fontSize: "14px",
              },
            }}
          >
            <MenuItem value="" style={{ display: "none", fontSize: "14px" }} />
            {organizationTypeItems.map((organization) => (
              <MenuItem
                key={organization.value}
                value={organization.value}
                style={{ fontSize: "14px" }}
              >
                {organization.description}
              </MenuItem>
            ))}
          </Select>
        }
      />
      <InputForm
        label="Razón Social"
        name="business_name"
        register={register}
        disabled={!isCreateBilling || !isEditable}
        errors={errors}
        required={isCreateBilling}
        placeholder="Ejemplo: Los Muebles Asociados"
      />
      {inputAccountUser}
      <Stack
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        gap="8px"
      >
        <InputForm
          label="Tipo de identificación"
          name="document_type"
          errors={errors}
          required
          width="200px"
          customInput={
            <Select
              name="document_type"
              size="small"
              variant="outlined"
              displayEmpty={true}
              style={{ fontSize: "14px" }}
              value={formValues.document_type || ""}
              disabled={!isEditable}
              {...register("document_type", {
                required: true,
                onChange: (e) => setValue("document_type", e.target.value),
              })}
              sx={{
                "& .MuiMenuItem-root": {
                  fontSize: "14px",
                },
              }}
            >
              <MenuItem
                value=""
                style={{ display: "none", fontSize: "14px" }}
              />
              {codeDocumentsType.map((documentType) => (
                <MenuItem
                  key={documentType.code}
                  value={documentType.code}
                  style={{ fontSize: "14px" }}
                >
                  {documentType.description}
                </MenuItem>
              ))}
            </Select>
          }
        />
        <InputForm
          label="Número de identificación"
          name="document_number"
          errors={errors}
          register={register}
          disabled={!isEditable}
          required
          width={"100%"}
          placeholder={"Ejemplo: 4255123452"}
        />
      </Stack>
      <InputForm
        label="Dirección"
        name="address"
        errors={errors}
        register={register}
        disabled={!isEditable}
        required
        placeholder={"Ejemplo: Cra. 8 #7 - 26"}
      />
      <Stack
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        gap="8px"
      >
        <InputForm
          label="Ciudad"
          name="city"
          errors={errors}
          register={register}
          disabled={!isEditable}
          required
          width={"50%"}
          customInput={
            <Autocomplete
              disabled={!isEditable}
              options={siigoCities}
              size="small"
              freeSolo
              sx={{
                "& .MuiAutocomplete-option": {
                  fontSize: "14px",
                },
                "& .MuiAutocomplete-listbox .MuiAutocomplete-option": {
                  fontSize: "14px",
                },
              }}
              renderOption={(props, option) => (
                <li
                  {...props}
                  key={option?.city_code}
                  style={{ fontSize: "14px" }}
                >
                  {option?.city_name}
                </li>
              )}
              filterOptions={(options, { inputValue }) => {
                const inputValueLower = inputValue.toLowerCase();
                return options.filter((option) =>
                  option.city_name.toLowerCase().includes(inputValueLower)
                );
              }}
              getOptionLabel={(option) => {
                return option?.city_name || "";
              }}
              onChange={(event, option) => {
                setValue("city", option, { shouldValidate: true });
                setValue("department", option?.state_name, {
                  shouldValidate: true,
                });
              }}
              onInputChange={(event) => {
                if (event?.target?.value?.length >= 3) {
                  handlGetSiigoCities(event.target.value);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: { fontSize: 14 },
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder={"Elige aquí"}
                />
              )}
              value={formValues.city || ""}
            />
          }
        />
        <InputForm
          label="Departamento"
          name="department"
          errors={errors}
          register={register}
          disabled
          width={"50%"}
          placeholder={"Ejemplo: Bogotá D.C"}
        />
      </Stack>
      <p
        style={{
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 600,
          marginBottom: "0px",
        }}
      >
        Datos de contacto
      </p>
      <Stack
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        gap="8px"
      >
        <InputForm
          label="Nombre"
          name="contact_name"
          errors={errors}
          register={register}
          disabled={!isEditable}
          required
          width={"50%"}
          placeholder={"Ejemplo: Diego"}
        />
        <InputForm
          label="Apellido"
          name="contact_surname"
          errors={errors}
          register={register}
          disabled={!isEditable}
          required
          width={"50%"}
          placeholder={"Ejemplo: Delgado"}
        />
      </Stack>
      <InputForm
        label="Email"
        name="email"
        errors={errors}
        required
        customInput={
          <EmailInput
            value={formValues.email}
            setValue={(value) => {
              setValue("email", value, { shouldValidate: true });
            }}
            register={register}
            required={true}
            disabled={!isEditable}
          />
        }
      />
      <InputForm
        label="Número de teléfono"
        name="phone"
        errors={errors}
        required
        customInput={
          <PhoneNumberInput
            phone={formValues.phone}
            disabled={!isEditable}
            setPhone={(value) => {
              setValue("phone", value, { shouldValidate: true });
            }}
            {...register("phone", PHONE_NUMBER_VALIDATOR())}
          />
        }
      />
    </Stack>
  );
};
