import React from "react";

export const BannerText = ({ firstText, secondText }) => {
  return (
    <p
      style={{
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "24px",
        textAlign: "left",
        marginBottom: "0px",
      }}
    >
      {firstText} {""}
      <span
        style={{
          color: "#475467",
          fontSize: "14px",
          fontWeight: 500,
          lineHeight: "24px",
        }}
      >
        {secondText}
      </span>
    </p>
  );
};
