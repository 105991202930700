import React from "react";

export const BalanceIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      style={{
        mixBlendMode: "multiply",
      }}
    >
      <rect width="32" height="32" rx="16" fill="#F4F4F5" />
      <path
        d="M9.33331 19.3333V19.2332C9.33331 19.0222 9.33331 18.9167 9.34945 18.8139C9.36378 18.7227 9.38755 18.6332 9.42039 18.5468C9.45737 18.4496 9.50971 18.358 9.61439 18.1748L12 14M9.33331 19.3333C9.33331 20.8061 10.5272 22 12 22C13.4727 22 14.6666 20.8061 14.6666 19.3333M9.33331 19.3333V19.2C9.33331 19.0133 9.33331 18.92 9.36964 18.8487C9.4016 18.7859 9.4526 18.735 9.51532 18.703C9.58662 18.6667 9.67996 18.6667 9.86665 18.6667H14.1333C14.32 18.6667 14.4133 18.6667 14.4846 18.703C14.5474 18.735 14.5984 18.7859 14.6303 18.8487C14.6666 18.92 14.6666 19.0133 14.6666 19.2V19.3333M12 14L14.3856 18.1748C14.4902 18.358 14.5426 18.4496 14.5796 18.5468C14.6124 18.6332 14.6362 18.7227 14.6505 18.8139C14.6666 18.9167 14.6666 19.0222 14.6666 19.2332V19.3333M12 14L20 12.6667M17.3333 18V17.8999C17.3333 17.6889 17.3333 17.5834 17.3495 17.4806C17.3638 17.3893 17.3876 17.2998 17.4204 17.2135C17.4574 17.1162 17.5097 17.0246 17.6144 16.8414L20 12.6667M17.3333 18C17.3333 19.4728 18.5272 20.6667 20 20.6667C21.4727 20.6667 22.6666 19.4728 22.6666 18M17.3333 18V17.8667C17.3333 17.68 17.3333 17.5866 17.3696 17.5153C17.4016 17.4526 17.4526 17.4016 17.5153 17.3697C17.5866 17.3333 17.68 17.3333 17.8666 17.3333H22.1333C22.32 17.3333 22.4133 17.3333 22.4846 17.3697C22.5474 17.4016 22.5984 17.4526 22.6303 17.5153C22.6666 17.5866 22.6666 17.68 22.6666 17.8667V18M20 12.6667L22.3856 16.8414C22.4902 17.0246 22.5426 17.1162 22.5796 17.2135C22.6124 17.2998 22.6362 17.3893 22.6505 17.4806C22.6666 17.5834 22.6666 17.6889 22.6666 17.8999V18M16 10V13.3333"
        stroke="#6938EF"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
