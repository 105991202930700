export const styles = {
  container: {
    background: "#FFFFFF",
    padding: "4px 6px 4px 14px",
    borderRadius: "16px",
    border: "1px solid #EAECF0",
    display: "flex",
    flexDirection: "row",
    gap: "8px",
    width: "-webkit-fill-available",
    justifyContent: "space-between",
    alignItems: "center",
    height: "40px",
  },
  siigoLogo: {
    width: "24px",
    height: "24px",
    marginRight: "8px",
  },
  lastSyncDate: {
    display: "flex",
    flexDirection: "row",
    gap: "4px",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    gap: "12px",
  },
  siigoLogoMargin: {
    marginBottom: "2px",
  },
  lastUpdateText: {
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "20px",
    textAlign: "left",
    color: "#667085",
    marginTop: "2px",
  },
  dateText: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "left",
    color: "#1D2939",
    marginTop: "2px",
  },
  sheetText: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "left",
    color: "#1D2939",
    marginTop: "2px",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  spinner: {
    marginRight: "8px",
  },
  updateButton: {
    height: "28px",
    borderRadius: "16px",
    padding: "4px 12px 4px 12px",
    backgroundColor: "transparent",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "transparent",
    },
  },
  syncBanner: {
    marginLeft: "0px",
    marginRight: "0px",
  },
};
