import { TextField } from "@mui/material";
import React from "react";
import { styles } from "./styles";

export const InputShareNumber = ({
  payment,
  index,
  handleOnChangePayment,
  name,
}) => {
  return (
    <TextField
      name={name}
      placeholder="Nro. de Cuota"
      type="number"
      sx={styles.inputAmount}
      value={payment?.share_number}
      onChange={(e) => {
        handleOnChangePayment(index, e.target.value, "shareNumber");
      }}
      disabled={payment?.accounting_concept === ""}
    />
  );
};
