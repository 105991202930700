import React from "react";
import "./styles.css";

export const CheckboxPayana = ({
  checked,
  value,
  onClick,
  disabled,
  style = {},
}) => {
  return (
    <input
      type="checkbox"
      className={"checkbox-custom"}
      checked={checked}
      value={value}
      onClick={onClick}
      disabled={disabled}
      style={style}
    />
  );
};
