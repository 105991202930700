import { AutocompleteTable } from "commons/components";
import React, { useEffect, useState } from "react";
import { getItemWarehouses } from "services";
import { ITEM_COLUMN_TYPE } from "../../constants";

export const AutocompleteWarehousesSelected = ({
  handleChangeMultipleSelect,
  params,
  placeholder,
}) => {
  const [warehouses, setWarehouses] = useState([]);

  const getWareHouses = async (productId = null, query = "") => {
    try {
      const response = await getItemWarehouses(productId, query);
      if (!Array.isArray(response)) return;
      setWarehouses(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    try {
      getWareHouses();
    } catch (error) {
      console.error(error);
    }
  }, []);

  return (
    <div
      style={{
        backgroundColor: "#ffffff",
        borderRadius: "8px",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <AutocompleteTable
        value={params.value}
        name="warehouse"
        options={warehouses}
        placeholder={placeholder}
        getOptionLabel={(option) => option?.wareHouse || ""}
        onChange={(event, option) => {
          event.preventDefault();
          handleChangeMultipleSelect(ITEM_COLUMN_TYPE.warehouse, option);
        }}
        onInputChange={(event) => {
          if (event?.target?.value?.length < 3) return;
          getWareHouses("", event?.target?.value);
        }}
        tableConfig={{
          columns: [
            {
              headerName: "Bodega",
              getText: (option) => option?.wareHouse,
              width: "200px",
            },
          ],
          getRowTooltipText: (option) => `${option?.wareHouse}`,
        }}
        placeholder="Bodega"
      />
    </div>
  );
};
