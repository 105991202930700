import React, { useRef, useState, useMemo, useCallback } from "react";
import { SecondaryButton, TagsButton } from "../../../commons/buttons";
import {
  Menu,
  Fade,
  Box,
  CircularProgress,
  TextField,
  InputAdornment,
  Typography,
  Chip,
} from "@mui/material";
import { SearchIcon } from "assets";
import stylesCSS from "../styles.module.css";

export const TagsDropdown = ({
  options = [],
  selectedValues: selectedValuesProp = [],
  setTags,
  setTagsSelected,
  isLoading = false,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchText, setSearchText] = useState("");
  const searchRef = useRef(null);
  const open = Boolean(anchorEl);

  const selectedValues = useMemo(
    () => (Array.isArray(selectedValuesProp) ? selectedValuesProp : []),
    [selectedValuesProp]
  );

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
    setTimeout(() => {
      searchRef?.current?.focus();
    }, 100);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    setSearchText("");
  }, []);

  const cleanTags = useCallback(() => {
    setTagsSelected([]);
  }, [setTagsSelected]);

  const menuStyles = useMemo(
    () => ({
      mt: "8px",
      "& .MuiPaper-root": {
        borderRadius: "12px",
        boxShadow:
          "0px 8px 24px -4px rgba(16, 24, 40, 0.08), 0px 6px 12px -6px rgba(16, 24, 40, 0.04)",
        border: "1px solid #EAECF0",
        minWidth: "320px",
        maxWidth: "90vw",
        maxHeight: "420px",
        overflow: "visible",
      },
    }),
    []
  );

  const containerStyles = useMemo(
    () => ({
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: "10px",
      position: "relative",
    }),
    []
  );

  const contentStyles = useMemo(
    () => ({
      display: "flex",
      flexDirection: "column",
      height: "100%",
      maxHeight: "420px",
    }),
    []
  );

  const searchStyles = useMemo(
    () => ({
      p: "12px 12px 8px 12px",
      borderBottom: "1px solid #EAECF0",
      backgroundColor: "#fff",
      position: "sticky",
      top: 0,
      zIndex: 1,
    }),
    []
  );

  const selectedTagsStyles = useMemo(
    () => ({
      display: "flex",
      flexWrap: "wrap",
      gap: "8px",
      padding: "8px 16px",
      borderBottom: selectedValues.length > 0 ? "1px solid #EAECF0" : "none",
      maxWidth: "400px",
      "& .MuiChip-root": {
        maxWidth: "180px",
        "& .MuiChip-label": {
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        },
      },
    }),
    [selectedValues.length]
  );

  const optionsContainerStyles = useMemo(
    () => ({
      flex: 1,
      overflowY: "auto",
      p: "8px 16px",
      minHeight: "200px",
      "&::-webkit-scrollbar": {
        width: "4px",
      },
      "&::-webkit-scrollbar-track": {
        background: "#F9FAFB",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#E4E7EC",
        borderRadius: "4px",
      },
    }),
    []
  );

  const footerStyles = useMemo(
    () => ({
      position: "sticky",
      bottom: 0,
      backgroundColor: "#fff",
      borderTop: "1px solid #EAECF0",
      padding: "12px 16px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      zIndex: 1,
    }),
    []
  );

  const filteredOptions = useMemo(() => {
    if (!searchText) return options;
    return options.filter((option) =>
      option.name?.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [options, searchText]);

  const handleCreateNewTag = useCallback(() => {
    if (!searchText) return;

    const newTag = {
      id: `new-${Date.now()}`,
      name: searchText,
      isNew: true,
    };

    setTagsSelected([...selectedValues, newTag]);
    setTags([...options, newTag]);
    setSearchText("");
  }, [searchText, selectedValues, setTagsSelected, setTags, options]);

  const handleRemoveTag = useCallback(
    (tagToRemove) => {
      const newSelectedValues = selectedValues.filter(
        (selected) => selected.id !== tagToRemove.id
      );
      setTagsSelected(newSelectedValues);
    },
    [selectedValues, setTagsSelected]
  );

  const handleOptionClick = useCallback(
    (option) => {
      if (!option?.id) return;

      const isSelected = selectedValues.some(
        (selected) => selected?.id === option.id
      );

      if (isSelected) {
        const newSelectedValues = selectedValues.filter(
          (selected) => selected.id !== option.id
        );
        setTagsSelected(newSelectedValues);
      } else {
        setTagsSelected([...selectedValues, option]);
      }
    },
    [selectedValues, setTagsSelected]
  );

  return (
    <Box sx={containerStyles}>
      <TagsButton
        open={open}
        action={handleClick}
        text="Etiquetas"
        quantity={selectedValues.length}
        cleanTags={cleanTags}
        disabled={isLoading}
      />

      <Menu
        id="tags-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        sx={menuStyles}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box sx={contentStyles}>
          <Box sx={searchStyles}>
            <TextField
              fullWidth
              size="small"
              placeholder="Buscar etiqueta"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                sx: {
                  fontSize: "14px",
                  backgroundColor: "#FFFFFF",
                  "& fieldset": {
                    borderColor: "#E4E7EC",
                  },
                  "&:hover fieldset": {
                    borderColor: "#D0D5DD",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#6938EF",
                  },
                },
              }}
            />
          </Box>

          {selectedValues.length > 0 && (
            <Box sx={selectedTagsStyles}>
              {selectedValues.map(
                (tag) =>
                  tag?.id && (
                    <Chip
                      key={tag.id}
                      label={tag.name}
                      onDelete={() => handleRemoveTag(tag)}
                      sx={{
                        backgroundColor: "#F9F5FF",
                        color: "#6938EF",
                        borderRadius: "8px",
                        border: "1px solid #6938EF",
                        "& .MuiChip-deleteIcon": {
                          color: "#6938EF",
                          "&:hover": {
                            color: "#5925DC",
                          },
                          height: "16px",
                          width: "16px",
                        },
                      }}
                    />
                  )
              )}
            </Box>
          )}

          <Box sx={optionsContainerStyles}>
            {isLoading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "200px",
                }}
              >
                <CircularProgress size={24} sx={{ color: "#6938EF" }} />
              </Box>
            ) : filteredOptions.length > 0 ? (
              filteredOptions.map(
                (option) =>
                  option?.id && (
                    <Box
                      key={option.id}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        margin: "4px 0",
                        width: "100%",
                        padding: "2px 8px",
                        borderRadius: "6px",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "#F9FAFB",
                        },
                        gap: "16px",
                        marginBottom: "8px",
                      }}
                      onClick={() => handleOptionClick(option)}
                    >
                      <input
                        type="checkbox"
                        className={stylesCSS.input}
                        checked={selectedValues.some(
                          (selected) => selected?.id === option.id
                        )}
                      />
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: selectedValues.some(
                            (selected) => selected?.id === option.id
                          )
                            ? "#6938EF"
                            : "#344054",
                          fontWeight: selectedValues.some(
                            (selected) => selected?.id === option.id
                          )
                            ? "500"
                            : "400",
                        }}
                      >
                        {option.name}
                      </Typography>
                    </Box>
                  )
              )
            ) : searchText ? (
              <Box
                onClick={handleCreateNewTag}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "8px 12px",
                  cursor: "pointer",
                  borderRadius: "6px",
                  "&:hover": {
                    backgroundColor: "#F9FAFB",
                  },
                }}
              >
                <Typography
                  sx={{
                    color: "#6938EF",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  Crear nueva etiqueta "{searchText}"
                </Typography>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "200px",
                }}
              >
                <Typography sx={{ color: "#667085", fontSize: "14px" }}>
                  No se encontraron resultados
                </Typography>
              </Box>
            )}
          </Box>

          <Box sx={footerStyles}>
            <Typography sx={{ color: "#667085", fontSize: "14px" }}>
              {selectedValues.length}{" "}
              {selectedValues.length === 1
                ? "etiqueta seleccionada"
                : "etiquetas seleccionadas"}
            </Typography>
            <SecondaryButton
              text="Limpiar"
              action={() => {
                cleanTags();
                handleClose();
              }}
              width="88px"
            />
          </Box>
        </Box>
      </Menu>
    </Box>
  );
};
