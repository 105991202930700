import { Autocomplete, TextField, Typography } from "@mui/material";
import React from "react";

export function InputProvider({ field, causationProps, disabled }) {
  const { search, formValues, errors, register, supplierFound } =
    causationProps;

  // Hacer el register opcional
  const registerProps = register
    ? register(field.name, {
        required: field.required,
        validate: {
          validSupplier: (value) => {
            const hasValidData =
              value?.id || (value?.full_name && supplierFound);
            return !!hasValidData;
          },
        },
      })
    : { ref: null, onChange: () => {} };

  const { ref, onChange: onChangeRegister } = registerProps;

  return (
    <>
      <Autocomplete
        {...field.props({ ...causationProps })}
        onChange={(event, newValue) => {
          const syntheticEvent = {
            target: {
              name: field.name,
              value: newValue,
            },
          };

          onChangeRegister(syntheticEvent);

          if (field.props({ ...causationProps }).onChange) {
            field.props({ ...causationProps }).onChange(event, newValue);
          }
        }}
        options={field.options || search[field.name]}
        value={field.value || formValues[field.name]}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            error={!!errors[field.name]}
            helperText={errors[field.name]?.message}
            InputProps={{
              ...params.InputProps,
              style: { fontSize: 14 },
            }}
            placeholder={field.placeholder}
          />
        )}
        sx={{
          "& .MuiAutocomplete-input": {
            fontSize: "14px",
          },
        }}
      />
      {!formValues.supplier?.id && !formValues.supplier?.full_name && (
        <Typography
          style={{
            color: "#667085",
            fontWeight: "400",
            fontSize: "12px",
          }}
        >
          {field.textProviderNotFoundUpload}
        </Typography>
      )}
      {formValues.supplier?.full_name && !supplierFound && (
        <Typography
          style={{
            color: "#F04438",
            fontWeight: "400",
            fontSize: "12px",
          }}
        >
          {field.textProviderNotFoundCreate}
        </Typography>
      )}
    </>
  );
}
