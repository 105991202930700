import React, { useState } from "react";
import { Multiselect } from "multiselect-react-dropdown";
import { PlusIcon2, XIcon } from "../../assets";
import { Button } from "@mui/material";
import { CreateTag, GetTags } from "../../services";
import { Spinner } from "react-bootstrap";

export const TagsSelector = ({
  options,
  selectedValues = [],
  onSelect,
  onRemove,
  searchBoxStyle = {
    border: "none",
    borderBottom: "1px solid blue",
    borderRadius: "0px",
    backgroundColor: "#FFFFFF",
  },
  inputFieldStyle = {
    fontSize: "14px",
    color: "black",
  },
  disable = false,
  setTags,
  setTagsSelected,
  ref,
  disablePlaceholder = false,
  className = null,
}) => {
  const [tagSearch, setTagSearch] = useState("");
  const [createRequestInProcess, setCreateRequestInProcess] = useState(false);

  const createTag = () => {
    if (createRequestInProcess) {
      return;
    }
    setCreateRequestInProcess(true);
    CreateTag({ tag: tagSearch }).then((tagCreated) => {
      GetTags()
        .then((res) => {
          setTags(res);
          setTagsSelected([...selectedValues, tagCreated]);
          document.getElementById("search_input").blur();
        })
        .finally(() => {
          setTimeout(() => {
            document.getElementById("search_input").value = "";
          }, 100);
          setCreateRequestInProcess(false);
          setTagSearch("");
        });
    });
  };

  return (
    <Multiselect
      options={options}
      className={className}
      selectedValues={selectedValues}
      onSelect={onSelect}
      onRemove={onRemove}
      onSearch={(value) => setTagSearch(value)}
      displayValue={"name"}
      placeholder={disablePlaceholder ? "" : "Buscar etiqueta"}
      showCheckbox
      hidePlaceholder
      ref={ref}
      emptyRecordMsg={
        tagSearch.length > 0 ? (
          <Button
            variant="primary"
            style={{
              textTransform: "none",
              width: "100%",
              alignItems: "center",
              justifyContent: "flex-start",
              display: "flex",
              flexDirection: "row",
              color: "#5925DC",
              fontSize: "14px",
              fontWeight: "400",
            }}
            onClick={() => createTag()}
            disabled={createRequestInProcess}
          >
            {createRequestInProcess ? (
              <Spinner animation="border" size="sm" />
            ) : (
              <>
                <div style={{ marginRight: "3px" }}>
                  <PlusIcon2 />
                </div>
                {`Crear etiqueta "${tagSearch}"`}
              </>
            )}
          </Button>
        ) : (
          ""
        )
      }
      avoidHighlightFirstOption
      disable={disable}
      customCloseIcon={
        <div style={{ marginLeft: "6px" }}>
          <XIcon />
        </div>
      }
      style={{
        multiselectContainer: {
          minWidth: "300px",
          paddingTop: "0px",
        },
        chips: {
          background: "#F9F5FF",
          color: "#6938EF",
          borderRadius: "8px",
          border: "1px solid #6938EF",
          marginBottom: "0px",
          margin: "4px",
        },
        searchBox: {
          ...searchBoxStyle,
          backgroundColor: "#ffffff !important",
        },
        inputField: {
          ...inputFieldStyle,
          fontSize: "14px",
          backgroundColor: "#FFFFFF",
        },
        optionContainer: {
          padding: "8px 16px",
          maxHeight: "420px",
          backgroundColor: "#ffffff",
          boxShadow:
            "0px 8px 24px -4px rgba(16, 24, 40, 0.08), 0px 6px 12px -6px rgba(16, 24, 40, 0.04)",
          border: "1px solid #EAECF0",
          borderRadius: "12px",
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-track": {
            background: "#F9FAFB",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#E4E7EC",
            borderRadius: "4px",
          },
        },
        option: {
          fontSize: "14px",
          backgroundColor: "white",
          color: "#344054",
          border: "0px solid transparent",
          display: "flex",
          alignItems: "center",
          margin: "4px 0",
          width: "100%",
          padding: "2px 8px",
          borderRadius: "6px",
          cursor: "pointer",
          gap: "16px",
          marginBottom: "8px",
          "&:hover": {
            backgroundColor: "#F9FAFB",
          },
        },
        optionListContainer: {
          boxShadow:
            "0px 8px 24px -4px rgba(16, 24, 40, 0.08), 0px 6px 12px -6px rgba(16, 24, 40, 0.04)",
        },
      }}
    />
  );
};

export default TagsSelector;
