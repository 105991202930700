import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";
import { UserContext } from "../../../contexts";
import { PlusCircleIcon } from "../../../assets";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CheckIcon from "@mui/icons-material/Check";
import { styles } from "./styles";
import { useNavigate } from "react-router-dom";
import { ChangeUserCompany, GetUserCompanies } from "services";
import { toast } from "react-toastify";
import { toastOptions } from "constants";
import { AccountMenu } from "../Navbar";

export const TopNavbar = ({ showMenuOptions, showUserMenu }) => {
  const {
    currentUser,
    currentCompany,
    currentUserIsLoggedIn,
    setIsCompanySwitchModalVisible,
    blockedCompany,
    hasTransactionsLimit,
  } = useContext(UserContext);
  const [companies, setCompanies] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  useEffect(() => {
    currentUserIsLoggedIn &&
      GetUserCompanies().then((res) => {
        setCompanies(res);
      });
  }, [currentUserIsLoggedIn]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCompanyClick = (company) => {
    ChangeUserCompany({ id: company.companyId }).then((res) => {
      handleClose();
      if (res?.message === "Company changed") {
        navigate("/payments/invoices", { replace: true });
        window.location.reload(true);
      } else {
        toast.error("Ha ocurrido un error, intente nuevamente", toastOptions);
      }
    });
  };

  const handleCreateNew = () => {
    setIsCompanySwitchModalVisible(true);
    handleClose();
  };

  const initials = (name) =>
    name
      ?.match(/\b(\w)/g)
      ?.join("")
      ?.toUpperCase()
      ?.slice(0, 2);

  const renderCompany = (company) => {
    const isActive = currentCompany?.id === company.companyId;

    return (
      <MenuItem
        key={`company-${company.companyId}`}
        onClick={() => handleCompanyClick(company)}
        sx={styles.menuItem}
      >
        <Box sx={styles.companyInfo}>
          {company.filePathLogo ? (
            <img
              style={styles.companyLogo}
              src={company.filePathLogo}
              alt="Logo"
            />
          ) : (
            <div
              style={{
                width: "32px",
                height: "32px",
                borderRadius: "150px",
                backgroundColor: "#D0D5DD",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                style={{
                  fontWeight: "600",
                  fontSize: "12px",
                  color: "#344054",
                }}
              >
                {initials(company?.companyName)}
              </Typography>
            </div>
          )}
          <Typography sx={styles.menuItemText}>
            {company.companyName}
          </Typography>
        </Box>
        {isActive && <CheckIcon sx={{ color: "#6938EF", fontSize: 20 }} />}
      </MenuItem>
    );
  };

  return (
    <Box sx={styles.topNavbar}>
      <Box sx={styles.container}>
        <Button
          onClick={handleClick}
          sx={styles.companyButton}
          endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          className="companiesSwitch"
        >
          <Box sx={styles.companyInfo}>
            {currentCompany?.file_path_logo ? (
              <img
                style={styles.companyLogo}
                src={currentCompany.file_path_logo}
                alt="Logo"
              />
            ) : (
              <div
                style={{
                  width: "32px",
                  height: "32px",
                  borderRadius: "150px",
                  backgroundColor: "#D0D5DD",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{
                    fontWeight: "600",
                    fontSize: "12px",
                    color: "#344054",
                  }}
                >
                  {initials(currentCompany?.name)}
                </Typography>
              </div>
            )}
            <Typography sx={styles.companyName}>
              {currentCompany?.name || currentUser.name}
            </Typography>
          </Box>
        </Button>

        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          slotProps={{
            paper: {
              sx: styles.menu,
            },
          }}
        >
          {companies?.map((company) => renderCompany(company))}
          {!blockedCompany && !hasTransactionsLimit && (
            <MenuItem
              key="create-new"
              onClick={handleCreateNew}
              sx={styles.createNewItem}
            >
              <PlusCircleIcon width="24" height="24" />
              <Typography sx={styles.createNewText}>
                Crear nueva empresa
              </Typography>
            </MenuItem>
          )}
        </Menu>
      </Box>
      {(showMenuOptions || !showUserMenu) &&
        (!showMenuOptions || showUserMenu) && (
          <Box sx={styles.userMenuContainer} className="accountSection">
            <AccountMenu />
          </Box>
        )}
    </Box>
  );
};
