import { format } from "date-fns";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const formatInvoicesXLSX = (response) => {
  const { invoices } = response;

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const invoicesRows = XLSX.utils.json_to_sheet(invoices);

  const wb = {
    Sheets: { "Cuentas por pagar": invoicesRows },
    SheetNames: ["Cuentas por pagar"],
  };

  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

  const data = new Blob([excelBuffer], { type: fileType });

  const now = new Date();

  FileSaver.saveAs(
    data,
    `Cuentas por pagar-${format(now, "dd.MM.yyyy")}-Payana` + fileExtension
  );
};

export default formatInvoicesXLSX;
