import React from "react";
import { styles } from "./styles";
import { ListCheckIcon, MinusIcon, RightArrowIcon } from "assets";
import { Stack } from "@mui/material";

export const TransactionCost = () => {
  return (
    <>
      <p
        style={{
          ...styles.cardInformationTitle,
          marginTop: "16px",
          marginBottom: "8px",
        }}
      >
        Costo por transacción
      </p>
      <div>
        <div style={styles.cardInformationListContainer}>
          <MinusIcon
            stroke="#6938EF"
            style={{ marginLeft: "-4px", marginRight: "-4px" }}
          />
          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={1}
          >
            <p style={styles.transactionCostTextList}>
              Pagos: $3.300/proveedor
            </p>
          </Stack>
        </div>
        <div style={styles.cardInformationListContainer}>
          <MinusIcon
            stroke="#6938EF"
            style={{ marginLeft: "-4px", marginRight: "-4px" }}
          />
          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={1}
          >
            <p style={styles.transactionCostTextList}>Cobros: $5.000/cobro</p>
          </Stack>
        </div>
      </div>
    </>
  );
};
