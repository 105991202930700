import React, { useContext, useEffect, useState } from "react";
import { TrackJS } from "trackjs";
import { Box, Collapse, Divider, Stack } from "@mui/material";
import { PseIcon, CardIcon } from "../../../assets";
import { styles } from "./styles";
import { PrimaryButton } from "../../buttons";
import { UserContext } from "../../../contexts";
import { GetTransaction } from "../../../services";
import ComissionRetentions from "./ComissionRetentions";
import { payanaComissionRetentions } from "../../../utils";
import PaymentMethodItem from "./PaymentMethodItem";
import CardDisabledIcon from "./CardIcon";
import BankingCorrespondantDisabledIcon from "./BankingCorrespondantDisabledIcon";
import BankingCorrespondantIcon from "./BankingCorrespondantIcon";
import BankingCorrespondentDisabledLeyend from "./BankingCorrespondent/DisabledLeyend";
import CreditCardDisabledLeyend from "./CreditCard/DisabledLeyend";
import PaymentModalRow from "./PaymentModalRow";
import getPayanaComission from "./getPayanaComission";
import {
  getCardFeePercentageByEntityType,
  PAYMENT_METHOD_PSE_KEY,
} from "constants";

function PaymentMethodSelectionStep({
  setSelectedPaymentMethod,
  selectedPaymentMethod,
  action,
  wompiButton,
  isLoading,
  totalAmount,
  trxId,
  type = "invoice",
  section,
  isCardLimitExceeded,
  showBankingCorrespondent,
  isBankingCorrespondentLimitExceeded,
  amountTotalInvoicePayana,
  setTotalAmountToPay,
  payoutsNumber,
  canPayWithCreditCard,
}) {
  const [loading, setLoading] = useState(false);
  const [linkGenerated, setLinkGenerated] = useState(false);
  const [transactionAmount, setTransactionAmount] = useState(totalAmount);

  useEffect(() => {
    trxId &&
      GetTransaction({ id: trxId }).then((res) => {
        setTransactionAmount(res.data.amount - res.data.amount_comission);
      });
  }, [trxId]);

  const formatAmount = new Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    minimumFractionDigits: 0,
  });

  const {
    newPricing,
    newPricingMetadata,
    comissionRetentions,
    comissionRetentionIsEnabled,
    isCreditCardEnabled,
  } = useContext(UserContext);

  const cardFeePercentage = getCardFeePercentageByEntityType(type);

  const { grossComission: payanaComission, label: commissionLabel } =
    getPayanaComission({
      amountTotalInvoicePayana,
      newPricing,
      newPricingMetadata,
      selectedPaymentMethod,
      totalAmount,
      type,
      cardFeePercentage,
      payoutsNumber,
      formatAmount,
    });

  const totalToPay = (selectedPaymentMethod, retentions) => {
    const totalToPay =
      section === "customer"
        ? selectedPaymentMethod === PAYMENT_METHOD_PSE_KEY
          ? transactionAmount
          : payanaComission + transactionAmount
        : comissionRetentionIsEnabled &&
          selectedPaymentMethod === PAYMENT_METHOD_PSE_KEY
        ? newPricing
          ? payanaComission -
            payanaComissionRetentions(
              payanaComission,
              selectedPaymentMethod,
              retentions
            ) +
            transactionAmount
          : transactionAmount
        : payanaComission + transactionAmount;
    setTotalAmountToPay(totalToPay);
    return totalToPay;
  };

  const primaryButtonTextLabels = {
    others: "Pagar con PSE",
    card: "Pagar con tarjeta de crédito",
    banking_correspondent: "Pagar en efectivo",
  };

  const primaryButtonText = primaryButtonTextLabels[selectedPaymentMethod];

  const isComissionRetentionsVisible =
    section !== "customer" &&
    comissionRetentionIsEnabled &&
    comissionRetentions.length > 0 &&
    newPricing &&
    payanaComission > 0 &&
    selectedPaymentMethod === PAYMENT_METHOD_PSE_KEY;

  return (
    <Box sx={styles.box}>
      <p style={styles.title}>Elige cómo quieres pagar</p>
      <p style={styles.subtitle}>Métodos de pago:</p>
      <div style={styles.column}>
        <PaymentMethodItem
          icon={<PseIcon />}
          type={PAYMENT_METHOD_PSE_KEY}
          selectedPaymentMethod={selectedPaymentMethod}
          id="paymentMethodOthers"
          label="PSE"
          onClick={() => setSelectedPaymentMethod(PAYMENT_METHOD_PSE_KEY)}
          isCreditCardEnabled={
            section === "customer" ? canPayWithCreditCard : isCreditCardEnabled
          }
          isBankingCorrespondentEnabled={
            section === "customer" && showBankingCorrespondent
          }
        />
        {section === "customer" && showBankingCorrespondent && (
          <PaymentMethodItem
            icon={
              isBankingCorrespondentLimitExceeded ? (
                <BankingCorrespondantDisabledIcon />
              ) : (
                <BankingCorrespondantIcon />
              )
            }
            type="banking_correspondent"
            selectedPaymentMethod={selectedPaymentMethod}
            id="paymentMethodBankingCorrespondent"
            label="Efectivo en corresponsal bancario"
            onClick={() => setSelectedPaymentMethod("banking_correspondent")}
            disabled={isBankingCorrespondentLimitExceeded}
            disabledLegend={<BankingCorrespondentDisabledLeyend />}
            isCreditCardEnabled={
              section === "customer"
                ? canPayWithCreditCard
                : isCreditCardEnabled
            }
            isBankingCorrespondentEnabled={
              section === "customer" && showBankingCorrespondent
            }
          />
        )}
        {isCreditCardEnabled && (
          <PaymentMethodItem
            icon={isCardLimitExceeded ? <CardDisabledIcon /> : <CardIcon />}
            disabledLegend={<CreditCardDisabledLeyend />}
            type="card"
            disabled={isCardLimitExceeded}
            selectedPaymentMethod={selectedPaymentMethod}
            id="paymentMethodCard"
            label="Tarjetas de crédito"
            onClick={() => setSelectedPaymentMethod("card")}
            isCreditCardEnabled={
              section === "customer"
                ? canPayWithCreditCard
                : isCreditCardEnabled
            }
            isBankingCorrespondentEnabled={
              section === "customer" && showBankingCorrespondent
            }
          />
        )}
        <div style={styles.sourceOfFundsInfoContainer}>
          <PaymentModalRow
            label={`Pagos a realizar (x${payoutsNumber})`}
            value={formatAmount.format(transactionAmount)}
          />
          <PaymentModalRow
            condition={commissionLabel && payanaComission > 0}
            label={commissionLabel}
            value={formatAmount.format(payanaComission)}
          />
          <Divider sx={styles.divider} />
          <Collapse sx={{ width: "100%" }} in={isComissionRetentionsVisible}>
            <ComissionRetentions
              comissionRetentions={comissionRetentions}
              formatAmount={formatAmount}
              payanaComission={payanaComission}
            />
            <Divider sx={styles.divider} />
          </Collapse>
          <PaymentModalRow
            label="Total a pagar"
            value={formatAmount.format(
              totalToPay(selectedPaymentMethod, comissionRetentions)
            )}
          />
        </div>
        <Stack
          direction="row"
          justifyContent="space-between"
          pt={1}
          style={{ width: "100%" }}
        >
          <PrimaryButton
            loading={loading || isLoading}
            isDisable={loading || isLoading || linkGenerated}
            width="100%"
            text={primaryButtonText}
            action={async () => {
              try {
                setLoading(true);
                await action();
                setLinkGenerated(true);
              } catch (error) {
                TrackJS.console.error(error);
                throw error;
              } finally {
                setLoading(false);
                setLinkGenerated(false);
              }
            }}
          />
        </Stack>
      </div>
      <form
        style={{ visibility: "hidden", height: 0 }}
        ref={wompiButton}
      ></form>
    </Box>
  );
}

export default PaymentMethodSelectionStep;
