import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import * as SubscriptionService from "services/api/subscription";
import {
  Billing,
  ModalSuscriber,
  ModalTrialSuscriber,
  ModalInactiveSuscriber,
  ModalSuccessSuscriber,
} from "./components";
import { stepsBilling, toastOptions } from "constants";
import { toast } from "react-toastify";
import { TrackJS } from "trackjs";

export const SubscriptionModal = ({
  subscription,
  openWompiModal = () => {},
}) => {
  const stepStatus = {
    trial_expired: stepsBilling.initial,
    inactive: stepsBilling.inactive,
    trial: stepsBilling.trial,
    cancelled: stepsBilling.cancelled,
  };

  const [step, setStep] = useState(
    stepStatus[subscription?.subscription?.status] || stepsBilling.trial
  );

  const [invoicesSubscriptionPending, setInvoicesSubscriptionPending] =
    useState(null);

  const [features, setFeatures] = useState([]);

  const getInvoicesSubscriptionPending = async () => {
    const resp = await SubscriptionService.getInvoicesSubscriptionPending();
    setInvoicesSubscriptionPending(resp.length > 0 ? resp[0] : null);
  };

  const handleSuccessSubscription = () => {
    subscription?.getMe();
    subscription?.hadleCloseModalSubscription();
  };

  const handleSuccessContinue = () => {
    handleSuccessSubscription();
    subscription?.callback();
  };

  const getFeatures = async () => {
    try {
      const respFeatures = await SubscriptionService.getFeatures();
      const featuresFilter = respFeatures.filter(
        (feature) => feature.type === subscription?.type
      );

      setFeatures(featuresFilter);
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangeStep = (value) => {
    setStep(value);
  };

  const createSubscription = async () => {
    try {
      const resp = await SubscriptionService.createSubcription(
        subscription?.subscription?.id,
        {
          featureId: features.find(
            (feature) =>
              feature.type === subscription?.type && feature.months === 1
          )?.id,
        }
      );
      if (typeof resp === "object") {
        await getInvoicesSubscriptionPending();
        handleChangeStep(stepsBilling.success);
      }
    } catch (error) {
      toast.error("Error al suscribirse", toastOptions);
      TrackJS.console.error(error);
    }
  };

  useEffect(() => {
    getFeatures();
    getInvoicesSubscriptionPending();
  }, []);

  const handleOpenModalPayment = () => {
    handleSuccessSubscription();
    openWompiModal({
      totalAmount: invoicesSubscriptionPending.amount_total,
      selectedRowsIds: [invoicesSubscriptionPending.id],
    });
    return;
  };

  const renderContent = {
    initial: (
      <ModalSuscriber
        type={subscription?.type}
        handleChangeStep={handleChangeStep}
        createSubscription={createSubscription}
      />
    ),
    success: (
      <ModalSuccessSuscriber
        handleSuccessContinue={handleSuccessContinue}
        invoicesSubscriptionPending={invoicesSubscriptionPending}
        handleOpenModalPayment={handleOpenModalPayment}
      />
    ),
    inactive: (
      <ModalInactiveSuscriber
        handleSuccessSubscription={handleSuccessSubscription}
        handleOpenModalPayment={handleOpenModalPayment}
        invoicesSubscriptionPending={invoicesSubscriptionPending}
      />
    ),
    trial: (
      <ModalTrialSuscriber
        type={subscription?.type}
        handleChangeStep={(stepValue) => setStep(stepValue)}
        subscription={subscription}
      />
    ),
    billing: (
      <Billing
        handleChangeStep={handleChangeStep}
        createSubscription={createSubscription}
      />
    ),
  };

  return (
    <Modal
      show={subscription?.showModalSubscription}
      onHide={subscription?.hadleCloseModalSubscription}
      centered
    >
      <Modal.Body
        style={{
          padding: "24px",
        }}
      >
        {renderContent[step]}
      </Modal.Body>
    </Modal>
  );
};
