import { Autocomplete, Box, TextField } from "@mui/material";
import { AutocompleteTable } from "commons/components";
import { ITEM_COLUMN_TYPE } from "../constants";

export const multipleSelectColumns = ({
  products,
  getSearchOptionsProducts,
  taxesIva,
  taxesRete,
  handleChangeMultipleSelect,
  costCenter,
}) => {
  const columns = [
    {
      field: "product",
      headerName: "Producto",
      sortable: false,
      flex: 1,
      headerAlign: "center",
      cellClassName: "super-app-theme--cell multiple-cell-select",
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              backgroundColor: "#ffffff",
              borderRadius: "8px",
            }}
            className="multipleSelect-1"
          >
            <AutocompleteTable
              name="product"
              options={products}
              tabIndex={params.tabIndex}
              placeholder="Producto"
              getOptionLabel={(option) =>
                option?.fname
                  ? `${option?.fcode?.trim()} - ${option?.fname?.trim()}`
                  : ""
              }
              onChange={(event, option) => {
                event.preventDefault();
                handleChangeMultipleSelect(ITEM_COLUMN_TYPE.product, option);
              }}
              onInputChange={(event) => {
                getSearchOptionsProducts(
                  ITEM_COLUMN_TYPE.product,
                  event?.target?.value
                );
              }}
              value={params.value}
              tableConfig={{
                columns: [
                  {
                    headerName: "Código",
                    getText: (option) => option?.fcode,
                  },
                  {
                    headerName: "Descripción",
                    getText: (option) => option?.fname?.trim(),
                  },
                ],
                rowClassName: "causation",
                getRowTooltipText: (option) =>
                  `${option?.fcode} - ${option?.fname}`,
              }}
            />
          </div>
        );
      },
    },
    {
      field: "description",
      headerName: "Descripción",
      sortable: false,
      flex: 1,
      headerAlign: "center",
      editable: !false,
      renderCell: () => "",
      cellClassName: "cell-hidden",
    },
    {
      field: "itemCostCenter",
      headerName: "Centro de costos",
      sortable: false,
      width: 163,
      headerAlign: "center",
      cellClassName: "super-app-theme--cell multiple-cell-select",
      renderCell: (params) => {
        return (
          <Autocomplete
            name="itemCostCenter"
            size="small"
            PaperComponent={({ children }) => (
              <Box className="autocomplete-container">{children}</Box>
            )}
            options={costCenter}
            className="multipleSelect-2"
            getOptionLabel={(option) => ` ${option.name}`}
            onChange={(_, newValue) =>
              handleChangeMultipleSelect(
                ITEM_COLUMN_TYPE.costCenter,
                newValue || null
              )
            }
            filterOptions={(options, { inputValue }) => {
              const inputValueLower = inputValue.toLowerCase();
              return options?.filter(
                (option) =>
                  option?.name?.toLowerCase().includes(inputValueLower) ||
                  option?.code?.toString().includes(inputValueLower)
              );
            }}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option?.id}>
                  {option?.name}
                </li>
              );
            }}
            noOptionsText="No se encontró ningún resultado para tu búsqueda"
            sx={{
              width: "100%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              "& fieldset": {
                border: "none",
              },
            }}
            value={params.row.costCenter}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  style: { fontSize: 14, minWidth: "100%" },
                }}
                placeholder="Centro de costos"
                sx={{
                  "& .MuiAutocomplete-input": {
                    fontSize: "14px",
                    minWidth: "100%",
                  },
                }}
              />
            )}
          />
        );
      },
    },
    {
      field: "quantity",
      headerName: "Cant.",
      type: "number",
      editable: !false,
      width: 105,
      sortable: false,
      headerAlign: "center",
      renderCell: () => "",
      cellClassName: "cell-hidden",
    },
    {
      field: "unitValue",
      headerName: "Valor unitario",
      type: "number",
      editable: false,
      sortable: false,
      width: 104,
      headerAlign: "center",
      renderCell: () => "",
      cellClassName: "cell-hidden",
    },
    {
      field: "discount",
      headerName: "Descuento",
      type: "number",
      editable: false,
      sortable: false,
      width: 100,
      headerAlign: "center",
      renderCell: () => "",
      cellClassName: "cell-hidden",
    },
    {
      field: "taxIva",
      headerName: "Imp. Cargo",
      sortable: false,
      width: 170,
      headerAlign: "center",
      cellClassName: "multiple-cell-select",
      renderCell: (params) => {
        return (
          <Autocomplete
            PaperComponent={({ children }) => (
              <Box className="autocomplete-container">{children}</Box>
            )}
            options={taxesIva}
            getOptionLabel={(option) => option.description || ""}
            onChange={(_, newValue) =>
              handleChangeMultipleSelect(
                ITEM_COLUMN_TYPE.taxIva,
                newValue?.id || null
              )
            }
            size="small"
            sx={{
              ...stylesSelectedTaxs,
              "& .MuiAutocomplete-input": {
                fontSize: "14px",
                minWidth: "100%",
                padding: "2px 4px",
                textAlign: "left",
                alignSelf: "center",
              },
              "& .MuiInputBase-root": {
                height: "100%",
                padding: "2px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "left",
              },
              "& .MuiOutlinedInput-root": {
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              },
              "& .MuiAutocomplete-endAdornment": {
                display: "none",
              },
              "& .MuiInputBase-input": {
                height: "100% !important",
                display: "flex !important",
                alignItems: "center !important",
                justifyContent: "center !important",
                padding: "0 !important",
              },
            }}
            noOptionsText="No se encontró ningún resultado para tu búsqueda"
            renderOption={(props, option) => {
              return (
                <li
                  {...props}
                  key={option?.id}
                  style={{
                    fontSize: "14px",
                    padding: "8px 12px",
                  }}
                >
                  {option.description}
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  style: { fontSize: 14, minWidth: "100%" },
                }}
                placeholder="IVA"
                sx={{
                  "& .MuiAutocomplete-input": {
                    fontSize: "14px",
                    minWidth: "100%",
                    padding: "0px",
                    textAlign: "center",
                  },
                }}
              />
            )}
          />
        );
      },
    },
    {
      field: "taxRetefuente",
      headerName: "Imp. Retención",
      sortable: false,
      width: 155,
      headerAlign: "center",
      cellClassName: "multiple-cell-select",
      renderCell: (params) => (
        <Autocomplete
          PaperComponent={({ children }) => (
            <Box className="autocomplete-container">{children}</Box>
          )}
          options={taxesRete}
          getOptionLabel={(option) => option.description || ""}
          onChange={(_, newValue) =>
            handleChangeMultipleSelect(
              ITEM_COLUMN_TYPE.taxRetefuente,
              newValue?.id || null
            )
          }
          size="small"
          sx={{
            ...stylesSelectedTaxs,
            "& .MuiAutocomplete-input": {
              fontSize: "14px",
              minWidth: "100%",
              padding: "2px 4px",
              textAlign: "left",
              alignSelf: "center",
            },
            "& .MuiInputBase-root": {
              height: "100%",
              padding: "2px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "left",
            },
            "& .MuiOutlinedInput-root": {
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            },
            "& .MuiAutocomplete-endAdornment": {
              display: "none",
            },
            "& .MuiInputBase-input": {
              height: "100% !important",
              display: "flex !important",
              alignItems: "center !important",
              justifyContent: "center !important",
              padding: "0 !important",
            },
          }}
          noOptionsText="No se encontró ningún resultado para tu búsqueda"
          renderOption={(props, option) => {
            return (
              <li
                {...props}
                key={option?.id}
                style={{
                  fontSize: "14px",
                  padding: "8px 12px",
                }}
              >
                {option.description}
              </li>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                style: {
                  fontSize: 14,
                  minWidth: "100%",
                  textOverflow: "unset",
                },
              }}
              placeholder="Retefuente"
              sx={{
                "& .MuiAutocomplete-input": {
                  fontSize: "14px",
                  minWidth: "100%",
                  textOverflow: "unset",
                },
              }}
            />
          )}
        />
      ),
    },
    {
      field: "amountTotal",
      headerName: "Valor Total",
      sortable: false,
      width: 105,
      headerAlign: "center",
      type: "number",
      editable: false,
      renderCell: () => "",
      cellClassName: "cell-hidden",
    },
    {
      field: "deleteItem",
      headerName: "",
      sortable: false,
      width: 60,
      editable: false,
      renderCell: () => "",
      cellClassName: "cell-hidden",
    },
  ];

  return columns;
};

export const stylesSelectedTaxs = {
  fontSize: "13px",
  width: "100%",
  "& fieldset": {
    border: "none",
  },
  "& .MuiSvgIcon-root": {
    display: "none",
  },
  "& .MuiInputBase-root": {
    width: "100%",
  },
  "& .MuiSelect-select": {
    padding: "0px !important",
    width: "100%",
    height: "100%",
    maxHeight: "50px",
    display: "flex",
    alignItems: "center",
    textOverflow: "ellipsis",
    whiteSpace: "normal",
  },
  "& .MuiAutocomplete-input": {
    fontSize: "14px",
  },
  "& .MuiAutocomplete-option": {
    fontSize: "14px",
  },
};
