import { SupplierRow } from "../commons/Table/components";
import { SortingHeader } from "../commons/Table/components";
import { isNewRow } from "./isNewRow";
import { formatLocaleDate } from "./formatLocaleDate";
import { CausationDetailColumn } from "screens";
import { PAYANA_DOCUMENT_NUMBER } from "constants/general";
import { NumericFormat } from "react-number-format";
import SyncPendingAmount from "./SyncPendingAmount";
import { FEATURES_FLAGS } from "commons/featureFlags/constants";

const rowIsIncomplete = (row) => {
  return (
    !row.original.provider_name ||
    !row.original.provider_document_type ||
    !row.original.provider_document_number ||
    !row.original.provider_bank_name ||
    (!row.original.provider_account_type &&
      row.original.provider_bank_name.toLowerCase() !== "nequi" &&
      row.original.provider_bank_name.toLowerCase() !== "daviplata") ||
    !row.original.provider_account_number
  );
};

export const invoicesColumns = ({
  setDrawerIsOpen,
  setSorting,
  sorting,
  navigateToCausation,
  isCausationAvailable,
  blockedCausation,
  refreshInvoiceTable,
  isFeatureEnabled,
}) => {
  const columns = [
    {
      field: "provider_name",
      headerName: "Proveedor",
      accessor: "provider_name",
      id: "provider_name",
      disableSortBy: true,
      className: "provider-name-column",
      Cell: (column) => {
        const cell = column.cell;
        const row = column.cell.row;
        const showPayanaIcon =
          +row.original.provider_document_number === PAYANA_DOCUMENT_NUMBER;

        return (
          <>
            {!row.original.service_name ? (
              <SupplierRow
                itemId={row.original.id}
                isNew={isNewRow(row)}
                setDrawerIsOpen={setDrawerIsOpen}
                cell={cell}
                isIncomplete={rowIsIncomplete(row)}
                showPayanaIcon={showPayanaIcon}
                linkTo="/payments/invoices/"
              />
            ) : (
              <p style={{ margin: 0 }}>{row.original.service_name}</p>
            )}
          </>
        );
      },
      Header: () => <div className="pill-header">Proveedor</div>,
    },
    {
      field: "invoice_number",
      headerName: "Comprobante",
      accessor: "invoice_number",
      id: "invoice_number",
      className: "invoice_number-column",
      disableSortBy: true,
      Header: () => <div className="pill-header">Comprobante</div>,
      Cell: ({ value }) => {
        if (value) {
          return value.toUpperCase();
        }
      },
    },
    {
      field: "issue_date",
      headerName: "Emisión",
      accessor: "issue_date",
      id: "issue_date",
      className: "invoice_dates-column",
      Cell: ({ value }) => {
        if (value) {
          return formatLocaleDate(value.slice(0, -1), "dd MMM, y");
        }
      },
      Header: () => {
        return (
          <SortingHeader
            fieldName="issue_date"
            label="Emisión"
            setSorting={setSorting}
            sorting={sorting}
          />
        );
      },
    },
    {
      field: "expiration_date",
      headerName: "Vencimiento",
      accessor: "expiration_date",
      id: "expiration_date",
      className: "invoice_dates-column",
      Cell: ({ value }) => {
        if (value) {
          return formatLocaleDate(value.slice(0, -1), "dd MMM, y");
        }
      },
      Header: () => {
        return (
          <SortingHeader
            fieldName="expiration_date"
            label="Vencimiento"
            setSorting={setSorting}
            sorting={sorting}
          />
        );
      },
    },
    ...(isFeatureEnabled(FEATURES_FLAGS.SYNC_PENDING_AMOUNT)
      ? [
          {
            field: "total_amount",
            headerName: "Monto",
            accessor: "original_total_amount",
            id: "original_total_amount",
            className: "original_total_amount-column",
            headerClassName: "original_total_amount-header",
            Header: () => {
              return (
                <SortingHeader
                  className="sorting-amount"
                  fieldName="invoices.amount"
                  label="Total"
                  setSorting={setSorting}
                  sorting={sorting}
                />
              );
            },
            Cell: ({ value }) => {
              return (
                <NumericFormat
                  value={value}
                  displayType="text"
                  thousandSeparator="."
                  decimalSeparator=","
                  prefix="$"
                  decimalScale={2}
                />
              );
            },
          },
          {
            field: "amount_total",
            headerName: "Saldo",
            accessor: "amount_total",
            id: "amount_total",
            className: "amount_total-column",
            headerClassName: "amount_total-header",
            Header: () => {
              return (
                <SortingHeader
                  className="sorting-amount_total"
                  fieldName="amount_total"
                  label="Saldo"
                  setSorting={setSorting}
                  sorting={sorting}
                />
              );
            },
            buttons: (cell) => {
              if (!cell.row.original.can_sync_pending_amount) {
                return null;
              }
              return (
                <SyncPendingAmount
                  invoiceId={cell.row.original.id}
                  onSuccess={refreshInvoiceTable}
                />
              );
            },
          },
        ]
      : [
          {
            field: "amount_total",
            headerName: "Monto",
            accessor: "amount_total",
            id: "amount_total",
            className: "amount_total-column",
            headerClassName: "amount_total-header",
            Header: () => {
              return (
                <SortingHeader
                  className="sorting-amount"
                  fieldName="amount_total"
                  label="Total"
                  setSorting={setSorting}
                  sorting={sorting}
                />
              );
            },
          },
        ]),
    {
      field: "status",
      headerName: "Estado",
      accessor: "status",
      id: "status",
      disableSortBy: true,
      Header: () => <div className="pill-header">Estado</div>,
    },
    {
      field: "tags",
      headerName: "Etiquetas",
      accessor: "tags",
      id: "tags",
      disableSortBy: true,
      Header: () => <div className="pill-header">Etiquetas</div>,
      Cell: ({ value }) => {
        const tags = value || [];
        const MAX_TAGS_TO_SHOW = 1;
        const tagsToShow = tags.slice(0, MAX_TAGS_TO_SHOW);
        const hiddenTags = tags.length - MAX_TAGS_TO_SHOW;
        const hiddenTagsAsTitle = tags
          .slice(MAX_TAGS_TO_SHOW)
          .map((tag) => tag.name)
          .join(", ");
        return (
          <div style={{ display: "flex" }}>
            {tagsToShow.map(({ id, name }) => {
              return (
                <p
                  key={id}
                  className="tag-pill-default"
                  style={{
                    maxWidth: "133px",
                    textOverflow: "ellipsis",
                  }}
                  title={name}
                >
                  {name}
                </p>
              );
            })}
            {hiddenTags > 0 ? (
              <p className="tag-pill-default" title={hiddenTagsAsTitle}>
                +{hiddenTags}
              </p>
            ) : null}
          </div>
        );
      },
    },
  ];

  if (isCausationAvailable && !blockedCausation) {
    const causationColumn = {
      field: "causation_state",
      headerName: "Causación",
      Header: () => <div className="pill-header">Causación</div>,
      accessor: "causation_state",
      id: "causation_state",
      Cell: (row) => {
        const originalRow = row.cell.row.original;
        return (
          <CausationDetailColumn
            row={originalRow}
            navigateToCausation={navigateToCausation}
          />
        );
      },
    };

    columns.splice(7, 0, causationColumn);
  }
  return columns;
};
