import { TextField } from "@mui/material";
import React from "react";
import { NumericFormat } from "react-number-format";
import { styles } from "./styles";
import { regexNumberWIthDecimal } from "utils";
import { Controller } from "react-hook-form";

export const InputAmount = ({
  payment,
  handleOnChangePayment,
  index,
  control,
  name = `payments.${index}.amount`,
  isCaused,
  required = false,
}) => {
  const handleOnChangeAmount = (value) => {
    if (!regexNumberWIthDecimal(value)) return;
    handleOnChangePayment(index, value, "amount");
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={payment?.amount || 0}
      rules={{
        required: required ? "Este campo no puede quedar incompleto." : false,
        validate: () => {
          if (!payment?.amount || payment?.amount <= 0) {
            return "Debe ser mayor a cero.";
          }
          return true;
        },
      }}
      render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
        <TextField
          name="paymentAmount"
          disabled={payment?.accounting_concept === "" || isCaused}
          value={payment?.amount || 0}
          type="text"
          onChange={(e) => {
            const newValue = +e.target.value;
            onChange(newValue);
            handleOnChangeAmount(newValue);
          }}
          inputRef={ref}
          error={!!error}
          helperText={error?.message}
          sx={{
            ...styles.inputAmount,
            "& .MuiInputBase-input": {
              fontSize: "14px",
            },
          }}
          InputProps={{
            inputComponent: MoneyFormatCustom,
            style: { fontSize: 14 },
          }}
        />
      )}
    />
  );
};

const MoneyFormatCustom = React.forwardRef(function NumberFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      thousandSeparator={"."}
      decimalSeparator={","}
      prefix={"$"}
      fixedDecimalScale={true}
      style={{
        textAlign: "right",
      }}
    />
  );
});
