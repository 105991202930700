import { PlusCircleIcon } from "assets";
import React from "react";

export const AddNewItem = ({ addNewTableInfo }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <p
        href="#"
        style={{
          textAlign: "center",
          margin: "20px 0px",
          color: "#5925DC",
          textDecoration: "none",
          cursor: "pointer",
          fontSize: "14px",
        }}
        onClick={(e) => {
          e.preventDefault();
          addNewTableInfo();
        }}
      >
        <PlusCircleIcon width="18" height="18" style={{ marginRight: "8px" }} />
        Agregar ítem
      </p>
    </div>
  );
};
