import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  useMemo,
} from "react";
import { TrackJS } from "trackjs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PaymentsContext } from "./PaymentsContext";
import { causationStates } from "constants";
import { useCausation, useSubscription } from "hooks";
import { SUBSCRIPTION_TYPE } from "constants";
import {
  DEFAULT_ERROR_MESSAGE,
  INITIAL_COUNT_GENERAL,
  INITIAL_ITEM_VALUES,
  INITIAL_PAYMENT_METHOD,
  INITIAL_PRODUCT,
  INITIAL_SEARCH,
  INITIAL_SUPPLIER,
  INITIAL_TAX,
  INITIAL_VALUES,
  ITEM_COLUMN_TYPE,
  MODAL_RESPONSE_INITIAL_STATE,
  MODAL_RESPONSE_OPEN_INITIAL_STATE,
  searchType,
  TAX_VALUES,
  typePaymentOnChange,
} from "commons/modals/ExpandCausationModal/constants";
import { toast } from "react-toastify";
import { toastOptions } from "constants";
import {
  checkCompanyNews,
  fixNumber,
  getAmountByPercentage,
  getDateByLocalTimeZone,
  truncateDecimalsDown,
  truncateDecimalsUp,
} from "utils";
import { objectIsEmpty } from "utils/objectUtils";
import { format } from "date-fns";
import { pdfState } from "constants";
import * as CausationService from "services/api/causation/Causation.service";
import { fetchDianPDF } from "services/fetch-dian-url";
import { GetInvoice } from "services";
import { UserContext } from "./UserContext";
import { useForm, useWatch, useFieldArray } from "react-hook-form";
import { CAUSATION_TYPES } from "constants";
import { erpNames } from "constants";

export const CausationContext = createContext();

export function CausationProvider({ children }) {
  const navigate = useNavigate();
  const location = useLocation();

  const { id } = useParams();
  const subscription = useSubscription({ type: SUBSCRIPTION_TYPE.CAUSATION });
  const {
    createInvoice,
    updateInvoice,
    uploadInvoiceFile,
    invoices,
    internalGetInvoices,
  } = useContext(PaymentsContext);
  const { currentCompany } = useContext(UserContext);
  const [originalItems, setOriginalItems] = useState([]);

  const [invoice, setInvoice] = useState({});
  const [checked, setChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [requestInProcess, setRequestInProcess] = useState(false);
  const hasErpCausation = !!currentCompany.erp_sheet_id;
  const erpName = currentCompany?.erp_name;

  const [content, setContent] = useState(INITIAL_COUNT_GENERAL);
  const [search, setSearch] = useState(INITIAL_SEARCH);
  const [payments, setPayments] = useState([{ ...INITIAL_PAYMENT_METHOD }]);
  const [paymentsAdvances, setPaymentsAdvances] = useState([]);
  const [amountTotalNeto, setAmountTotalNeto] = useState(0);
  const [dueDate, setDueDate] = useState(null);
  const [modalResponse, setModalResponse] = useState(
    MODAL_RESPONSE_INITIAL_STATE
  );
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [lastSyncCausationAt, setLastSyncCausationAt] = useState(null);
  const [activateOverlade, setActivateOverlade] = useState(false);
  const [supplierFound, setSupplierFound] = useState(false);

  const amountAvailable = useMemo(() => {
    const totalRegister = payments?.reduce((acc, curr) => {
      return acc + +curr.amount;
    }, 0);
    return fixNumber(amountTotalNeto - totalRegister) > 0
      ? fixNumber(amountTotalNeto - totalRegister)
      : 0;
  }, [payments, amountTotalNeto]);

  const handleSetPayments = useCallback((payments) => {
    setPayments(payments);
  }, []);

  const handleSetPaymentsAdvances = useCallback((advances) => {
    setPaymentsAdvances(advances);
  }, []);

  const isAdvance = "Cruzar anticipo";

  const updatePaymentMethodAmount = (amount) => {
    if (formValues?.isSuggested?.paymentMethod || payments.length === 1) {
      const updatedPayments = payments.map((payment, index) => {
        if (index === 0) {
          return {
            ...payment,
            amount: amount,
          };
        }
        return payment;
      });
      handleSetPayments(updatedPayments);
    }
  };

  useEffect(() => {
    if (content) {
      const totalNeto =
        content.subtotal +
        content.ivaTotal -
        content.retefuenteTotal -
        content.reteIcaValue -
        content.reteIvaValue;
      setAmountTotalNeto(totalNeto);
      updatePaymentMethodAmount(totalNeto);
    }
  }, [content]);

  const handleNextInvoice = async (id) => {
    let invoicesRow = invoices;
    try {
      if (!invoicesRow.length) {
        invoicesRow = await internalGetInvoices();
      }

      const index = invoicesRow.findIndex(
        (invoice) => invoice.id === parseInt(id)
      );
      if (index !== -1) {
        const nextInvoice = invoicesRow
          .slice(index + 1)
          .find(
            (invoice) =>
              invoice.causation_state === causationStates.draft &&
              invoice.origin === "dian"
          );
        if (nextInvoice) {
          navigate(`/payments/causation/${nextInvoice.id}`, {
            replace: true,
          });

          return window.location.reload();
        }
      }

      navigate("/payments/invoices", {
        replace: true,
      });
      return toast.info(
        "No se encontró una factura para continuar",
        toastOptions
      );
    } catch (error) {
      TrackJS.console.error("Error in handleNextCausation:", error);
    }
  };

  const { getItemAmountTotal, hasCompanyWareHouse } = useCausation();

  const {
    handleSubmit,
    formState: { errors },
    register,
    setValue,
    trigger,
    control,
  } = useForm({
    defaultValues: {
      ...INITIAL_VALUES,
      items: [
        {
          ...INITIAL_ITEM_VALUES,
          unitValue: location?.state?.amount || 0,
          amountTotal: location?.state?.amount || 0,
        },
      ],
    },
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });
  const formValues = useWatch({ control });
  const {
    fields: items,
    append,
    remove,
    update,
  } = useFieldArray({
    control,
    name: "items",
  });

  const isCollectionAccountCreation = location?.state?.createCollectionAccount;

  const handleOnChange = (event) => {
    let { value, name } = event.target;
    if (name === "documentType") {
      setValue("documentType", value);
      setValue(
        "isSuggested",
        { ...formValues.isSuggested, documentType: false },
        { shouldValidate: true }
      );
      if (!value.use_cost_center) {
        setValue("costCenter", "");
        setValue("isSuggested.costCenter", false);
      }
      uploadConsecutive(value?.consecutive);
      return;
    }

    setValue(name, value);
  };

  const uploadConsecutive = (consecutive) => {
    if (consecutive && currentCompany?.erp_name !== erpNames.siigoNube) {
      setValue("consecutive", consecutive);
    }
  };

  const handleOnChangeAutoComplete = async (fromType, newValue) => {
    try {
      if (fromType === "supplier" && newValue) {
        const payments = await CausationService.search(
          searchType.paymentMethod,
          "",
          newValue?.account_id
        );
        setSearch((prev) => ({ ...prev, paymentMethod: payments }));
      }
      setValue(fromType, newValue);
    } catch (error) {
      toast.error(
        "Ha ocurrido un error, no pudimos obtener los anticipos.",
        toastOptions
      );
    }
  };

  const addNewTableInfo = () => {
    append({
      ...INITIAL_ITEM_VALUES,
      id: crypto.randomUUID(),
    });
  };

  const handleDeleteItem = (idItem) => {
    const indexItem = formValues.items.findIndex((item) => item.id === idItem);
    if (indexItem === -1) {
      return;
    }

    if (formValues.items.length <= 1) {
      setValue("items", [{ ...INITIAL_ITEM_VALUES }]);
      return;
    }

    const updatedItems = formValues.items.filter(
      (_, index) => index !== indexItem
    );
    setValue("items", updatedItems);
  };

  const handleOnChangeTableItem = (params, field, value = null) => {
    const indexItem = formValues.items.findIndex(
      (item) => item.id === params.id
    );
    const updateItems = [...formValues.items];
    const itemToUpdate = updateItems[indexItem];
    const updateAmountTotal = (percentage) => {
      itemToUpdate.amountTotal = getItemAmountTotal(
        field,
        percentage,
        params.row
      );
    };

    switch (field) {
      case "taxIva":
        if (value !== null) {
          const taxIndex = search[field].findIndex((tax) => tax.id === value);
          itemToUpdate[field] = search[field][taxIndex];
          updateAmountTotal(search[field][taxIndex].percentage);
        } else {
          itemToUpdate[field] = { ...INITIAL_TAX };
          updateAmountTotal(0);
        }
        itemToUpdate.suggestedFields?.taxIva &&
          (itemToUpdate.suggestedFields.taxIva = false);
        break;

      case "taxRetefuente":
        if (value !== null) {
          const taxIndex = search[field].findIndex((tax) => tax.id === value);
          itemToUpdate[field] = search[field][taxIndex];
          updateAmountTotal(search[field][taxIndex].percentage);
        } else {
          itemToUpdate[field] = { ...INITIAL_TAX };
          updateAmountTotal(0);
        }
        itemToUpdate.suggestedFields?.taxRetefuente &&
          (itemToUpdate.suggestedFields.taxRetefuente = false);
        break;

      case "product":
        itemToUpdate[field] = value?.fcode ? value : INITIAL_PRODUCT;
        break;

      case "unitValue":
      case "discount":
      case "quantity":
        itemToUpdate[field] = value;
        updateAmountTotal(value);
        break;

      case "description":
      case "warehouse":
      case "costCenter":
        itemToUpdate[field] = value ? value : null;
        itemToUpdate.suggestedFields?.costCenter &&
          (itemToUpdate.suggestedFields.costCenter = false);
        break;

      default:
        itemToUpdate[field] = params.props.value;
    }

    update(indexItem, itemToUpdate);
  };

  const calculateContent = () => {
    let total = 0;
    let descuento = 0;
    let subtotal = 0;
    let iva = 0;
    let retefuente = 0;
    let reteIvaValue = 0;
    let reteIcaValue = 0;

    if (items.length <= 0) {
      setContent(INITIAL_COUNT_GENERAL);
    }

    for (const item of items) {
      let { unitValue, quantity, discount, taxIva, taxRetefuente } = item;
      discount = +discount;
      quantity = +quantity;

      const totalUnitValue = fixNumber(
        truncateDecimalsUp(quantity * unitValue)
      );
      const totalUnitWithDiscount = fixNumber(totalUnitValue - discount);

      total = fixNumber(total + totalUnitValue);
      descuento = fixNumber(descuento + discount);
      subtotal = fixNumber(total - descuento);

      iva = fixNumber(
        iva +
          truncateDecimalsUp(totalUnitWithDiscount * (taxIva.percentage / 100))
      );

      retefuente = fixNumber(
        retefuente +
          truncateDecimalsUp(
            totalUnitWithDiscount * (taxRetefuente.percentage / 100)
          )
      );
    }

    if (content.reteIca.id !== null) {
      reteIcaValue = getAmountByPercentage(
        subtotal,
        content.reteIca.percentage,
        1000
      );
    }

    if (content.reteIva.id !== null) {
      reteIvaValue = getAmountByPercentage(iva, content.reteIva.percentage);
    }

    setContent((prev) => {
      return {
        ...prev,
        totalGross: total,
        discount: descuento,
        subtotal: subtotal,
        ivaTotal: iva,
        retefuenteTotal: retefuente,
        reteIcaValue,
        reteIvaValue,
      };
    });
  };

  const calculateGeneralNetTotal = () => {
    const { subtotal, ivaTotal, retefuenteTotal, reteIvaValue, reteIcaValue } =
      content;

    const result = truncateDecimalsDown(
      subtotal + ivaTotal - retefuenteTotal - reteIvaValue - reteIcaValue
    );

    setAmountTotalNeto(result);
    updatePaymentMethodAmount(result);
  };

  const handleOnChangeGeneralTax = (e) => {
    const { name, value } = e.target;
    const { ivaTotal, subtotal } = content;

    if (name === "ReteIVA") {
      setContent((prev) => {
        return {
          ...prev,
          reteIva: value,
          reteIvaValue: getAmountByPercentage(ivaTotal, value.percentage),
        };
      });
      if (formValues.isSuggested?.reteIva) {
        setValue(
          "isSuggested",
          { ...formValues.isSuggested, reteIva: false },
          { shouldValidate: true }
        );
      }
    }

    if (name === "ReteICA") {
      setContent((prev) => {
        return {
          ...prev,
          reteIca: value,
          reteIcaValue: getAmountByPercentage(subtotal, value.percentage, 1000),
        };
      });
      if (formValues.isSuggested?.reteIca) {
        setValue(
          "isSuggested",
          { ...formValues.isSuggested, reteIca: false },
          { shouldValidate: true }
        );
      }
    }
  };

  const getTypeCausation = () => {
    if (isCreditNote) return CAUSATION_TYPES.siigoNubeNC;

    if (erpName) return CAUSATION_TYPES.sheet;

    return CAUSATION_TYPES.siigoNubeFC;
  };

  const handleSendCausation = async () => {
    const amountRegister = payments.reduce(
      (total, payment) => fixNumber(total + payment.amount),
      0
    );

    if (amountRegister !== amountTotalNeto) {
      return toast.error(
        "El monto total de los pagos no coincide con el total neto",
        toastOptions
      );
    }

    setRequestInProcess(true);

    const action = "create";
    const body = createSaveCausationBody(action);

    const storedSupplier = await CausationService.storeSupplier({
      id: formValues.supplier.id,
      type: getTypeCausation(),
    });

    let createdInvoice = {};

    if (!id) {
      const uploadedFile = await uploadInvoiceFile(selectedFiles);
      const date = new Date();
      createdInvoice = await createInvoice({
        invoice_number: formValues.nroComprobante,
        file_path: uploadedFile?.location,
        provider_id: storedSupplier.id,
        amount: amountTotalNeto,
        issue_date: date,
        type: "collection_account",
      });
    } else {
      await updateInvoice(id, {
        provider_id: storedSupplier.id,
      });
    }

    try {
      setModalResponse({
        ...MODAL_RESPONSE_OPEN_INITIAL_STATE,
        type: "loading",
      });
      const resp = await CausationService.sendCausation({
        ...body,
        invoiceID: createdInvoice?.id || id,
      });

      if (resp?.statusCode === 403) {
        setRequestInProcess(false);
        return setModalResponse({
          type: "error",
          open: true,
          textError:
            resp?.message === "causationService.causate.error"
              ? DEFAULT_ERROR_MESSAGE
              : resp?.message,
        });
      }

      if (resp?.statusCode === 202) {
        setRequestInProcess(false);
        return setModalResponse({
          ...MODAL_RESPONSE_OPEN_INITIAL_STATE,
          type: "success",
          statusCode: resp?.statusCode,
        });
      }

      if (typeof resp?.erpId === "number") {
        setRequestInProcess(false);
        return setModalResponse({
          ...MODAL_RESPONSE_OPEN_INITIAL_STATE,
          type: "success",
          supportDocument: resp?.supportDocument,
          checkSupportDocument: isCausationToDian,
        });
      }
      setRequestInProcess(false);
      return setModalResponse({
        type: "error",
        open: true,
        textError: DEFAULT_ERROR_MESSAGE,
      });
    } catch (error) {
      setRequestInProcess(false);
      return setModalResponse({
        ...MODAL_RESPONSE_OPEN_INITIAL_STATE,
        type: "error",
      });
    }
  };

  const handleSaveCausation = async (markAsCaused = false, redirect = true) => {
    try {
      setRequestInProcess(true);
      const action = "save";
      const body = createSaveCausationBody(action);

      const storedSupplier = await CausationService.storeSupplier({
        id: formValues.supplier.id,
        type: getTypeCausation(),
      });

      let createdInvoice = {};

      if (!id) {
        const uploadedFile = await uploadInvoiceFile(selectedFiles);
        const date = new Date();
        createdInvoice = await createInvoice({
          invoice_number: formValues.nroComprobante,
          file_path: uploadedFile?.location,
          provider_id: storedSupplier.id,
          amount: amountTotalNeto,
          issue_date: date,
          type: "collection_account",
        });
      } else {
        await updateInvoice(id, {
          provider_id: storedSupplier.id,
        });
      }

      const resp = await CausationService.saveCausation({
        ...body,
        invoiceID: createdInvoice?.id || id,
      });
      setRequestInProcess(false);
      if (!redirect) return;

      if (!objectIsEmpty(resp)) {
        navigate("/payments/invoices", { replace: true });
        return toast.success(
          markAsCaused
            ? "¡Factura marcada como causada con éxito!"
            : "¡Guardamos con la información con éxito!",
          toastOptions
        );
      } else {
        return toast.success("No pudimos guardar la información", toastOptions);
      }
    } catch (error) {
      setRequestInProcess(false);
      toast.error(
        "Ha ocurrido un error, por favor intente más tarde.",
        toastOptions
      );
    }
  };

  const getSiigoSupplier = async (supplierCausation, invoice, supplier) => {
    if (supplierCausation) {
      return supplierCausation;
    }

    if (invoice && invoice.provider_document_number) {
      if (supplierFound) {
        const res = await CausationService.search(
          searchType.supplier,
          invoice.provider_document_number
        );
        const supplierOptions = supplier.push(res.at(0));
        setSearch((prev) => ({
          ...prev,
          supplier: supplierOptions,
        }));

        return res.at(0);
      } else {
        return {
          email: "",
          full_name: invoice.provider_name?.toUpperCase(),
          id: null,
          document_number: invoice.provider_document_number,
        };
      }
    }

    return INITIAL_SUPPLIER;
  };

  const createSaveCausationBody = (action) => {
    let products = [];
    for (const item of items) {
      products.push({
        productID: item.product.id ? +item.product.id : "",
        quantity: item.quantity,
        description: item.description,
        unitValue: item.unitValue,
        discount: item.discount,
        taxIvaID: +item.taxIva.id ? item.taxIva.id : "",
        taxRetefuenteID: +item.taxRetefuente.id ? item.taxRetefuente.id : "",
        amountTotal: item.amountTotal,
        warehouse: item?.warehouse || "",
        costCenter: item?.costCenter || "",
      });
    }

    let paymentsAdvances = [];
    let paymentsMethods = [];
    for (const payment of payments) {
      if (
        payment?.accounting_concept === "Cruzar anticipo" &&
        action === "create"
      ) {
        paymentsAdvances.push({
          dueName: payment?.data?.DueName,
          amount: +payment?.amount,
          ACAccountCode: payment?.data?.ACAccountCode,
          AcDueBalanceID: payment?.data?.AcDueBalanceID,
          DuePrefix: payment?.data?.DuePrefix,
          DueConsecutive: payment?.data?.DueConsecutive,
        });
      }

      if (payment?.accounting_concept !== "Cruzar anticipo") {
        paymentsMethods.push({
          paymentMethodID: +payment?.data?.id,
          dueDate: payment?.data?.dueDate
            ? format(new Date(payment?.data?.dueDate), "yyyy-MM-dd")
            : null,
          amount: +payment?.amount,
          dueType: payment?.data?.due_type,
          shareNumber: payment?.share_number,
        });
      }
    }

    const isHasPayments = paymentsMethods.length || paymentsAdvances.length;

    return {
      invoiceTypeID: +formValues?.documentType?.id || null,
      supplierID: +formValues?.supplier?.id || null,
      payments: isHasPayments
        ? {
            ...(paymentsMethods.length && { paymentMethods: paymentsMethods }),
            ...(paymentsAdvances.length && {
              purchaseAdvances: paymentsAdvances,
            }),
          }
        : null,
      invoiceType: {
        nroComprobante: formValues?.nroComprobante || null,
        costCenter: formValues?.costCenter?.id || 0,
        dateElaboration: formValues.dateElaboration
          ? format(new Date(formValues.dateElaboration), "yyyy-MM-dd")
          : null,
        dateExpiration: format(
          new Date(formValues.dateExpiration),
          "yyyy-MM-dd"
        ),
      },
      products: products,
      totales: {
        totalBruto: content.totalGross,
        discount: content.discount,
        subtotal: content.subtotal,
        ivaTotal: content.ivaTotal,
        retefuenteTotal: content.retefuenteTotal,
        reteIvaValue: content.reteIvaValue,
        reteIvaID: +content.reteIva?.id ? content.reteIva.id : "",
        reteIcaValue: content.reteIcaValue,
        reteIcaID: +content.reteIca?.id ? content.reteIca.id : "",
        amountTotalNeto,
      },
      filePath: invoice?.file_path,
      observation: formValues?.observation,
      type: getTypeCausation(),
      ...(id && { invoiceID: +id }),
      ...(isCreditNote && {
        erpNdId: formValues.creditNote?.entryId,
        erpNdInvoiceNumber: formValues.creditNote?.docName,
      }),
      ...(isCausationToDian && {
        sendSupportDocument: isCausationToDian,
      }),
      ...(formValues?.consecutive && {
        internConsecutive: formValues?.consecutive,
      }),
    };
  };

  const getSearchsAndUpdateForm = async () => {
    try {
      const [
        invoiceTypes,
        supplier,
        paymentMethods,
        products,
        costCenter,
        taxIvas,
        ivaTypeFour,
        taxRetefuentes,
        taxReteIcas,
        taxReteIvas,
        invoice,
        causation,
      ] = await fetchInitialData();

      if (invoice && invoice.provider_document_number) {
        const res = await CausationService.searchErpSupplier({
          documentNumber: invoice.provider_document_number,
        });
        setSupplierFound(res);
      }

      setInvoice(invoice);
      setChecked(!!invoice?.file_path);
      setSearch((prev) => ({
        ...prev,
        invoiceType: invoiceTypes,
        supplier: supplier,
        product: products,
        paymentMethod: paymentMethods,
        taxIva: [...taxIvas, ...ivaTypeFour],
        taxRetefuente: taxRetefuentes,
        taxReteIca: taxReteIcas,
        taxReteIva: taxReteIvas,
        costCenter: costCenter,
      }));

      const findCostCenter = (costCenter) =>
        costCenter?.id === causation?.suggestion?.cost_center;
      const costCenterCoincidence = costCenter?.find(findCostCenter);
      setValue("costCenter", costCenterCoincidence);
      setValue("isSuggested.costCenter", !!causation?.suggestion?.cost_center);

      handleInvoiceState(invoice);

      if (causation?.products) {
        const mappedItems = await mapProducts({
          products: causation.products,
          searchProducts: products,
          searchTaxIvas: [...taxIvas, ...ivaTypeFour],
          searchTaxRetefuentes: taxRetefuentes,
          searchCostCenter: costCenter,
          suggestion: causation.suggestion,
        });
        setOriginalItems(mappedItems);
        setValue("items", mappedItems);
      }

      if (id) {
        await handleCausationData(
          causation,
          invoice,
          supplier,
          invoiceTypes,
          paymentMethods,
          taxReteIcas,
          taxReteIvas,
          products,
          taxRetefuentes,
          costCenter
        );
      }
      handleCreditNote(invoice);
    } catch (error) {
      TrackJS.console.error("Error getting initial causation data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const uploadForm = async () => {
    const causation = await CausationService.getCausationShow(id);
    const invoice = await GetInvoice({ id });
    if (invoice?.pdf_state === pdfState.in_process) return;

    const { issueDate, expirationDate, invoiceNumber } = causation;

    setInvoice(invoice);

    setValue(
      "nroComprobante",
      location?.state?.invoiceNumber ||
        invoiceNumber ||
        formValues.nroComprobante
    );
    setValue(
      "dateElaboration",
      location?.state?.issueDate
        ? location?.state?.issueDate
        : issueDate
        ? getDateByLocalTimeZone(issueDate)
        : null
    );
    setValue("dueDate", getDueDate(expirationDate, invoice));

    if (causation?.products) {
      const mappedItems = await mapProducts({
        products: causation.products,
        searchProducts: search.product,
        searchTaxIvas: search.taxIva,
        searchTaxRetefuentes: search.taxRetefuente,
        searchCostCenter: search.costCenter,
        suggestion: causation.suggestion,
      });
      setValue("items", mappedItems);
    }

    setActivateOverlade(false);
  };

  const fetchInitialData = async () => {
    return await Promise.all([
      CausationService.search(searchType.invoiceType),
      CausationService.search(searchType.supplier),
      CausationService.search(searchType.paymentMethod),
      CausationService.search(searchType.product),
      CausationService.search(searchType.costCenter),
      CausationService.search(searchType.tax, TAX_VALUES.iva),
      CausationService.search(searchType.tax, TAX_VALUES.ivaTypeFour),
      CausationService.search(searchType.tax, TAX_VALUES.retefuente),
      CausationService.search(searchType.tax, TAX_VALUES.reteIca),
      CausationService.search(searchType.tax, TAX_VALUES.reteIva),
      id && GetInvoice({ id }),
      id &&
        !isCollectionAccountCreation &&
        CausationService.getCausationShow(id),
    ]);
  };

  const handleInvoiceState = (invoice) => {
    if (invoice?.origin === "dian" && invoice?.type === "invoice") {
      setActivateOverlade(invoice?.pdf_state === pdfState.in_process);
    }
  };

  const formatValuePayment = (value, preValue, amountAvailable, dueDate) => {
    if (value === null) {
      return { ...INITIAL_PAYMENT_METHOD };
    }
    if (value?.accounting_concept === isAdvance) {
      return {
        ...preValue,
        accounting_concept: value?.accounting_concept,
        name: value?.accounting_concept,
        data: {},
      };
    }

    const { amount, accounting_concept, name, ...data } = value;
    return {
      ...preValue,
      accounting_concept: accounting_concept,
      name: name,
      data: { ...data, ...(value?.due_type === -1 && { dueDate: dueDate }) },
      amount: amountAvailable,
    };
  };

  const handleOnChangePayment = useCallback(
    (index, value, typeChange) => {
      const paymentsUpdate = [...payments];

      if (typeChange === typePaymentOnChange.payment) {
        paymentsUpdate[index] = formatValuePayment(
          value,
          paymentsUpdate[index],
          amountAvailable,
          dueDate
        );
        setValue("isSuggested.paymentMethod", false, { shouldValidate: true });
      } else if (typeChange === typePaymentOnChange.advance) {
        if (!value) {
          deselectAdvance(paymentsUpdate[index], paymentsUpdate, index);
          return;
        }
        setSelectedAdvance(value, paymentsUpdate, amountAvailable, index);
      } else if (typeChange === typePaymentOnChange.dueDate) {
        paymentsUpdate[index].data.dueDate = value;
      } else if (typeChange === typePaymentOnChange.amount) {
        paymentsUpdate[index].amount = value;
      } else if (typeChange === typePaymentOnChange.shareNumber) {
        paymentsUpdate[index].share_number = value;
      }

      handleSetPayments(paymentsUpdate);
    },
    [payments, amountTotalNeto]
  );

  const handleCausationData = async (
    causation,
    invoice,
    supplier,
    invoiceTypes,
    paymentMethods,
    taxReteIcas,
    taxReteIvas,
    products,
    taxRetefuentes,
    costCenter
  ) => {
    const {
      issueDate,
      expirationDate,
      invoiceNumber,
      supplier: supplierCausation,
      observation,
      erpNdInvoiceNumber,
      suggestion,
    } = causation;

    if (supplierCausation && supplierCausation?.account_id) {
      const payments = await CausationService.search(
        searchType.paymentMethod,
        "",
        supplierCausation.account_id
      );

      setSearch((prevSearch) => ({
        ...prevSearch,
        paymentMethod: payments,
      }));
    } else {
      const payments = await CausationService.search(
        searchType.paymentMethod,
        ""
      );
      setSearch((prevSearch) => ({
        ...prevSearch,
        paymentMethod: payments,
      }));
    }

    const findInvoiceType = (invoiceType) =>
      invoiceType?.id ===
      (suggestion?.invoice_type_id || causation?.invoiceType?.id);

    const findPaymentMethod = (paymentMethod) =>
      paymentMethod?.id === suggestion?.payment_method_id;

    const documentType = invoiceTypes.find(findInvoiceType);
    const paymentMethod = paymentMethods.find(findPaymentMethod);

    const suggestedFields = {
      documentType: !!suggestion?.invoice_type_id,
      paymentMethod: !!suggestion?.payment_method_id,
      costCenter: !!suggestion?.cost_center,
      products: !!suggestion?.products && suggestion?.products?.length > 0,
      reteIva: !!suggestion?.rete_iva_id,
      reteIca: !!suggestion?.rete_ica_id,
    };

    setValue("issueDate", issueDate);
    setValue("expirationDate", expirationDate);
    setValue("invoiceNumber", invoiceNumber);
    setValue("supplier", supplierCausation);
    setValue("observation", observation);
    setValue(
      "documentType",
      documentType || (invoiceTypes.length === 1 ? invoiceTypes[0] : null)
    );
    setValue("isSuggested", {
      documentType: suggestedFields.documentType,
      paymentMethod: suggestedFields.paymentMethod,
      costCenter: suggestedFields.costCenter,
      products: suggestedFields.products,
      reteIva: suggestedFields.reteIva,
      reteIca: suggestedFields.reteIca,
    });

    if (erpName !== erpNames.siigoNube) {
      if (documentType) {
        uploadConsecutive(documentType?.consecutive);
      } else if (invoiceTypes.length === 1) {
        uploadConsecutive(invoiceTypes[0]?.consecutive);
      }
    }

    let formattedPayment = null;
    if (paymentMethod) {
      formattedPayment = {
        accounting_concept: paymentMethod.accounting_concept,
        data: {
          name: paymentMethod.name,
          id: paymentMethod.id,
          due_type: paymentMethod.due_type,
          is_active: paymentMethod.is_active,
        },
        amount: amountTotalNeto,
      };

      handleSetPayments([formattedPayment]);
    }

    const siigoSupplier = await getSiigoSupplier(
      supplierCausation,
      invoice,
      supplier
    );

    const {
      invoiceType,
      paymentMethod: paymentMethodCausation,
      taxReteIca,
      taxReteIva,
    } = getFormValuesSave(
      causation,
      {
        invoiceTypes,
        paymentMethods,
        taxReteIcas,
        taxReteIvas,
      },
      suggestion
    );

    setValue(
      "nroComprobante",
      location?.state?.invoiceNumber ||
        invoiceNumber ||
        formValues.nroComprobante
    );
    setValue(
      "dateElaboration",
      location?.state?.issueDate
        ? location?.state?.issueDate
        : issueDate
        ? getDateByLocalTimeZone(issueDate)
        : null
    );
    setValue("dueDate", getDueDate(expirationDate, invoice));
    setValue("supplier", siigoSupplier);
    setValue(
      "paymentType",
      paymentMethodCausation ? paymentMethodCausation : INITIAL_PAYMENT_METHOD
    );
    setValue("observation", observation);
    setValue("noteCredit", { docName: erpNdInvoiceNumber });

    handleSetPayments(
      paymentMethodCausation.length > 0
        ? paymentMethodCausation
        : formattedPayment
        ? [formattedPayment]
        : [{ ...INITIAL_PAYMENT_METHOD }]
    );

    if (paymentMethodCausation.length > 0) {
      setValue("isSuggested.paymentMethod", false, { shouldValidate: true });
    }

    const findTaxReteIva = (taxReteIva) =>
      taxReteIva?.id === (suggestion?.rete_iva_id || causation?.reteIva?.id);

    const findTaxReteIca = (taxReteIca) =>
      taxReteIca?.id === (suggestion?.rete_ica_id || causation?.reteIca?.id);

    const taxReteIvaSuggestion = taxReteIvas?.find(findTaxReteIva);
    const taxReteIcaSuggestion = taxReteIcas?.find(findTaxReteIca);

    setContent((prev) => ({
      ...prev,
      reteIva: taxReteIvaSuggestion
        ? taxReteIvaSuggestion
        : taxReteIva
        ? taxReteIva
        : prev.reteIva,
      reteIca: taxReteIcaSuggestion
        ? taxReteIcaSuggestion
        : taxReteIca
        ? taxReteIca
        : prev.reteIca,
    }));

    if (causation?.products) {
      const mappedItems = await mapProducts({
        products: causation.products,
        searchProducts: products,
        searchTaxIvas: taxReteIva,
        searchTaxRetefuentes: taxRetefuentes,
        searchCostCenter: costCenter,
        suggestion: causation.suggestion,
      });
      setOriginalItems(mappedItems);
      setValue("items", mappedItems);
    }
  };

  const mapProducts = async ({
    products,
    searchProducts,
    searchTaxIvas,
    searchTaxRetefuentes,
    searchCostCenter,
    suggestion = null,
  }) => {
    const productPromises = [];
    if (suggestion?.products) {
      suggestion.products.forEach((suggestedItem) => {
        if (suggestedItem.product_id) {
          const exists = searchProducts.some(
            (p) => p.id === suggestedItem.product_id
          );
          if (!exists) {
            const promise = CausationService.search(
              searchType.productId,
              suggestedItem.product_id
            )
              .then((foundProducts) => {
                if (foundProducts && Array.isArray(foundProducts)) {
                  const matchedProduct = foundProducts.find(
                    (p) => p.id === suggestedItem.product_id
                  );
                  if (matchedProduct) {
                    searchProducts.push(matchedProduct);
                    setSearch((prev) => ({
                      ...prev,
                      product: [
                        ...prev.product.filter(
                          (p) => p.id !== matchedProduct.id
                        ),
                        matchedProduct,
                      ],
                    }));
                    return matchedProduct;
                  }
                }
                return null;
              })
              .catch((error) => {
                return null;
              });
            productPromises.push(promise);
          }
        }
      });
    }

    if (productPromises.length > 0) {
      await Promise.all(productPromises);
    }

    return products.map((product) => {
      const findProduct = (p) => p?.id === product.product?.id;

      const productsFind = getValuesFindSearch({
        searchType: "products",
        causationSearchSave: product?.product,
        callbackfind: findProduct,
        arrayToFind: searchProducts,
      });

      const findTaxIva = (taxIva) => taxIva?.id === product?.taxIva?.id;
      const taxIva = getValuesFindSearch({
        searchType: "taxIva",
        causationSearchSave: product?.taxIva,
        callbackfind: findTaxIva,
        arrayToFind: searchTaxIvas,
      });

      const findTaxReteFuente = (taxRetefuente) =>
        taxRetefuente.id === product?.taxRetefuente?.id;
      const taxRetefuente = getValuesFindSearch({
        searchType: "taxRetefuente",
        causationSearchSave: product?.taxRetefuente,
        callbackfind: findTaxReteFuente,
        arrayToFind: searchTaxRetefuentes,
      });

      let suggestedData = null;

      if (suggestion?.products) {
        const productIndex = products.indexOf(product);
        suggestedData = suggestion.products.find(
          (sp) => sp.item_id === productIndex + 1
        );
      }

      const suggestedFields = {
        product: !!suggestedData && !!suggestedData.product_id,
        warehouse: !!suggestedData && !!suggestedData.warehouse,
        taxIva: !!suggestedData && !!suggestedData.tax_iva_id,
        taxRetefuente: !!suggestedData && !!suggestedData.tax_retefuente_id,
        costCenter: !!suggestedData && !!suggestedData.cost_center,
      };

      let warehouseData = product?.warehouse;
      if (
        suggestedData?.warehouse &&
        typeof suggestedData.warehouse === "string"
      ) {
        try {
          warehouseData = JSON.parse(suggestedData.warehouse);
        } catch (e) {
          console.error("Error parsing warehouse JSON", e);
          warehouseData = product?.warehouse;
        }
      }

      let suggestedProduct = null;
      if (suggestedData?.product_id) {
        suggestedProduct = searchProducts.find(
          (p) => p.id === suggestedData.product_id
        );
      }

      let suggestedTaxIva = null;
      if (suggestedData?.tax_iva_id) {
        suggestedTaxIva = (
          Array.isArray(searchTaxIvas) ? searchTaxIvas : [searchTaxIvas]
        )?.find((t) => t.id === suggestedData.tax_iva_id);
      }

      let suggestedTaxRetefuente = null;
      if (suggestedData?.tax_retefuente_id) {
        suggestedTaxRetefuente = searchTaxRetefuentes?.find(
          (t) => t.id === suggestedData.tax_retefuente_id
        );
      }

      let suggestedCostCenter = null;
      if (suggestedData?.cost_center) {
        suggestedCostCenter = searchCostCenter.find(
          (c) => c.id === suggestedData.cost_center
        );
      }

      const item = {
        id: crypto.randomUUID(),
        type: null,
        product: !objectIsEmpty(productsFind)
          ? productsFind
          : suggestedProduct || { ...INITIAL_PRODUCT },
        description: product?.description || null,
        quantity: product?.quantity || 1,
        unitValue: product?.unitValue || 0,
        discount: product?.discount || 0,
        taxIva: !objectIsEmpty(taxIva)
          ? taxIva
          : suggestedTaxIva || { ...INITIAL_TAX },
        taxRetefuente: !objectIsEmpty(taxRetefuente)
          ? taxRetefuente
          : suggestedTaxRetefuente || { ...INITIAL_TAX },
        costCenter: product?.costCenter || suggestedCostCenter || null,
        isSelected: false,
        warehouse: warehouseData || null,
        taxIvaPercentage: product?.taxIvaPercentage || 0,
        suggestedFields: suggestedFields,
      };

      item.amountTotal = getItemAmountTotal("", 0, item);

      return item;
    });
  };

  const getDueDate = (expirationDate, invoice) => {
    if (expirationDate) {
      return getDateByLocalTimeZone(expirationDate);
    }

    if (invoice?.expiration_date) {
      return getDateByLocalTimeZone(invoice?.expiration_date);
    }

    return null;
  };

  const getFormValuesSave = (
    causation,
    { invoiceTypes, taxReteIcas, taxReteIvas },
    suggestion = null
  ) => {
    const findInvoiceType = (invoiceType) =>
      invoiceType?.id === causation?.invoiceType?.id;
    const invoiceType = getValuesFindSearch({
      searchType: "invoiceType",
      causationSearchSave: causation?.invoiceType,
      callbackfind: findInvoiceType,
      arrayToFind: invoiceTypes,
    });

    const paymentMethod = [];
    if (causation?.payments?.paymentMethods) {
      causation.payments.paymentMethods.forEach((payment) => {
        paymentMethod.push({
          accounting_concept: payment.accounting_concept,
          amount: +payment.amount,
          data: {
            dueDate: payment?.dueDate
              ? getDateByLocalTimeZone(payment.dueDate)
              : null,
            due_type: payment.due_type,
            id: payment.id,
          },
          name: payment.name,
        });
      });
    }

    if (causation?.payments?.purchaseAdvances) {
      causation.payments.purchaseAdvances.forEach((payment) => {
        paymentMethod.push({
          accounting_concept: "Cruzar anticipo",
          amount: +payment.amount,
          data: {
            DueName: payment.dueName,
            ACAccountCode: payment.ACAccountCode,
            AcDueBalanceID: payment.AcDueBalanceID,
            DuePrefix: payment.DuePrefix,
            DueConsecutive: payment.DueConsecutive,
            isFromSave: true,
          },
          name: payment.dueName,
        });
      });
    }

    const findTaxReteIca = (taxReteIca) =>
      taxReteIca.id === (suggestion?.rete_ica_id || causation?.reteIca?.id);
    const taxReteIca = getValuesFindSearch({
      searchType: "taxReteIca",
      causationSearchSave: causation?.reteIca,
      callbackfind: findTaxReteIca,
      arrayToFind: taxReteIcas,
    });

    const findReteIva = (taxReteIva) =>
      taxReteIva.id === (suggestion?.rete_iva_id || causation?.reteIva?.id);
    const taxReteIva = getValuesFindSearch({
      searchType: "taxReteIva",
      causationSearchSave: causation?.reteIva,
      callbackfind: findReteIva,
      arrayToFind: taxReteIvas,
    });

    return {
      invoiceType,
      paymentMethod,
      taxReteIca,
      taxReteIva,
    };
  };

  const getValuesFindSearch = ({
    searchType,
    causationSearchSave,
    callbackfind,
    arrayToFind = null,
  }) => {
    let findSearch;
    let arraySearch = [];

    if (Array.isArray(arrayToFind)) {
      arraySearch = arrayToFind;
    } else {
      arraySearch = search[searchType];
    }

    findSearch = arraySearch?.find(callbackfind);

    if (!findSearch && causationSearchSave) {
      findSearch = causationSearchSave;
    }

    return findSearch;
  };

  const getSearchOptions = async (type, query = "") => {
    if (query.length >= 3 || query.length === 0) {
      const resp = await CausationService.search(searchType[type], query);
      setSearch((prev) => ({
        ...prev,
        [type]: resp,
      }));
    }
  };

  const filePathIsFromDian = invoice?.file_path?.includes(
    "https://catalogo-vpfe.dian.gov.co/document"
  );

  useEffect(() => {
    if (filePathIsFromDian && id) {
      fetchDianPDF(id).then((response) => {
        response &&
          setInvoice({
            ...invoice,
            file_path: response.url,
            expiration_date: response.expiration_date,
          });
        setValue(
          "dueDate",
          !formValues.dueDate && response?.expiration_date
            ? getDateByLocalTimeZone(response.expiration_date)
            : null
        );
      });
    }
  }, [invoice?.file_path]);

  useEffect(() => {
    getSearchsAndUpdateForm();
    CausationService.checkSiigoCredential().then((res) => {
      setLastSyncCausationAt(res.syncValidation.lastSyncCausationAt);
    });
  }, [id]);

  useEffect(() => {
    calculateContent();
  }, [items]);

  useEffect(() => {
    calculateGeneralNetTotal();
  }, [content]);

  useEffect(() => {
    if (invoice?.pdf_state === pdfState.in_process && activateOverlade) {
      const newsInterval = setInterval(() => {
        checkCompanyNews()
          .then((updated) => {
            if (updated) {
              uploadForm();
              clearInterval(newsInterval);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }, 10000);
      return () => clearInterval(newsInterval);
    }
  }, [invoice]);

  const isCaused = invoice?.causation_state === causationStates.caused;

  const handleSelectAllItems = (e) => {
    const updatedItems = items.map((item, index) => {
      update(index, { ...item, isSelected: e.target.checked });
    });
  };

  const selectItem = (index) => {
    const item = items[index];
    update(index, { ...item, isSelected: !item?.isSelected });
  };

  const handleTaxChange = (item, field, value) => {
    const taxValue =
      value !== null
        ? search[field]?.find((tax) => tax.id === value)
        : INITIAL_TAX;
    item[field] = taxValue;
    item.amountTotal = getItemAmountTotal(
      field,
      taxValue?.percentage || 0,
      item
    );
  };

  const handleChangeMultipleSelect = (field, value) => {
    try {
      const updateItems = items.map((item) => {
        if (!item.isSelected) return item;

        const handlers = {
          [ITEM_COLUMN_TYPE.product]: () => {
            item[field] = value?.fcode ? value : INITIAL_PRODUCT;
            if (item?.suggestedFields) {
              item.suggestedFields.product = false;
            }
          },
          [ITEM_COLUMN_TYPE.taxIva]: () => {
            handleTaxChange(item, field, value);
            if (item?.suggestedFields) {
              item.suggestedFields.taxIva = false;
            }
          },
          [ITEM_COLUMN_TYPE.taxRetefuente]: () => {
            handleTaxChange(item, field, value);
            if (item?.suggestedFields) {
              item.suggestedFields.taxRetefuente = false;
            }
          },
          [ITEM_COLUMN_TYPE.warehouse]: () => {
            item[field] = item.product.is_inventory_control ? value : null;
            if (item?.suggestedFields) {
              item.suggestedFields.warehouse = false;
            }
          },
          [ITEM_COLUMN_TYPE.costCenter]: () => {
            item[field] = value ? value : null;
            if (item?.suggestedFields) {
              item.suggestedFields.costCenter = false;
            }
          },
        };

        if (handlers[field]) {
          handlers[field]();
        }

        return item;
      });

      setValue("items", updateItems);
    } catch (error) {
      console.error(error);
    }
  };

  const startTime = useRef(Date.now());

  useEffect(() => {
    startTime.current = Date.now();
  }, [id]);

  const handleDeletePayment = useCallback(
    (index) => {
      const payment = payments[index];
      if (payment.data?.AcDueBalanceID) {
        const advanceUpdate = paymentsAdvances.map((advance) => {
          if (advance.AcDueBalanceID === payment.data.AcDueBalanceID) {
            return {
              ...advance,
              isUsed: false,
            };
          }
          return advance;
        });
        handleSetPaymentsAdvances(advanceUpdate);
      }
      if (index > -1) {
        const paymentsUpdate = [...payments];
        paymentsUpdate.splice(index, 1);
        if (paymentsUpdate.length === 0) {
          handleSetPayments([{ ...INITIAL_PAYMENT_METHOD }]);
        } else {
          handleSetPayments(paymentsUpdate);
        }
      }
    },
    [payments, paymentsAdvances]
  );

  const deselectAdvance = (advancePrevValue, paymentsUpdate, index) => {
    if (advancePrevValue.data?.AcDueBalanceID) {
      const advanceUpdate = paymentsAdvances.map((advance) => {
        if (advance.AcDueBalanceID === advancePrevValue.data.AcDueBalanceID) {
          return {
            ...advance,
            isUsed: false,
          };
        }
        return advance;
      });
      handleSetPaymentsAdvances(advanceUpdate);
    }
    paymentsUpdate[index] = {
      ...paymentsUpdate[index],
      data: {},
      amount: 0,
    };

    handleSetPayments(paymentsUpdate);
  };

  const setSelectedAdvance = (
    value,
    paymentsUpdate,
    amountAvailable,
    index
  ) => {
    const advance = paymentsAdvances.find(
      (advance) => advance.AcDueBalanceID === value
    );

    const advancePrev = { ...paymentsUpdate[index].data };

    if (advance) {
      paymentsUpdate[index] = {
        ...paymentsUpdate[index],
        data: advance,
        amount:
          advance?.Value > amountAvailable ? amountAvailable : advance?.Value,
      };
      advance.isUsed = true;
      const advancesUpdate = paymentsAdvances.filter(
        (advance) => advance.AcDueBalanceID !== value
      );
      let advancePrevUpdate = advancesUpdate;
      if (advancePrev.AcDueBalanceID) {
        advancePrevUpdate = advancesUpdate.map((advance) => {
          if (advance.AcDueBalanceID === advancePrev.AcDueBalanceID) {
            return {
              ...advance,
              isUsed: false,
            };
          }
          return advance;
        });
      }
      handleSetPaymentsAdvances([...advancePrevUpdate, { ...advance }]);
    }
  };

  const [isCreditNote, setIsCreditNote] = useState(false);

  const handleCreditNote = async (invoice) => {
    if (
      currentCompany?.erp_name &&
      currentCompany?.erp_name !== erpNames.siigoNube
    )
      return;

    const isAmountNegative = invoice?.amount_total < 0 || false;
    setIsCreditNote(isAmountNegative);
    if (isAmountNegative) {
      setSearch((prev) => ({
        ...prev,
        invoiceType: prev.invoiceType.filter(
          (invoiceType) => invoiceType.erp_doc_class === "ND"
        ),
      }));
    }
  };

  const handleGetInvoicesNC = async (query) => {
    try {
      const resp = await CausationService.getInvoicesNC(query);
      setSearch((prev) => ({
        ...prev,
        noteCredit: resp,
      }));
    } catch (error) {
      toast.error("No se encontraron notas de crédito", toastOptions);
    }
  };

  const handleGetInformationInvoiceSiigo = async (invoiceNC) => {
    const resp = await CausationService.getInformationInvoiceSiigo(
      invoiceNC?.entryId
    );

    await validateUploadCreditNote(resp, invoiceNC);
  };

  const validateUploadCreditNote = async (creditNote, invoiceNC) => {
    if (
      creditNote?.causation?.costCenter &&
      typeof creditNote?.causation?.costCenter === "object"
    ) {
      setValue("costCenter", creditNote?.causation?.costCenter);
    }
    if (
      creditNote?.causation?.supplier &&
      typeof creditNote?.causation?.supplier === "object"
    ) {
      setValue("supplier", creditNote?.causation?.supplier);
    }

    if (
      creditNote?.causation?.products &&
      Array.isArray(creditNote?.causation?.products) &&
      creditNote?.causation?.products.length > 0
    ) {
      const mappedItems = await mapProducts({
        products: creditNote?.causation?.products,
        searchProducts: search.product,
        searchTaxIvas: search.taxIva,
        searchTaxRetefuentes: search.taxRetefuente,
        searchCostCenter: search.costCenter,
        suggestion: null,
      });
      setValue("items", mappedItems);
    }

    setValue("creditNote", { ...creditNote, docName: invoiceNC?.docName });
  };

  const [isCausationToDian, setIsCausationToDian] = useState(false);

  const handleSendCausationToDian = (value) => {
    setIsCausationToDian(value);
  };

  return (
    <CausationContext.Provider
      value={{
        id,
        invoice,
        checked,
        setChecked,
        isLoading,
        subscription,
        requestInProcess,
        setRequestInProcess,
        modalResponse,
        setModalResponse,
        handleSendCausation,
        handleSaveCausation,
        setActivateOverlade,
        getValuesFindSearch,
        setValue,
        getItemAmountTotal,
        search,
        activateOverlade,
        handleOnChange,
        handleOnChangeAutoComplete,
        getSearchOptions,
        isCaused,
        selectedFiles,
        setSelectedFiles,
        lastSyncCausationAt,
        supplierFound,
        setSupplierFound,
        filePathIsFromDian,
        hasCompanyWareHouse,
        handleChangeMultipleSelect,
        selectItem,
        handleSelectAllItems,
        payments,
        paymentsAdvances,
        amountTotalNeto,
        handleSetPayments,
        handleSetPaymentsAdvances,
        content,
        handleOnChangeGeneralTax,
        location,
        taxIva: search.taxIva,
        hasErpCausation,
        erpName,
        handleNextInvoice,
        startTime,
        handleSubmit,
        errors,
        register,
        trigger,
        formValues,
        items,
        addNewTableInfo,
        handleDeleteItem,
        handleOnChangeTableItem,
        control,
        handleOnChangePayment,
        handleDeletePayment,
        dueDate,
        amountAvailable,
        originalItems,
        handleSetOriginalItems: (items) => {
          setOriginalItems(items);
        },
        handleGetInvoicesNC,
        handleGetInformationInvoiceSiigo,
        isCreditNote,
        handleSendCausationToDian,
        isCausationToDian,
      }}
    >
      {children}
    </CausationContext.Provider>
  );
}
