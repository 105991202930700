export const handleSyncErpResponse = (
  res,
  onSuccess = () => {},
  setSyncBanner = () => {}
) => {
  const handleErrorMessage = {
    genericError: () => {
      setSyncBanner((syncBanner) => {
        return {
          ...syncBanner,
          visible: true,
          type: "error",
          title:
            "No pudimos importar los documentos. Por favor intenta de nuevo más tarde.",
        };
      });
    },
    wrongCredentials: () => {
      setSyncBanner((syncBanner) => {
        return {
          ...syncBanner,
          visible: true,
          type: "error",
          title: "La sincronización falló debido a credenciales incorrectas.",
        };
      });
    },
    siigoIntermittencies: () => {
      setSyncBanner((syncBanner) => {
        return {
          ...syncBanner,
          visible: true,
          type: "error",
          title: "No pudimos importar los documentos.",
          description:
            "Siigo se encuentra con intermitencias. Por favor, intenta de nuevo más tarde.",
        };
      });
    },
    fiveMinutes: () => {
      setSyncBanner((syncBanner) => {
        return {
          ...syncBanner,
          visible: true,
          type: "warning",
          title:
            "Para que puedas sincronizar nuevamente, es necesario que pasen al menos 5 minutos de la última sincronización.",
        };
      });
    },
    tenMinutes: () => {
      setSyncBanner((syncBanner) => {
        return {
          ...syncBanner,
          visible: true,
          type: "warning",
          title:
            "Para que puedas sincronizar nuevamente, es necesario que pasen al menos 10 minutos de la última sincronización.",
        };
      });
    },
    sheetError: () => {
      setSyncBanner((syncBanner) => {
        return {
          ...syncBanner,
          visible: true,
          type: "error",
          title:
            "La sincronización falló debido a errores en los datos del Sheet.",
        };
      });
    },
  };

  try {
    if (res?.message === "Request failed with status code 400") {
      const responseMessage = res.response.data.message;
      if (
        responseMessage === "Credenciales incorrectas (obtener token)" ||
        responseMessage === "Credentials is empty"
      ) {
        handleErrorMessage["wrongCredentials"]();
      } else if (responseMessage === "Error at syncing siigo causation") {
        handleErrorMessage["siigoIntermittencies"]();
      } else if (
        responseMessage === "Falló la lambda al subir las facturas." ||
        responseMessage === "Erp debe ser tipo Siigo." ||
        responseMessage === "Falta fecha de creación." ||
        responseMessage === "Request failed with status code 429" ||
        responseMessage === "Error at lambda siigo-causation"
      ) {
        handleErrorMessage["genericError"]();
      } else if (
        responseMessage === "You can try again in a few minutes." ||
        responseMessage === "Please retry in a few minutes."
      ) {
        handleErrorMessage["fiveMinutes"]();
      } else {
        handleErrorMessage["genericError"]();
      }
    } else if (
      res?.message === "Failed to make request." ||
      res?.message === "Please retry in a few minutes."
    ) {
      handleErrorMessage["tenMinutes"]();
    } else if (res?.message === "Error en sincronización") {
      handleErrorMessage["sheetError"]();
    } else {
      onSuccess();
    }
  } catch (error) {
    handleErrorMessage["genericError"]();
  }
};
