import EyeIcon from "assets/svg/EyeIcon";
import {
  AccountsIcon,
  AddFileIcon,
  AddresseeIcon,
  AIStarsIcon,
  Alegra,
  AlegraLogo,
  AlertIcon,
  AlertTriangleIcon,
  ApprovedIcon,
  ArrowDownIcon,
  ArrowIcon,
  ArrowLeftIcon,
  ArrowMenuIcon,
  ArrowNarrowBottomLeftIcon,
  ArrowNarrowUpRightIcon,
  ArrowRightIcon,
  ArrowRightWithoutStick,
  ArrowTopRight,
  ArrowUpIcon,
  AttentionIcon,
  BackgroundInformation,
  BancolombiaCircleIcon,
  BancolombiaIcon,
  BankIcon,
  BankNoteIcon,
  BannerErrorIcon,
  BellRingin,
  BlockIcon,
  CalendarIcon,
  CardIcon,
  CausationTutorialStepOne,
  CausationTutorialStepTwo,
  CausationTutorialStepZero,
  CheckCircleIcon,
  CheckDoneIcon,
  CheckedIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRight,
  ChevronUpIcon,
  CircleAlertIcon,
  CircleMas,
  ClipboardIcon,
  ClipIcon,
  CloseIcon,
  CollectionsIcon,
  CollectionsMobileIcon,
  CompanyIcon,
  ConfirmCobreStepIcon,
  ConnectedIcon,
  ConnectorIcon,
  CopyIcon,
  CopyUrlIcon,
  CornerDownRightIcon,
  CreateCompanyIcon,
  CreateUserIcon,
  CrossIcon,
  CrownIcon,
  CurrencyIcon,
  DataIcon,
  DaviviendaIcon,
  DeleteIcon,
  DianIconSvg,
  DianIntegrationIcon,
  DianMenuIcon,
  DocumentIcon,
  DocumentMenuIcon,
  Dot,
  DotsIcon,
  DownloadDocumentIcon,
  DownloadFileIcon,
  DownloadFileIcon2,
  DownloadFileIcon3,
  DownloadFileIcon4,
  EditIcon,
  EditUserIcon,
  EmailIcon,
  EmptyCheckIcon,
  EmptyProfile,
  EmptyStateIcon,
  EmptyStateSearchIcon,
  ErrorIcon,
  ExitIcon,
  ExpandArrowsIcon,
  ExpandIcon,
  ExportTutorialIcon,
  ExternalLinkIcon,
  FileAttachmentFilledIcon,
  FileAttachmentIcon,
  FileIcon,
  FileMenuIcon,
  FilePlusFilledIcon,
  FilePlusIcon,
  FileSearchIcon,
  FilesStackIcon,
  FilterIcon,
  GrupoAvalIcon,
  HelpCircleIcon,
  HelpIcon,
  IconCloseSmall,
  ImportIcon,
  InfoIcon,
  LightningIcon,
  ListCheckIcon,
  ListXIcon,
  LogoutIcon,
  MagicWandIcon,
  MarkIcon,
  MenuIcon,
  MiniLogoutIcon,
  MinusIcon,
  ModalDeleteIcon,
  ModalDownloadIcon,
  ModalFileIcon,
  ModalInfoIcon,
  ModalSuccessIcon,
  ModalWarningIcon,
  MoneyCurrentStepIcon,
  MoneyDoneIcon,
  MoneyDotIcon,
  MoneyFinishedIcon,
  MoneyIcon,
  MoneyRejectedIcon,
  MovementsNotReconciledEmpty,
  MovementsReconciledEmpty,
  MovementWithoutItems,
  MsgIcon,
  NewCheckIcon,
  NewIcon,
  NewMultipleSelect,
  NewStatusIcon,
  NewStatusMobile1Icon,
  NewStatusMobile2Icon,
  NewTxPanel,
  NotesIcon,
  OneFileIcon,
  PaginationDotIcon,
  PaperclipIcon,
  PartiallyReconciledIcon,
  PayanaIcon,
  PayanaLogo,
  PayanaModalLogo,
  PaymentIcon,
  PaymentsIcon,
  PayrollIcon,
  PercentageIcon,
  PhoneIcon,
  PlusCircleIcon,
  PlusIcon,
  PlusIcon2,
  PricingIcon,
  PseIcon,
  ReconciliationEmpty,
  ReconciliationRegisterEmpty,
  RectangleIcon,
  RefreshIcon,
  RefreshOneIcon,
  RejectedIcon,
  ReturnPendingIcon,
  RightArrowIcon,
  RoleIcon,
  RollbackIcon,
  SaveIcon,
  SearchIcon,
  SendIcon,
  SettingsIcon,
  SiigoIntegrationIcon,
  SiigoLogo,
  SiigoMenuIcon,
  SmallInfoIcon,
  StarIcon,
  StarsIcon,
  SuccessIcon,
  SwitchIcon,
  SyncIcon,
  Tick,
  TimerIcon,
  TrendDownIcon,
  TrxStepDoneIcon,
  TrxStepPendingIcon,
  UncheckedIcon,
  UpdateIcon,
  UploadCloudIcon,
  UserCheckIcon,
  UserIcon,
  UsersTwoIcon,
  WarningIcon,
  WppIcon,
  XIcon,
  ServiceIcon,
  SigmaIcon,
} from "assets";
import { Stack } from "@mui/material";
import { Tooltip } from "commons";
import { toastOptions } from "constants";
import { toast } from "react-toastify";
import { BalanceIcon } from "assets/svg/BalanceIcon";

const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
  toast.success("Copiado: " + text, toastOptions);
};

export const StorybookScreen = () => {
  const icons = [
    AccountsIcon,
    AddFileIcon,
    AddresseeIcon,
    AIStarsIcon,
    Alegra,
    AlegraLogo,
    AlertIcon,
    AlertTriangleIcon,
    ApprovedIcon,
    ArrowDownIcon,
    ArrowIcon,
    ArrowLeftIcon,
    ArrowMenuIcon,
    ArrowNarrowBottomLeftIcon,
    ArrowNarrowUpRightIcon,
    ArrowRightIcon,
    ArrowRightWithoutStick,
    ArrowTopRight,
    ArrowUpIcon,
    AttentionIcon,
    BancolombiaCircleIcon,
    BancolombiaIcon,
    BankIcon,
    BankNoteIcon,
    BannerErrorIcon,
    BellRingin,
    BlockIcon,
    CalendarIcon,
    CardIcon,
    CheckCircleIcon,
    CheckDoneIcon,
    CheckedIcon,
    CheckIcon,
    ChevronDownIcon,
    ChevronLeftIcon,
    ChevronRight,
    ChevronUpIcon,
    CircleAlertIcon,
    CircleMas,
    ClipboardIcon,
    ClipIcon,
    CloseIcon,
    CollectionsIcon,
    CollectionsMobileIcon,
    CompanyIcon,
    ConnectedIcon,
    ConnectorIcon,
    CopyIcon,
    CopyUrlIcon,
    CornerDownRightIcon,
    CreateUserIcon,
    CrossIcon,
    CrownIcon,
    CurrencyIcon,
    DataIcon,
    DaviviendaIcon,
    DeleteIcon,
    DianIconSvg,
    DianMenuIcon,
    DocumentIcon,
    DocumentMenuIcon,
    Dot,
    DotsIcon,
    DownloadDocumentIcon,
    DownloadFileIcon,
    DownloadFileIcon2,
    DownloadFileIcon3,
    DownloadFileIcon4,
    EditIcon,
    EditUserIcon,
    EmailIcon,
    EmptyCheckIcon,
    EmptyProfile,
    EmptyStateSearchIcon,
    ErrorIcon,
    ExitIcon,
    ExpandArrowsIcon,
    ExpandIcon,
    ExternalLinkIcon,
    EyeIcon,
    FileAttachmentFilledIcon,
    FileAttachmentIcon,
    FileIcon,
    FileMenuIcon,
    FilePlusFilledIcon,
    FilePlusIcon,
    FileSearchIcon,
    FilesStackIcon,
    FilterIcon,
    GrupoAvalIcon,
    HelpCircleIcon,
    HelpIcon,
    IconCloseSmall,
    ImportIcon,
    InfoIcon,
    LightningIcon,
    ListCheckIcon,
    ListXIcon,
    LogoutIcon,
    MagicWandIcon,
    MarkIcon,
    MenuIcon,
    MiniLogoutIcon,
    MinusIcon,
    ModalDeleteIcon,
    ModalDownloadIcon,
    ModalFileIcon,
    ModalInfoIcon,
    ModalSuccessIcon,
    ModalWarningIcon,
    MoneyCurrentStepIcon,
    MoneyDoneIcon,
    MoneyDotIcon,
    MoneyFinishedIcon,
    MoneyIcon,
    MoneyRejectedIcon,
    MsgIcon,
    NewCheckIcon,
    NewIcon,
    NotesIcon,
    OneFileIcon,
    PaginationDotIcon,
    PaperclipIcon,
    PartiallyReconciledIcon,
    PayanaIcon,
    PaymentIcon,
    PaymentsIcon,
    PayrollIcon,
    PercentageIcon,
    PhoneIcon,
    PlusCircleIcon,
    PlusIcon,
    PlusIcon2,
    PricingIcon,
    PseIcon,
    RectangleIcon,
    RefreshIcon,
    RefreshOneIcon,
    RejectedIcon,
    ReturnPendingIcon,
    RightArrowIcon,
    RoleIcon,
    SaveIcon,
    SearchIcon,
    SendIcon,
    SettingsIcon,
    SiigoLogo,
    SiigoMenuIcon,
    SmallInfoIcon,
    StarIcon,
    StarsIcon,
    SuccessIcon,
    SwitchIcon,
    SyncIcon,
    Tick,
    TimerIcon,
    TrendDownIcon,
    TrxStepDoneIcon,
    TrxStepPendingIcon,
    UncheckedIcon,
    UpdateIcon,
    UploadCloudIcon,
    UserCheckIcon,
    UserIcon,
    UsersTwoIcon,
    WarningIcon,
    WppIcon,
    XIcon,
    RollbackIcon,
    ServiceIcon,
    SigmaIcon,
    BalanceIcon,
  ];
  return (
    <Stack
      direction="row"
      flexWrap="wrap"
      justifyContent="center"
      spacing={1}
      sx={{ padding: 12 }}
    >
      {icons.map((Icon) => {
        const instance = <Icon />;
        return (
          <Tooltip
            title={`Click to copy <${instance.type.name} />`}
            placement="top"
          >
            <button
              className="button-without-default-style"
              onClick={() => copyToClipboard(`<${instance.type.name} />`)}
            >
              {instance}
            </button>
          </Tooltip>
        );
      })}
    </Stack>
  );
};
