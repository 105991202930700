import React, { useEffect } from "react";
import { useTable, usePagination, useRowSelect } from "react-table";
import { renderToString } from "react-dom/server";
import { NumericFormat } from "react-number-format";
import { useMediaQuery } from "react-responsive";
import {
  Box,
  IconButton,
  InputAdornment,
  Link,
  TextField,
} from "@mui/material";
import {
  CopyIcon,
  EditIcon,
  MoneyIcon,
  HelpIcon,
  SearchIcon,
  SendIcon,
  DownloadFileIcon,
} from "../../assets";
import {
  AmountInput,
  AmountRow,
  BankRow,
  BeneficiaryBankRow,
  BeneficiaryDocRow,
  BeneficiaryRow,
  DeleteIconContainer,
  ExpandItemContainer,
  ExpirationDateRow,
  FileRow,
  InvoiceNumberRow,
  Pagination,
  SourceOfFundsRow,
  StatusPill,
  TransactionsDateRow,
} from "./components";
import {
  DRAFT,
  IN_PROCESS,
  OUTSTANDING_BALANCE,
  PAID_OUTSIDE,
  PAYANA_DOCUMENT_NUMBER,
  PENDING,
  permissions,
  sectionsEnabled,
  SENT,
} from "../../constants";
import { styles } from "./styles";
import stylesCSS from "./styles.module.css";
import {
  useIsUserAllowedTo,
  formatCellDate,
  isRowEnabled,
  formatLocaleDate,
} from "../../utils";
import { format } from "date-fns";
import { Tooltip } from "commons/components";

export function Table({
  loading = false,
  loadingSkeleton = "loading...",
  columns,
  data,
  section,
  amountModal,
  expandIcon,
  expandLink,
  expandAction,
  editIcon,
  editAction,
  setSelectedRows = null,
  setPageIndex = null,
  pageNumber,
  checkboxVisible,
  pageCount,
  deleteIcon,
  openDeleteItemModal,
  copyIcon,
  copyAction,
  showAutocomplete = true,
  isBO = false,
  moneyIcon,
  emailIcon,
  helpOnClick,
  statusPillAction,
  hasFileIcon,
  toggleSelectedRow,
  isChecked = () => {},
  toggleSelectedAll,
  perPage,
  setPerPage,
  perPageVisible = false,
  statusSelected = null,
  providerIdSelected = null,
  serviceIdSelected = null,
  emptyState,
  extraFilters = false,
  thirdSearchPlaceholder,
  handleThirdSearchChange,
  onClickRow,
  tagsSelected,
  renderAutocomplete = () => {},
  renderRowEndButtons = () => {},
  tableStyles = {},
  showFilters = true,
  openSupplier = () => {},
  openEmployee = () => {},
  openCustomer = () => {},
  isCausationAvailable = false,
  causationFilter = "",
  showPagination = true,
}) {
  const { isUserAllowedTo } = useIsUserAllowedTo();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    selectedFlatRows,
    state: { pageIndex },
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      pageCount,
    },
    usePagination,
    useRowSelect,
    (hooks) => {
      if (!checkboxVisible) {
        return;
      }
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          Header: ({ toggleRowSelected, isAllPageRowsSelected, page }) => {
            const modifiedOnChange = (event) => {
              page.forEach((row) => {
                //check each row if it is not disabled
                isRowEnabled(section, row) &&
                  toggleSelectedAll(row.original, event.target.checked);
              });
            };

            //Count number of selectable and selected rows in the current page
            //to determine the state of select all checkbox
            let selectableRowsInCurrentPage = 0;
            let selectedRowsInCurrentPage = 0;
            page.forEach((row) => {
              checkboxVisible &&
                isChecked(row.original) &&
                selectedRowsInCurrentPage++;
              isRowEnabled(section, row) && selectableRowsInCurrentPage++;
            });

            //If there are no selectable rows in the current page
            //select all checkbox will be disabled -> see page 2
            const disabled = selectableRowsInCurrentPage === 0;
            const checked =
              (isAllPageRowsSelected ||
                selectableRowsInCurrentPage === selectedRowsInCurrentPage) &&
              !disabled;

            return checkboxVisible ? (
              <div className="pill-header">
                <label className="lbl-checkbox-header">
                  <input
                    type="checkbox"
                    className={stylesCSS.input}
                    checked={checked}
                    onChange={modifiedOnChange}
                    disabled={disabled}
                  />
                </label>
              </div>
            ) : null;
          },
          Cell: ({ row }) => {
            const isDisabledBySection = {
              causation: row.values.causation_state !== DRAFT,
              customer: row.values.status !== SENT,
              invoices:
                row.values.status !== PENDING &&
                row.values.status !== SENT &&
                row.values.status !== OUTSTANDING_BALANCE &&
                row.values.status !== PAID_OUTSIDE,
              services:
                !row.original.transaction_deleted_at &&
                (row.values.status !== PENDING ||
                  row.original.transaction_status === IN_PROCESS),
            };
            return checkboxVisible ? (
              <div style={{ display: "flex" }}>
                <label className="lbl-checkbox">
                  <Tooltip
                    title={
                      !row?.original?.is_delayed
                        ? ""
                        : "Este documento no puede seleccionarse por que es parte de una transacción en proceso."
                    }
                  >
                    <input
                      type="checkbox"
                      className={stylesCSS.input}
                      checked={isChecked(row.original)}
                      onChange={() => toggleSelectedRow(row.original)}
                      disabled={isDisabledBySection[section]}
                    />
                  </Tooltip>
                </label>
              </div>
            ) : null;
          },
        },
        ...columns,
      ]);
    }
  );

  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });

  useEffect(() => {
    gotoPage(0);
  }, [statusSelected]);

  setSelectedRows && setSelectedRows(selectedFlatRows);
  !pageNumber && setPageIndex && setPageIndex(pageIndex + 1);

  const rowIsIncomplete = (row) => {
    return (
      (((!row.original.employee_account_type &&
        row.original.employee_bank_name?.toLowerCase() !== "nequi" &&
        row.original.employee_bank_name?.toLowerCase() !== "daviplata") ||
        !row.original.employee_account_number ||
        !row.original.employee_document_number ||
        !row.original.employee_document_type) &&
        section === "salaries") ||
      (((!row.original.account_type &&
        row.original.bank_name?.toLowerCase() !== "nequi" &&
        row.original.bank_name?.toLowerCase() !== "daviplata") ||
        !row.original.account_number ||
        !row.original.document_number ||
        !row.original.document_type) &&
        section === "employees") ||
      ((!row.original.name ||
        !row.original.email ||
        row.original.email === "" ||
        !row.original.document_number ||
        !row.original.document_type) &&
        section === "collections") ||
      ((!row.original.provider_name ||
        !row.original.provider_document_type ||
        !row.original.provider_document_number ||
        !row.original.provider_bank_name ||
        (!row.original.provider_account_type &&
          row.original.provider_bank_name.toLowerCase() !== "nequi" &&
          row.original.provider_bank_name.toLowerCase() !== "daviplata") ||
        !row.original.provider_account_number) &&
        !row.original.service_id &&
        section === "invoices")
    );
  };

  const getRowStyle = (row) => {
    let styledRow = { cursor: "pointer", height: "40px" };
    let isIncomplete = rowIsIncomplete(row);
    const currentDate = new Date();
    const expired = currentDate >= new Date(row.original?.expiration_date);
    const isExpired =
      section === "customer" &&
      expired &&
      (row.original.status === "pending" || row.original.status === "sent");
    const isSelected = checkboxVisible
      ? isChecked(row.original)
      : row.isSelected;
    const isPaid =
      row.original.status === "paid" || row.original.status === "paid_outside";

    if (isExpired) {
      styledRow = { ...styledRow, backgroundColor: "#FEF3F2" };
    }

    if (isSelected) {
      styledRow = { ...styledRow, backgroundColor: "#F4F3FF" };
    }

    if (isSelected && isIncomplete) {
      styledRow = { ...styledRow };
    }

    if (isIncomplete && !isPaid) {
      styledRow = { ...styledRow };
    }

    if (sectionsEnabled.includes(section)) {
      styledRow = { ...styledRow, height: "40px" };
    }

    if (isMobile) {
      styledRow = { ...styledRow, height: "50px", width: "100%" };
    }

    if (section === "transactions" && row.original.type === "date") {
      styledRow = {
        backgroundColor: "#fdfefe",
        height: "42px",
      };
    }

    if (section === "transactions" && row.original.type === "section") {
      styledRow = {
        backgroundColor: "#F9FAFB",
        height: "48px",
      };
    }

    if (section === "reconciliations") {
      styledRow = { ...styledRow, height: "48px" };
    }

    return styledRow;
  };

  const getMobileRowStyle = (row) => {
    let styledRow = { ...styles.mobileRow, cursor: "pointer" };
    const currentDate = new Date();
    const expired = currentDate >= new Date(row.original?.expiration_date);
    const isExpired =
      section === "customer" &&
      expired &&
      (row.original.status === "pending" || row.original.status === "sent");

    if (isExpired) {
      styledRow = { ...styledRow, backgroundColor: "#FEF3F2" };
    }
    return styledRow;
  };

  const isStatusACH = (cell) =>
    cell.value === IN_PROCESS && cell.row.original.is_ach;

  const handleRowClick = (row, e) => {
    isMobile &&
      (row.values.status === "pending" ||
        row.values.status === "outstanding_balance") &&
      toggleSelectedRow(row.original);

    onClickRow &&
      onClickRow(row, e.target.tagName.toLowerCase(), e.target.outerHTML);
  };

  const getEmployeeNameStyle = (row) => {
    let styledCell = {};
    let incomplete = rowIsIncomplete(row);
    if (incomplete) {
      styledCell = { ...styledCell, color: "#B54708" };
    }
    return styledCell;
  };

  const rowEndButtons = (row) => {
    if (+row.original.document_number === PAYANA_DOCUMENT_NUMBER)
      return <td style={{ backgroundColor: "transparent" }}></td>;
    if (section === "receipts") {
      if (isMobile) {
        return (
          <>
            {hasFileIcon && (
              <Tooltip title="Descargar documento" offset={2}>
                <Link
                  href={
                    row.original.file_path
                      ? row.original.file_path
                      : row.original.file_path_provisional
                  }
                  target="_blank"
                >
                  <IconButton
                    disabled={
                      !row.original.file_path &&
                      !row.original.file_path_provisional
                    }
                  >
                    <DownloadFileIcon
                      size={20}
                      stroke={
                        row.original.file_path_provisional ||
                        row.original.file_path
                          ? "#98A2B3"
                          : "#98A2B380"
                      }
                    />
                  </IconButton>
                </Link>
              </Tooltip>
            )}
            {expandIcon && (
              <ExpandItemContainer
                linkTo={expandLink}
                action={expandAction}
                itemId={row.original.id}
              />
            )}
            {isUserAllowedTo(permissions.INVOICES_CREATE) &&
              moneyIcon &&
              row.original.status === "pay_later" && (
                <Tooltip title="Pagar">
                  <IconButton onClick={() => expandAction(row.original.id)}>
                    <MoneyIcon />
                  </IconButton>
                </Tooltip>
              )}
            {isUserAllowedTo(permissions.INVOICES_CREATE) && editIcon && (
              <Tooltip title="Editar">
                <IconButton onClick={() => editAction(row.original.id)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}
            {isUserAllowedTo(permissions.INVOICES_CREATE) &&
              deleteIcon &&
              row.original.status === "pay_later" && (
                <DeleteIconContainer
                  itemId={row.original.id}
                  openDeleteItemModal={openDeleteItemModal}
                />
              )}
          </>
        );
      } else {
        return (
          <td style={{ backgroundColor: "transparent" }}>
            {hasFileIcon &&
              (row.original?.file_path ||
                row.original?.file_path_provisional) && (
                <Tooltip title="Descargar documento" offset={2}>
                  <Link
                    href={
                      row.original.file_path
                        ? row.original.file_path
                        : row.original.file_path_provisional
                    }
                    target="_blank"
                  >
                    <IconButton
                      disabled={
                        !row.original.file_path &&
                        !row.original.file_path_provisional
                      }
                    >
                      <DownloadFileIcon
                        size={20}
                        stroke={
                          row.original.file_path_provisional ||
                          row.original.file_path
                            ? "#98A2B3"
                            : "#98A2B380"
                        }
                      />
                    </IconButton>
                  </Link>
                </Tooltip>
              )}
            {expandIcon && (
              <ExpandItemContainer
                linkTo={expandLink}
                action={expandAction}
                itemId={row.original.id}
              />
            )}
            {isUserAllowedTo(permissions.INVOICES_CREATE) &&
              moneyIcon &&
              row.original.status === "pay_later" && (
                <Tooltip title="Pagar">
                  <IconButton onClick={() => expandAction(row.original.id)}>
                    <MoneyIcon />
                  </IconButton>
                </Tooltip>
              )}
            {isUserAllowedTo(permissions.INVOICES_CREATE) && editIcon && (
              <Tooltip title="Editar">
                <IconButton onClick={() => editAction(row.original.id)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}
            {isUserAllowedTo(permissions.INVOICES_CREATE) &&
              deleteIcon &&
              row.original.status === "pay_later" && (
                <DeleteIconContainer
                  itemId={row.original.id}
                  openDeleteItemModal={openDeleteItemModal}
                />
              )}
          </td>
        );
      }
    } else if (
      section === "invoices" ||
      section === "customer" ||
      section === "customers" ||
      section === "collections" ||
      section === "suppliers" ||
      section === "services" ||
      section === "employees" ||
      section === "transactions" ||
      section === "BOReceipts" ||
      section === "causation" ||
      section === "reconciliations"
    ) {
      return renderRowEndButtons(row);
    } else {
      if (isMobile) {
        return (
          <>
            {emailIcon && (
              <IconButton sx={{ margin: "0px 10px" }}>
                <SendIcon />
              </IconButton>
            )}
            {expandIcon && (
              <ExpandItemContainer
                linkTo={expandLink}
                action={expandAction}
                itemId={row.original.id}
                section={section}
              />
            )}
            {copyIcon && (
              <IconButton onClick={() => copyAction(row.original.id)}>
                <CopyIcon />
              </IconButton>
            )}
            {editIcon && (
              <IconButton onClick={() => editAction(row.original.id)}>
                <EditIcon />
              </IconButton>
            )}
            {deleteIcon && (
              <DeleteIconContainer
                itemId={row.original.id}
                openDeleteItemModal={openDeleteItemModal}
              />
            )}
          </>
        );
      } else if (section === "BOPayroll") {
        return (
          <td>
            {helpOnClick && (
              <IconButton
                style={row.original.send_help && { opacity: "0.7" }}
                onClick={() => helpOnClick(row.original)}
                disabled={row.original.status === "paid"}
              >
                <HelpIcon
                  wasHelped={row.original?.send_support === 1}
                  isDisabled={row.original.status === "paid"}
                />
              </IconButton>
            )}
          </td>
        );
      } else {
        return (
          <td>
            {emailIcon && (
              <IconButton sx={{ margin: "0px 10px" }}>
                <SendIcon />
              </IconButton>
            )}
            {hasFileIcon && (
              <Tooltip title="Descargar documento" offset={2}>
                <Link href={row.original.file_path} target="_blank">
                  <IconButton disabled={row.original.file_path ? false : true}>
                    <DownloadFileIcon
                      size={20}
                      stroke={row.original.file_path ? "#98A2B3" : "#98A2B380"}
                    />
                  </IconButton>
                </Link>
              </Tooltip>
            )}
            {expandIcon && (
              <ExpandItemContainer
                linkTo={expandLink}
                action={expandAction}
                itemId={row.original.id}
                section={section}
              />
            )}
            {copyIcon && row.original.status !== "pay_later" && (
              <Tooltip title="Clonar">
                <IconButton onClick={() => copyAction(row.original.id)}>
                  <CopyIcon />
                </IconButton>
              </Tooltip>
            )}
            {((["invoiceTrx", "serviceTrx"].includes(section) &&
              isUserAllowedTo(permissions.INVOICES_CREATE)) ||
              (section === "payrollTrx" &&
                isUserAllowedTo(permissions.PAYROLLS_CREATE))) &&
              moneyIcon &&
              row.original.status === "pay_later" && (
                <Tooltip title="Pagar">
                  <IconButton onClick={() => expandAction(row.original.id)}>
                    <MoneyIcon />
                  </IconButton>
                </Tooltip>
              )}
            {editIcon && (
              <Tooltip title="Editar">
                <IconButton onClick={() => editAction(row.original.id)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}
            {((section === "invoiceTrx" &&
              isUserAllowedTo(permissions.INVOICES_DELETE)) ||
              (section === "payrollTrx" &&
                isUserAllowedTo(permissions.PAYROLLS_DELETE)) ||
              section === "salaries") &&
              deleteIcon && (
                <DeleteIconContainer
                  itemId={row.original.id}
                  openDeleteItemModal={openDeleteItemModal}
                />
              )}
          </td>
        );
      }
    }
  };

  return !loading &&
    statusSelected &&
    statusSelected === "all" &&
    providerIdSelected &&
    providerIdSelected === "all" &&
    serviceIdSelected &&
    serviceIdSelected === "all" &&
    data.length === 0 &&
    tagsSelected &&
    tagsSelected.length === 0 &&
    isCausationAvailable &&
    causationFilter === "" ? (
    emptyState
  ) : (
    <>
      {showFilters && (
        <div style={styles.tableContainer}>
          {showAutocomplete && !loading && renderAutocomplete()}
          {isBO && (
            <>
              {extraFilters && (
                <>
                  <TextField
                    size="small"
                    variant="outlined"
                    InputProps={{
                      style: { fontSize: 14, backgroundColor: "#ffffff" },
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      handleThirdSearchChange(e.target.value);
                    }}
                    sx={{
                      minWidth: "164px",
                      "& fieldset": {
                        borderRadius: "8px",
                      },
                    }}
                    placeholder={thirdSearchPlaceholder}
                  />
                </>
              )}
            </>
          )}
          {perPageVisible && !loading && (
            <div className="paginationNav" style={{ marginTop: "16px" }}>
              <select
                value={perPage}
                className="page-link"
                style={styles.paginationPerPage}
                onChange={(e) => {
                  setPerPage(Number(e.target.value));
                  gotoPage(0);
                  setPageIndex(0);
                }}
              >
                {[100, 150, 200].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Mostrar {pageSize}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>
      )}
      {loading && loadingSkeleton}
      {!loading && data.length > 0 ? (
        <table
          {...getTableProps()}
          className="table"
          cellSpacing="0"
          cellPadding="0"
          style={{
            ...tableStyles,
            backgroundColor: "#FFFFFF",
            marginLeft: "2.5%",
            marginRight: "2.5%",
          }}
        >
          {!isMobile && (
            <thead className="tablehead">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps()}
                      className={column.headerClassName}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                  <th></th>
                </tr>
              ))}
            </thead>
          )}
          <tbody {...getTableBodyProps()} className="tablebody">
            {page.map((row) => {
              prepareRow(row);
              if (section === "transactions" && row.original.type === "date") {
                const today = formatLocaleDate(new Date(), "dd/MM/yyyy");
                const date = formatLocaleDate(row.original.date, "dd/MM/yyyy");
                const formattedDate = new Date(
                  row.original.date
                ).toLocaleString("co", {
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                });

                return (
                  <tr {...row.getRowProps()} style={getRowStyle(row)}>
                    <td
                      style={{
                        backgroundColor: "#FCFCFD",
                        color: "#475467",
                        fontWeight: "500",
                        textTransform: "none",
                        ...(row.original.type === "date" && {
                          borderBottomWidth: "0px",
                        }),
                        ...(row.original.type === "section" && {
                          padding: "0px",
                          backgroundColor: "#F9FAFB",
                        }),
                      }}
                      colSpan="8"
                    >
                      {date === today
                        ? "Hoy"
                        : formattedDate.charAt(0).toUpperCase() +
                          formattedDate.slice(1).replace(",", "")}
                    </td>
                  </tr>
                );
              }
              return (
                <tr
                  {...row.getRowProps()}
                  style={getRowStyle(row)}
                  onClick={(e) => handleRowClick(row, e)}
                  className={
                    section === "transactions"
                      ? row.original.type === "section"
                        ? "firstBatch"
                        : row.original.type === "row"
                        ? "firstBatchRow"
                        : ""
                      : ""
                  }
                >
                  {isMobile && section === "invoices" ? (
                    <td style={styles.mobileRow}>
                      <div style={styles.mobileDiv}>
                        {checkboxVisible ? (
                          <div style={{ display: "flex", marginRight: "12px" }}>
                            <input
                              className={stylesCSS.input}
                              type="checkbox"
                              checked={isChecked(row.original)}
                              disabled={
                                (row.values.status !== "pending" &&
                                  row.values.status !==
                                    "outstanding_balance") ||
                                rowIsIncomplete(row)
                              }
                            />
                          </div>
                        ) : (
                          <div></div>
                        )}
                        <div style={styles.mobileColumn}>
                          <p style={styles.mobileSupplier}>
                            {row.original.service_name
                              ? row.original.service_name
                              : row.original.provider_name}
                          </p>
                          <p>{row.original.invoice_number}</p>
                        </div>
                      </div>
                      <div style={styles.mobileAmount}>
                        <p style={{ marginTop: "16px" }}>
                          <NumericFormat
                            value={parseInt(
                              renderToString(row.original.amount_total)
                            )}
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            prefix={"$"}
                            decimalScale={2}
                          />
                        </p>
                        <StatusPill
                          status={row.original.status}
                          isIncomplete={rowIsIncomplete(row)}
                          invoiceIsApproved={row.original.approved}
                          transactionStatus={row.original.transaction_status}
                          receipts={row.original.receipts}
                          section={section}
                          statusPaymentGateway={
                            row.original.status_payment_gateway
                          }
                        />
                      </div>
                      {rowEndButtons(row)}
                    </td>
                  ) : isMobile &&
                    (section === "suppliers" || section === "customers") ? (
                    <td style={styles.mobileRow}>
                      <div style={styles.mobileDiv}>
                        <div style={styles.mobileColumn}>
                          <p style={styles.mobileSupplier}>
                            {row.original.name}
                          </p>
                        </div>
                      </div>
                      <div style={styles.mobileAmount}>
                        <p style={styles.mobileSupplier}>
                          {row.original.email}
                        </p>
                      </div>
                      {rowEndButtons(row)}
                    </td>
                  ) : isMobile && section === "transactions" ? (
                    <td style={styles.mobileRow}>
                      <div style={styles.mobileDiv}>
                        <div style={styles.mobileColumn}>
                          <p style={styles.mobileSupplier}>
                            {formatCellDate(row.original?.date_hour)}
                          </p>
                          <p>{row.original?.hash?.toUpperCase()}</p>
                        </div>
                      </div>
                      <div style={styles.mobileAmount}>
                        <p style={{ marginTop: "16px" }}>
                          <NumericFormat
                            value={parseInt(
                              renderToString(row.original.amount)
                            )}
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            prefix={"$"}
                            decimalScale={2}
                          />
                        </p>
                        <StatusPill
                          status={row.original.status}
                          isIncomplete={rowIsIncomplete(row)}
                          invoiceIsApproved={row.original.approved}
                          section={section}
                        />
                      </div>
                      {rowEndButtons(row)}
                    </td>
                  ) : isMobile && section === "receipts" ? (
                    <td style={styles.mobileRow}>
                      <div style={styles.mobileDiv}>
                        <div style={styles.mobileColumn}>
                          <p style={styles.mobileSupplier}>
                            {row.original.provider_name}
                          </p>
                          <p>{formatCellDate(row.original?.created_at)}</p>
                        </div>
                      </div>
                      <div style={styles.mobileAmount}>
                        <p style={{ marginTop: "16px" }}>
                          <NumericFormat
                            value={parseInt(
                              renderToString(row.original.amount)
                            )}
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            prefix={"$"}
                            decimalScale={2}
                          />
                        </p>
                        <StatusPill
                          status={row.original.status}
                          isIncomplete={rowIsIncomplete(row)}
                          invoiceIsApproved={row.original.approved}
                          section={section}
                        />
                      </div>
                      {rowEndButtons(row)}
                    </td>
                  ) : isMobile && section === "salaries" ? (
                    <td style={styles.mobileRow}>
                      <div style={styles.mobileDiv}>
                        <div style={styles.mobileColumn}>
                          <p style={styles.mobileSupplier}>
                            {row.original.employee_name}
                          </p>
                          <p>{row.original.employee_account_number}</p>
                        </div>
                      </div>
                      <div style={styles.mobileAmount}>
                        <AmountInput row={row} id={row?.original?.id} />
                      </div>
                      {rowEndButtons(row)}
                    </td>
                  ) : isMobile && section === "employees" ? (
                    <td style={styles.mobileRow}>
                      <div style={styles.mobileDiv}>
                        <div style={styles.mobileColumn}>
                          <p style={styles.mobileSupplier}>
                            {row.original.name}
                          </p>
                          <p>{row.original.account_number}</p>
                        </div>
                      </div>
                      {rowEndButtons(row)}
                    </td>
                  ) : isMobile && section === "customer" ? (
                    <td
                      style={
                        isChecked(row.original)
                          ? styles.mobileSelectedRow
                          : getMobileRowStyle(row)
                      }
                    >
                      <div style={styles.mobileDiv}>
                        {checkboxVisible ? (
                          <div style={{ display: "flex", marginRight: "12px" }}>
                            <input
                              className={stylesCSS.input}
                              type="checkbox"
                              checked={isChecked(row.original)}
                              onChange={() => toggleSelectedRow(row.original)}
                              disabled={row.values.status !== "sent"}
                            />
                          </div>
                        ) : (
                          <div></div>
                        )}
                        <div style={styles.mobileColumn}>
                          <p style={styles.mobileSupplier}>
                            {row.original.concept}
                          </p>
                          {row.original.expiration_date && (
                            <p>
                              {format(
                                new Date(
                                  row.original.expiration_date?.slice(0, -1)
                                ),
                                "dd/MM/yyyy"
                              )}
                            </p>
                          )}
                        </div>
                      </div>
                      <div style={styles.mobileAmount}>
                        <p style={{ marginTop: "16px" }}>
                          <NumericFormat
                            value={parseInt(
                              renderToString(row.original.amount)
                            )}
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            prefix={"$"}
                            decimalScale={2}
                          />
                        </p>
                        <StatusPill
                          status={row.original.status}
                          section={section}
                        />
                      </div>
                    </td>
                  ) : isMobile && section === "collections" ? (
                    <td style={styles.mobileRow}>
                      <div style={styles.mobileDiv}>
                        {checkboxVisible ? (
                          <div style={{ display: "flex", marginRight: "12px" }}>
                            <input
                              className={stylesCSS.input}
                              type="checkbox"
                              checked={isChecked(row.original)}
                              disabled={
                                !isRowEnabled(section, row) ||
                                rowIsIncomplete(row)
                              }
                              onChange={() => toggleSelectedRow(row.original)}
                            />
                          </div>
                        ) : (
                          <div></div>
                        )}
                        <div style={styles.mobileColumn}>
                          <p style={styles.mobileSupplier}>
                            {row.original.name}
                          </p>
                          <p>{row.original.concept}</p>
                        </div>
                      </div>
                      <div style={styles.mobileAmount}>
                        <p style={{ marginTop: "16px" }}>
                          <NumericFormat
                            value={parseInt(
                              renderToString(row.original.amount)
                            )}
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            prefix={"$"}
                            decimalScale={2}
                          />
                        </p>
                        <StatusPill
                          status={row.original.status}
                          section={section}
                        />
                      </div>
                      {rowEndButtons(row)}
                    </td>
                  ) : (
                    row.cells.map((cell) => {
                      //-------------------------------------------------------------------------
                      if (section === "transactions") {
                        if (
                          row.original.type === "date" ||
                          row.original.type === "section"
                        ) {
                          return cell.column.field === "total_amount" ? (
                            <td
                              {...cell.getCellProps()}
                              className={cell.column.className}
                              style={{
                                backgroundColor: "#FCFCFD",
                                textTransform: "none",
                                color: "#475467",
                                fontWeight: "500",
                                ...(row.original.type === "section" && {
                                  fontWeight: "600",
                                  backgroundColor: "#F9FAFB",
                                }),
                              }}
                            >
                              <AmountRow
                                cell={cell}
                                amountModal={amountModal}
                                pageIndex={pageIndex}
                                status={statusSelected}
                                providerId={providerIdSelected}
                                toggleSelected={() =>
                                  toggleSelectedRow(row.original)
                                }
                                isChecked={isChecked(row.original)}
                                isInvoices={section === "invoices"}
                                prefix={
                                  cell.row.original.operation_type ===
                                    "collections" ||
                                  cell.row.original.operation_type ===
                                    "collection"
                                    ? "+$"
                                    : "-$"
                                }
                              />
                            </td>
                          ) : cell.column.field === "source_of_funds" ? (
                            <td
                              {...cell.getCellProps()}
                              style={{
                                backgroundColor: "transparent",
                                textTransform: "none",
                                color: "#475467",
                                fontWeight: "500",
                                ...(row.original.type === "section" && {
                                  fontWeight: "600",
                                  backgroundColor: "#F9FAFB",
                                }),
                              }}
                            >
                              <SourceOfFundsRow row={row} />
                            </td>
                          ) : cell.column.field === "date_hour" ? (
                            <td
                              {...cell.getCellProps()}
                              style={{
                                backgroundColor: "#FCFCFD",
                                textTransform: "none",
                                color: "#475467",
                                fontWeight: "500",
                                ...(row.original.type === "section" && {
                                  fontWeight: "600",
                                  backgroundColor: "#F9FAFB",
                                }),
                              }}
                            >
                              {cell.render("Cell")}
                            </td>
                          ) : (
                            <td
                              {...cell.getCellProps()}
                              style={{
                                backgroundColor: "#FCFCFD",
                                textTransform: "none",
                                color: "#475467",
                                fontWeight: "500",
                                ...(row.original.type === "section" && {
                                  padding: "0px",
                                  fontWeight: "600",
                                  backgroundColor: "#F9FAFB",
                                }),
                              }}
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        }
                        return cell.column.field === "status" ? (
                          <td
                            {...cell.getCellProps()}
                            style={{
                              backgroundColor: "transparent",
                            }}
                          >
                            <StatusPill
                              status={
                                isStatusACH(cell)
                                  ? "in_process_ach"
                                  : cell.value
                              }
                              isIncomplete={rowIsIncomplete(row)}
                              invoiceIsApproved={row.original.approved}
                              transactionStatus={
                                row.original.transaction_status
                              }
                              receipts={row.original.receipts}
                              section={section}
                              statusPaymentGateway={
                                row.original.status_payment_gateway
                              }
                              operationType={row.original.operation_type}
                              className={"firstBatchRowStatus"}
                              tooltipText={
                                isStatusACH(cell)
                                  ? "Puede tardar entre 24 y 72 hs"
                                  : undefined
                              }
                            />
                          </td>
                        ) : cell.column.field === "beneficiary_name" ? (
                          <td
                            {...cell.getCellProps()}
                            style={{
                              color: "#30374F",
                              fontWeight: "600",
                              maxWidth: "296px",
                            }}
                          >
                            {cell.render("Cell")}
                          </td>
                        ) : cell.column.field === "date_hour" ? (
                          <td
                            {...cell.getCellProps()}
                            style={{
                              backgroundColor: "transparent",
                            }}
                          >
                            <TransactionsDateRow cell={cell} />
                          </td>
                        ) : cell.column.field === "total_amount" ? (
                          <td
                            {...cell.getCellProps()}
                            className={cell.column.className}
                            style={{
                              backgroundColor: "transparent",
                            }}
                          >
                            <AmountRow
                              cell={cell}
                              amountModal={amountModal}
                              pageIndex={pageIndex}
                              status={statusSelected}
                              providerId={providerIdSelected}
                              toggleSelected={() =>
                                toggleSelectedRow(row.original)
                              }
                              isChecked={isChecked(row.original)}
                              isInvoices={section === "invoices"}
                              prefix={
                                (cell.row.original.operation_type ===
                                  "collections" ||
                                  cell.row.original.operation_type ===
                                    "collection") &&
                                cell.row.original.beneficiary_type !==
                                  "receiver"
                                  ? "+$"
                                  : "-$"
                              }
                            />
                          </td>
                        ) : cell.column.field === "source_of_funds" ? (
                          <td
                            {...cell.getCellProps()}
                            style={{
                              backgroundColor: "transparent",
                              textTransform: "none",
                              color: "#475467",
                              fontWeight: "500",
                              ...(row.original.type === "section" && {
                                fontWeight: "600",
                                backgroundColor: "#F9FAFB",
                              }),
                            }}
                          >
                            <SourceOfFundsRow row={row} />
                          </td>
                        ) : (
                          <td
                            {...cell.getCellProps()}
                            style={{ textTransform: "none" }}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      }
                      //-------------------------------------------------------------------------
                      if (section === "employees") {
                        return cell.column.field === "name" ? (
                          <td
                            {...cell.getCellProps()}
                            style={getEmployeeNameStyle(row)}
                          >
                            {cell.render("Cell")}
                          </td>
                        ) : (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      }
                      //--------------------------------------------------------------------------
                      if (section === "suppliers" || section === "customers") {
                        return cell.column.field === "bank_info" ? (
                          <td {...cell.getCellProps()}>
                            <BankRow cell={cell} section={section} />
                          </td>
                        ) : (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      }
                      //--------------------------------------------------------------------------
                      if (section === "salaries") {
                        return cell.column.field === "employee_name" ? (
                          <td
                            {...cell.getCellProps()}
                            style={getEmployeeNameStyle(row)}
                          >
                            {cell.render("Cell")}
                          </td>
                        ) : cell.column.field === "payroll_amount" ? (
                          <td
                            style={{ maxWidth: "150px" }}
                            {...cell.getCellProps()}
                          >
                            <AmountInput row={row} id={row?.original?.id} />
                          </td>
                        ) : cell.column.field === "status" ? (
                          <td
                            {...cell.getCellProps()}
                            style={{
                              backgroundColor: "transparent",
                            }}
                            className={cell.column.className}
                          >
                            <Box
                              sx={{
                                cursor: "pointer",
                              }}
                            >
                              <StatusPill
                                status={cell.value}
                                isIncomplete={rowIsIncomplete(row)}
                                section={section}
                              />
                            </Box>
                          </td>
                        ) : (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                        //-------------------------------------------------------------------------
                      } else if (
                        section === "BOReceipts" ||
                        section === "BOReceiptsServices"
                      ) {
                        return cell.column.field === "status" ? (
                          <td
                            {...cell.getCellProps()}
                            style={{
                              backgroundColor: "transparent",
                            }}
                          >
                            <Box
                              sx={{
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                statusPillAction(
                                  row.original.id,
                                  row.original.status
                                )
                              }
                            >
                              <StatusPill
                                status={cell.value}
                                isIncomplete={rowIsIncomplete(row)}
                              />
                            </Box>
                          </td>
                        ) : cell.column.field === "status_payment_gateway" &&
                          cell.row.original.payment_gateway === "cobre" ? (
                          <td
                            {...cell.getCellProps()}
                            style={{
                              backgroundColor: "transparent",
                            }}
                          >
                            <StatusPill
                              status={cell.value}
                              tooltipText={cell.row.original.error_message}
                            />
                          </td>
                        ) : cell.column.field === "amount" ? (
                          <td {...cell.getCellProps()}>
                            <AmountRow cell={cell} />
                          </td>
                        ) : cell.column.field === "file" ? (
                          <td
                            style={{ maxWidth: "150px" }}
                            {...cell.getCellProps()}
                          >
                            <FileRow
                              section={section}
                              cell={cell}
                              pageIndex={pageIndex}
                            />
                          </td>
                        ) : cell.column.field === "provider_name" ? (
                          <td {...cell.getCellProps()}>
                            <BeneficiaryRow
                              cell={cell}
                              openSupplier={openSupplier}
                              openEmployee={openEmployee}
                              openCustomer={openCustomer}
                            />
                          </td>
                        ) : cell.column.field === "provider_document_number" ? (
                          <td {...cell.getCellProps()}>
                            <BeneficiaryDocRow cell={cell} />
                          </td>
                        ) : cell.column.field === "bank_name" ? (
                          <td {...cell.getCellProps()}>
                            <BeneficiaryBankRow cell={cell} />
                          </td>
                        ) : (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                        // -------------------------------------------------------------------
                      } else if (
                        section === "collections" ||
                        section === "customer" ||
                        section === "invoiceTrx"
                      ) {
                        return cell.column.field === "status" ? (
                          <td
                            {...cell.getCellProps()}
                            style={{
                              backgroundColor: "transparent",
                            }}
                            className={cell.column.className}
                          >
                            <Box
                              sx={{
                                cursor: "pointer",
                              }}
                            >
                              <StatusPill
                                status={cell.value}
                                isIncomplete={rowIsIncomplete(row)}
                                section={section}
                              />
                            </Box>
                          </td>
                        ) : cell.column.field === "amount" ? (
                          <td
                            style={{
                              backgroundColor: "transparent",
                            }}
                            {...cell.getCellProps()}
                          >
                            <AmountRow cell={cell} />
                          </td>
                        ) : cell.column.field === "expiration_date" ? (
                          <td
                            style={{
                              backgroundColor: "transparent",
                            }}
                            {...cell.getCellProps()}
                          >
                            <ExpirationDateRow
                              cell={cell}
                              section={section}
                              pageIndex={pageIndex}
                            />
                          </td>
                        ) : (
                          <td
                            className={cell.column.className}
                            title={cell.value}
                            style={{
                              backgroundColor: "transparent",
                            }}
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                        // -------------------------------------------------------------------
                      } else {
                        return cell.column.field === "status" ? (
                          <td
                            {...cell.getCellProps()}
                            style={{
                              backgroundColor: "transparent",
                            }}
                          >
                            <StatusPill
                              status={cell.value}
                              isIncomplete={rowIsIncomplete(row)}
                              invoiceIsApproved={row.original.approved}
                              transactionStatus={
                                row.original.transaction_status
                              }
                              receipts={row.original.receipts}
                              section={section}
                              statusPaymentGateway={
                                row.original.status_payment_gateway
                              }
                            />
                          </td>
                        ) : cell.column.field === "amount_total" ||
                          cell.column.field === "amount" ? (
                          <td
                            {...cell.getCellProps()}
                            className={cell.column.className}
                            style={{
                              backgroundColor: "transparent",
                            }}
                          >
                            <AmountRow
                              cell={cell}
                              amountModal={amountModal}
                              pageIndex={pageIndex}
                              status={statusSelected}
                              providerId={providerIdSelected}
                              toggleSelected={() =>
                                toggleSelectedRow(row.original)
                              }
                              isChecked={isChecked(row.original)}
                              isInvoices={section === "invoices"}
                              buttons={cell.column.buttons}
                            />
                          </td>
                        ) : cell.column.field === "file" ? (
                          <td
                            {...cell.getCellProps()}
                            style={{
                              backgroundColor: "transparent",
                            }}
                          >
                            <FileRow
                              section={section}
                              cell={cell}
                              pageIndex={pageIndex}
                            />
                          </td>
                        ) : cell.column.id === "selection" ? (
                          <td
                            {...cell.getCellProps()}
                            style={{
                              backgroundColor: "transparent",
                              width: 75,
                            }}
                          >
                            {cell.render("Cell")}
                          </td>
                        ) : cell.column.field === "invoice_number" ? (
                          <td
                            {...cell.getCellProps()}
                            style={{
                              backgroundColor: "transparent",
                            }}
                          >
                            <InvoiceNumberRow cell={cell} />
                          </td>
                        ) : (
                          <td
                            {...cell.getCellProps()}
                            className={cell.column.className}
                            style={{
                              backgroundColor: "transparent",
                            }}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      }
                    })
                  )}
                  {!isMobile && rowEndButtons(row)}
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : !loading ? (
        emptyState
      ) : null}
      {showPagination && !loading && (
        <div style={{ ...styles.tableContainer, marginBottom: "20px" }}>
          <Pagination
            canPreviousPage={pageNumber ? pageNumber > 1 : canPreviousPage}
            previousPage={
              pageNumber ? () => setPageIndex(pageNumber - 1) : previousPage
            }
            pageIndex={pageNumber ? pageNumber : pageIndex + 1}
            pageOptions={pageOptions}
            canNextPage={pageNumber ? pageNumber < pageCount : canNextPage}
            nextPage={
              pageNumber ? () => setPageIndex(pageNumber + 1) : nextPage
            }
            pageSize={perPage}
            setPerPage={setPerPage}
            perPageVisible={perPageVisible}
            gotoPage={gotoPage}
            setPageIndex={setPageIndex}
          />
        </div>
      )}
    </>
  );
}
