export const ThirdButton = ({
  text,
  action,
  isDisable = false,
  height = "40px",
  width = "100px",
  fontSize = "14px",
  style = {},
  icon = null,
  textStyle = {},
}) => {
  const styles = {
    backgroundColor: isDisable ? "#F2F3FF80" : "#F2F3FF",
    borderRadius: "8px",
    height: height,
    color: isDisable ? "#5925DC80" : "#5925DC",
    fontSize: fontSize,
    fontWeight: "500",
    lineHeight: "20px",
    width: width,
    border: "none",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#F2F3Fb",
    },
    ...style,
  };

  return (
    <button
      type="button"
      onClick={action}
      disabled={isDisable}
      style={styles}
      className={
        isDisable
          ? "payana-third-primary-button-disabled"
          : "payana-third-primary-button"
      }
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "8px",
        }}
      >
        {icon}
        <span style={textStyle}>{text}</span>
      </div>
    </button>
  );
};
