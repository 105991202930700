import React, { useContext, useState } from "react";
import { renderToString } from "react-dom/server";
import { NumericFormat } from "react-number-format";
import { IconButton } from "@mui/material";
import { styles } from "../styles";
import { AmountModal } from "../../modals";
import { PAYANA_DOCUMENT_NUMBER, permissions } from "../../../constants";
import { useIsUserAllowedTo } from "../../../utils";
import { Show, Tooltip } from "commons/components";
import { EditIcon } from "assets";
import { FEATURES_FLAGS } from "commons/featureFlags/constants";
import { UserContext } from "contexts";

export const AmountRow = ({
  cell,
  amountModal,
  pageIndex,
  status,
  providerId,
  toggleSelected,
  isChecked = null,
  isInvoices = false,
  prefix = "$",
  buttons,
}) => {
  const { isFeatureEnabled } = useContext(UserContext);
  const { isUserAllowedTo } = useIsUserAllowedTo();
  const [visible, setVisible] = useState(false);

  const handleClose = () => setVisible(false);
  const handleVisible = () => setVisible(true);
  const isPayana =
    +cell.row.original.provider_document_number === PAYANA_DOCUMENT_NUMBER;

  const customButtons = buttons && buttons(cell);

  const showEditAmountButton =
    amountModal &&
    !isPayana &&
    (cell.row.original.status === "pending" ||
      cell.row.original.status === "outstanding_balance") &&
    isUserAllowedTo(permissions.INVOICES_UPDATE) &&
    (!isFeatureEnabled(FEATURES_FLAGS.SYNC_PENDING_AMOUNT) ||
      (isFeatureEnabled(FEATURES_FLAGS.SYNC_PENDING_AMOUNT) &&
        !cell.row.original.can_sync_pending_amount));

  return (
    <>
      <div style={isInvoices ? styles.invoicesAmountRow : styles.amountRow}>
        <p style={styles.revert}>
          <NumericFormat
            value={parseInt(
              renderToString(
                cell.row.original.operation_type === "collection" &&
                  cell.row.original.beneficiary_type !== "receiver"
                  ? cell.row.original.batch_total_amount
                  : cell.render("Cell")
              )
            )}
            displayType="text"
            thousandSeparator="."
            decimalSeparator=","
            prefix={prefix}
            decimalScale={2}
            allowNegative={true}
          />
        </p>
        <Show condition={showEditAmountButton && !customButtons}>
          <Tooltip title="Editar monto">
            <IconButton onClick={handleVisible} sx={{ width: 35, height: 35 }}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        </Show>
        {customButtons}
        <Show condition={!showEditAmountButton && !customButtons}>
          <IconButton disabled sx={{ width: 35, height: 35 }}></IconButton>
        </Show>
      </div>
      <AmountModal
        visible={visible && !isPayana}
        handleClose={handleClose}
        id={cell.row.original.id}
        pageIndex={pageIndex}
        status={status}
        providerId={providerId}
        toggleSelected={toggleSelected}
        isChecked={isChecked}
      />
    </>
  );
};
