export const styles = {
  modalBox: {
    borderBottom: 1,
    borderColor: "divider",
  },
  modalSecondaryButton: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #D0D5DD",
    borderRadius: "8px",
    color: "#344054",
    fontWeight: "500",
    fontSize: "16px",
  },
  modalPrimaryButton: {
    backgroundColor: "#6938EF",
    border: "1px solid #6938EF",
    borderRadius: "8px",
    color: "#FFFFFF",
    fontWeight: "500",
    fontSize: "16px",
  },
  headerRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  revert: {
    margin: "revert",
  },
  tabPanelTitle: {
    color: "#344054",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "20px",
  },
  tabPanelText: {
    color: "#667085",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "18px",
  },
  withholdingsCard: {
    marginTop: "24px",
  },
  withholdingsCardBody: {
    padding: "0px 12px 0px 12px",
  },
  withholdingsCardContent: {
    display: "flex",
    justifyContent: "space-between",
    height: "36px",
  },
  contentSecondaryText: {
    margin: "revert",
    color: "#475467",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "20px",
  },
  separator: {
    height: "2px",
    backgroundColor: "#E9ECF0",
    opacity: "1",
    border: "0",
  },
  withholdingsCardTotal: {
    display: "flex",
    justifyContent: "space-between",
    height: "42px",
  },
  contentPrimaryText: {
    color: "#101828",
    fontSize: "18px",
    fontWeight: "500",
    lineHeight: "28px",
  },
  invoiceApprovalsCard: {
    width: "-webkit-fill-available",
    backgroundColor: "#EFF2F6",
    borderRadius: "8px",
    borderColor: "#EFF2F6",
  },
  invoiceCardTitle: {
    color: "#344054",
    fontWeight: "500",
    fontSize: "14px",
  },
  invoiceCardTitleRevert: {
    color: "#344054",
    fontWeight: "500",
    fontSize: "14px",
  },
  invoiceSelectText: {
    color: "#101828",
    fontWeight: "500",
    fontSize: "14px",
  },
  invoiceRow: {
    marginTop: "24px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  invoiceCard: {
    width: "-webkit-fill-available",
    marginRight: "12px",
    backgroundColor: "#EFF2F6",
    borderRadius: "8px",
    borderColor: "#EFF2F6",
  },
  invoiceLastCard: {
    width: "-webkit-fill-available",
    backgroundColor: "#EFF2F6",
    borderRadius: "8px",
    borderColor: "#EFF2F6",
  },
  invoiceSupplierCardTitle: {
    color: "#667085",
    fontWeight: "400",
    fontSize: "14px",
  },
  invoiceSupplierCardText: {
    color: "#344054",
    fontWeight: "500",
    fontSize: "14px",
  },
  invoiceInputText: {
    color: "#344054",
    fontWeight: "500",
    fontSize: "14px",
    borderRadius: "8px",
    borderWidth: "1px",
    height: "40px",
    paddingLeft: "8px",
    paddingRight: "8px",
    borderColor: "#0000002d",
  },
  invoiceColumn: {
    display: "flex",
    flexDirection: "column",
    width: "-webkit-fill-available",
    margin: "revert",
  },
  invoiceTotalsContainer: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    margin: "revert",
    marginLeft: "auto",
    marginRight: "0",
  },
  invoiceTotalsRow: {
    display: "flex",
    flexDirection: "row",
    margin: "revert",
  },
  invoiceTotalsColumn: {
    display: "flex",
    flexDirection: "column",
    margin: "revert",
    marginLeft: "auto",
    marginRight: "0",
    width: "50%",
  },
  invoiceTotalsSecondaryText: {
    color: "#475467",
    fontWeight: "500",
    fontSize: "14px",
    textAlign: "end",
  },
  invoiceTotalsPrimaryText: {
    color: "#101828",
    fontWeight: "500",
    fontSize: "18px",
    textAlign: "end",
  },
  amountModalTab: {
    fontSize: "14px",
    color: "#1D2939",
    fontWeight: "400",
    textTransform: "none",
  },
  skeletonMargins: {
    marginLeft: "2.5%",
    marginRight: "2.5%",
    marginTop: "24px",
  },
  rowSpaceAround: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  amountRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%",
    alignItems: "center",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    width: "100%",
    alignItems: "center",
  },
  expirationEmptyRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    border: "1px solid #B54708",
    borderRadius: "8px",
    padding: "8px",
    height: "36px",
  },
  invoicesAmountRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%",
    alignItems: "center",
  },
  disclaimerContainer: {
    backgroundColor: "#fcfaff",
    border: "1px solid #eaecf0",
    borderRadius: "8px",
    margin: "20px 0px",
    padding: "8px",
    color: "#7a5af8",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: "20px",
  },
  BOInvoiceCard: {
    width: "-webkit-fill-available",
    marginRight: "12px",
    backgroundColor: "#EFF2F6",
    borderRadius: "8px",
    borderColor: "#EFF2F6",
    display: "flex",
    flexDirection: "row",
    padding: "8px",
    height: "40px",
    alignItems: "center",
  },
  BOInvoiceSupplierCardTitle: {
    color: "#667085",
    fontWeight: "400",
    fontSize: "14px",
    marginTop: "8px",
    marginRight: "4px",
  },
  BOInvoiceSupplierCardText: {
    color: "#344054",
    fontWeight: "500",
    fontSize: "14px",
    marginTop: "8px",
  },
  BOInvoiceLastCard: {
    width: "-webkit-fill-available",
    backgroundColor: "#EFF2F6",
    borderRadius: "8px",
    borderColor: "#EFF2F6",
    display: "flex",
    flexDirection: "row",
    padding: "8px",
    height: "40px",
    alignItems: "center",
  },
  nonShadow: {
    boxShadow: "none",
  },
  title: {
    marginLeft: "16px",
    fontSize: "18px",
  },
  paginationFont: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#344054",
  },
  paginationPerPage: {
    height: "31px",
    fontSize: "14px",
  },
  tableContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginLeft: "2.5%",
    marginRight: "2.5%",
    height: "40px",
    marginTop: "16px",
    gap: "20px",
  },
  mobileRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  mobileSelectedRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#F4F3FF",
  },
  mobileDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "60%",
  },
  mobileColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  mobileRowData: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  mobileSupplier: {
    marginTop: "16px",
    textOverflow: "ellipsis",
    color: "#101828",
  },
  mobileAmount: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  modalSaveButton: {
    backgroundColor: "#6938EF",
    border: "1px solid #6938EF",
    borderRadius: "8px",
    color: "#FFFFFF",
    fontWeight: "500",
    fontSize: "16px",
    marginLeft: "8px",
    width: "100px",
  },
  supplierDataCardContainer: {
    marginTop: 8,
    backgroundColor: "#EFF2F6",
    borderRadius: "8px",
    borderColor: "#EFF2F6",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "12px",
  },
  supplierDataCard: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  dropdownContainer: {
    backgroundColor: "transparent",
    borderRadius: "8px",
    height: "40px",
    color: "#EFEFEF",
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "20px",
    borderColor: "transparent",
    outline: "0",
    boxShadow: "0 0 0 0.25rem rgb(13 110 253 / 0%)",
    padding: "8px 16px 8px 12px",
  },
  dropdownButton: {
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    width: "-webkit-fill-available",
    margin: "revert",
    border: "1px solid #F4F4F5",
    borderRadius: "8px",
    marginBottom: "24px",
  },
  cardTitle: {
    fontSize: "16px",
    fontWeight: "600",
    color: "#26272B",
  },
  cardRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "12px",
    padding: "12px",
  },
  cardRowSpaceBetween: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "12px",
    justifyContent: "space-between",
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: "24px",
  },
  cardText: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#667085",
  },
  cardData: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#344054",
  },
  cardDataLink: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#6938EF",
    cursor: "pointer",
  },
  sectionRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "12px",
  },
  collectionTotalsCardText: {
    color: "#1D2939",
    fontWeight: "600",
    fontSize: "14px",
  },
  collectionTotalCardTitle: {
    color: "#1D2939",
    fontWeight: "600",
    fontSize: "18px",
  },
  collectionTotalCardText: {
    color: "#1D2939",
    fontWeight: "600",
    fontSize: "18px",
  },
  switchText: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#344054",
  },
  receiverDataCard: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    backgroundColor: "#FAFAFF",
    borderRadius: "8px",
    paddingLeft: "8px",
    paddingRight: "8px",
    paddingTop: "8px",
    marginBottom: "8px",
  },
  receiverCardTitle: {
    color: "#667085",
    fontWeight: "400",
    fontSize: "14px",
  },
  emptyReceiverDataCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    backgroundColor: "#FEF0C7",
    borderRadius: "8px",
    paddingTop: "12px",
    paddingBottom: "12px",
    paddingLeft: "16px",
    paddingRight: "16px",
    height: "124px",
    marginBottom: "8px",
  },
  inputError: {
    fontSize: "14px",
    color: "#FF3252",
    fontWeight: "500",
  },
  revertRow: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    margin: "revert",
  },
  customRowDate: {
    color: "#4A5578",
    fontSize: "14px",
    fontWeight: "400",
    justifyContent: "flex-start",
    margin: "0px",
  },
  customRowTime: {
    color: "#98A2B3",
    fontSize: "12px",
    fontWeight: "400",
    justifyContent: "flex-start",
    margin: "0px",
  },
  cardHeaderContainer: {
    display: "flex",
    padding: "12px",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cardHeaderTitle: {
    display: "flex",
    alignItems: "center",
    gap: "12px",
  },
};
