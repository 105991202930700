import React from "react";
import { styles } from "../styles";
import { CloseIcon, StarIcon } from "assets";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const CausationBanner = ({
  saveInLocalStorage = () => {},
  setCausationBannerVisible = () => {},
  type,
  customStyle = {},
}) => {
  const navigate = useNavigate();

  const title = {
    siigo: (
      <Typography sx={styles.causationBannerTitle}>
        ¡Causa tus facturas automáticamente en Siigo!
      </Typography>
    ),
    dian: (
      <Typography sx={styles.causationBannerTitle}>
        ¿Tienes Siigo como sistema contable?
      </Typography>
    ),
    emptyState: (
      <Typography sx={styles.causationBannerTitle}>
        Causación en Siigo Nube
      </Typography>
    ),
  };

  const description = {
    siigo: <></>,
    dian: (
      <Typography sx={styles.causationBannerDescription}>
        Ahora puedes causar tus facturas desde nuestra plataforma.
      </Typography>
    ),
    emptyState: <></>,
  };

  const iconSize = {
    siigo: "42",
    dian: "42",
    emptyState: "42",
  };

  return (
    <div style={{ ...styles.causationBannerContainer, ...customStyle }}>
      <div style={{ ...styles.row, gap: "12px" }}>
        <div
          style={{
            width: "24px",
            height: "24px",
            marginRight: "8px",
            marginTop: "-8px",
          }}
        >
          <StarIcon size={iconSize[type]} />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "4px",
            marginTop: "2px",
          }}
        >
          {title[type]}
          {description[type]}
        </div>
      </div>
      <div style={{ ...styles.row, gap: "8px" }}>
        <div
          style={styles.causationBannerAction}
          onClick={() => {
            navigate("/payments/causation", {
              state: {
                causationComingSoon: true,
                type: type,
              },
              replace: true,
            });
          }}
        >
          <Typography style={styles.causationBannerLink}>
            {type === "emptyState" ? "Empezar ahora" : "Ver más"}
          </Typography>
        </div>
        {type !== "emptyState" && (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              saveInLocalStorage("isCausationBannerVisible", "false");
              setCausationBannerVisible(false);
            }}
          >
            <CloseIcon color={"#667085"} />
          </div>
        )}
      </div>
    </div>
  );
};
