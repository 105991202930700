import { MenuItem, Select, TextField } from "@mui/material";
import { InfoIcon } from "assets";
import { Tooltip, ValidationMessage } from "commons/components";
import React from "react";
import { NumericFormat } from "react-number-format";
import { typePaymentOnChange } from "../../constants";
import { styles } from "./styles";
import { Controller } from "react-hook-form";

export const SelectAdvance = ({
  payment,
  handleOnChangePayment,
  index,
  paymentsAdvances,
  control,
  name = `payments.${index}.advance`,
  required = false,
}) => {
  const getTooltipTitle = (value) => {
    return (
      <snap>
        El valor del anticipo es{" "}
        {
          <NumericFormat
            tabIndex="0"
            value={value}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={"$"}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        }
      </snap>
    );
  };

  const getRenderValueSelect = (selected) => {
    if (selected === null) return "Comprobante";

    const advance = paymentsAdvances.find(
      (advance) => advance.AcDueBalanceID === selected
    );

    return (
      <div style={styles.selectRenderContainer}>
        <Tooltip title={getTooltipTitle(advance?.Value)}>
          <div style={styles.selectRenderIcon}>
            <InfoIcon color="#1D2939" />
          </div>
        </Tooltip>
        {payment.data.isFromSave ? payment?.data?.DueName : advance?.DueName}
      </div>
    );
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={payment?.data?.AcDueBalanceID || null}
      rules={{
        required: required ? "Este campo no puede quedar incompleto." : false,
        validate: () => {
          if (!payment?.data?.AcDueBalanceID) {
            return "Selecciona un comprobante.";
          }

          if (payment.amount > payment.data?.Value) {
            return "El monto del anticipo no puede ser mayor al saldo del mismo.";
          }
          return true;
        },
      }}
      render={({ field: { onChange, value, ref }, fieldState: { error } }) => {
        return (
          <>
            <Select
              name="advance"
              sx={{
                ...styles.selectAdvance,
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: error ? "#F04438" : "#D0D5DD",
                },
              }}
              onChange={(e) => {
                onChange(e.target.value);
                handleOnChangePayment(
                  index,
                  e.target.value,
                  typePaymentOnChange.advance
                );
              }}
              value={payment?.data?.AcDueBalanceID || null}
              placeholder="Comprobante"
              renderValue={(selected) => getRenderValueSelect(selected)}
            >
              <MenuItem value={null} style={{ height: "36px" }}>
                {" "}
              </MenuItem>
              {paymentsAdvances.map((advance) => (
                <MenuItem
                  key={advance.AcDueBalanceID}
                  value={advance.AcDueBalanceID}
                  style={{
                    display: advance?.isUsed ? "none" : "flex",
                    gap: "10px",
                  }}
                >
                  {`${advance.DueName} -  `}{" "}
                  <NumericFormat
                    tabIndex="0"
                    value={advance.Value}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"$"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                </MenuItem>
              ))}
            </Select>
            {error && (
              <span style={{ color: "rgba(240, 68, 56, 1)", fontSize: 12 }}>
                {error?.message}
              </span>
            )}
          </>
        );
      }}
    />
  );
};
