import React, { useContext } from "react";
import FetchingPDF from "../../ExpandInvoiceModal/components/FetchingPDF";
import { CausationContext } from "contexts";
import { Box, Button, Typography } from "@mui/material";
import { PrimaryButton } from "commons/buttons";

export function PdfViewContainer() {
  const { invoice, checked, filePathIsFromDian } = useContext(CausationContext);

  if (!checked && invoice) return;

  return (
    <div
      style={{
        minHeight: "458px",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {filePathIsFromDian ? (
        <FetchingPDF />
      ) : (
        <Box sx={{ width: "100%", height: "100%" }}>
          {invoice?.file_path ? (
            <iframe
              src={`${invoice?.file_path}#toolbar=0#view=fitH`}
              height="100%"
              width="100%"
              title="invoice_file"
            />
          ) : (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#323639",
                padding: "24px",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: "8px",
                  padding: "16px 24px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "16px",
                  boxShadow:
                    "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",
                }}
              >
                <Typography
                  sx={{
                    color: "#101828",
                    fontSize: "14px",
                    fontWeight: 400,
                  }}
                >
                  Se ha producido un error al cargar el documento PDF.
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      )}
    </div>
  );
}
