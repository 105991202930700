import React from "react";
import { Box, LinearProgress, Skeleton } from "@mui/material";

function FetchingPDF() {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <Box
        sx={{
          width: "30%",
          position: "absolute",
          top: "25%",
          textAlign: "center",
        }}
      >
        <span
          style={{
            fontSize: "14px",
            fontWeight: "400",
            color: "#667085",
          }}
        >
          Descargando comprobante
        </span>
        <LinearProgress sx={{ height: 4, marginTop: 1 }} />
      </Box>
      <Skeleton variant="rectangular" width="100%" height="100%" />
    </Box>
  );
}

export default FetchingPDF;
