const cardInformationListText = {
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 400,
  margin: "0px",
  color: "#344054",
};

export const SUBSCRIPTION_TYPE = {
  TREASURY: "treasury",
  CAUSATION: "causation",
  RECONCILIATION: "reconciliation",
};

export const SUBSCRIPTION_STATUS = {
  INACTIVE: "inactive",
  TRIAL_EXPIRED: "trial_expired",
  CANCELLED: "cancelled",
};
export const FEATURE_TIME = {
  MONTHLY: 1,
  ANNUAL: 12,
};

export const LIST_CARD_INFORMATION = {
  [SUBSCRIPTION_TYPE.TREASURY]: [
    {
      text: (
        <p style={cardInformationListText}>
          Acceso a solución de proveedores, nómina y recaudo
        </p>
      ),
    },
    {
      text: (
        <p style={cardInformationListText}>
          Pagos vía PSE sin comisión bancaria ni ciclos ACH
        </p>
      ),
    },
    {
      text: (
        <p style={cardInformationListText}>
          Notificación de soportes de pago vía Whatsapp
        </p>
      ),
    },
    {
      text: (
        <p style={cardInformationListText}>
          Control, visibilidad y reportes de tus cuentas a pagar y cobrar
        </p>
      ),
    },
    {
      text: <p style={cardInformationListText}>Inscripción de proveedores</p>,
    },
    {
      text: (
        <p style={cardInformationListText}>
          Descargables y buscadores de documentos
        </p>
      ),
    },
    {
      text: (
        <p style={cardInformationListText}>
          Registro de egresos en tu sistema contable
        </p>
      ),
    },
  ],
  [SUBSCRIPTION_TYPE.CAUSATION]: [
    {
      text: (
        <p style={cardInformationListText}>
          Importa <strong>automáticamente</strong> tus documentos desde la DIAN.
        </p>
      ),
    },
    {
      text: (
        <p style={cardInformationListText}>
          Causa tus facturas y documentos soporte en Siigo{" "}
          <strong>un 80% más rápido.</strong>
        </p>
      ),
    },
    {
      text: (
        <p style={cardInformationListText}>
          Usuarios <strong>ilimitados</strong> para todas tus empresas.
        </p>
      ),
    },
  ],
  [SUBSCRIPTION_TYPE.RECONCILIATION]: [
    {
      text: <p style={cardInformationListText}>Integración Siigo</p>,
    },
    {
      text: (
        <p style={cardInformationListText}>
          Importación de tus movimientos bancarios
        </p>
      ),
    },
    {
      text: (
        <p style={cardInformationListText}>
          Conciliación de múltiples cuentas bancarias en simultáneo
        </p>
      ),
    },
    {
      text: <p style={cardInformationListText}>Reporte de conciliación</p>,
    },
    {
      text: <p style={cardInformationListText}>Creación de anticipos</p>,
    },
    {
      text: (
        <p style={cardInformationListText}>
          Creación registro de pagos y/o comprobantes contables
        </p>
      ),
    },
  ],
};

export const TITLE_FEATURE = {
  [FEATURE_TIME.MONTHLY]: "Plan mensual",
  [FEATURE_TIME.ANNUAL]: "Plan anual",
};

export const PRICE_FEATURE = {
  [SUBSCRIPTION_TYPE.TREASURY]: {
    [FEATURE_TIME.MONTHLY]: "150.000 COP",
    [FEATURE_TIME.ANNUAL]: "1.530.000 COP (127.500 COP/mes)",
  },
  [SUBSCRIPTION_TYPE.CAUSATION]: {
    [FEATURE_TIME.MONTHLY]: "100.000 COP",
    [FEATURE_TIME.ANNUAL]: "1.020.000 COP (85.000 COP/mes)",
  },
};

export const TITLE = {
  [SUBSCRIPTION_TYPE.TREASURY]: "¡Suscríbete a la solución de Tesorería PRO!",
  [SUBSCRIPTION_TYPE.CAUSATION]: "¡Suscríbete a la solución de Causación!",
};

export const TRIAL_TITLE = {
  [SUBSCRIPTION_TYPE.TREASURY]: "Prueba la solución de Tesorería",
  [SUBSCRIPTION_TYPE.CAUSATION]: "Prueba la solución de Causación",
  [SUBSCRIPTION_TYPE.RECONCILIATION]: "Prueba la solución de Conciliación",
};

export const DISCOUNT = {
  [FEATURE_TIME.MONTHLY]: "",
  [FEATURE_TIME.ANNUAL]: "Mejor oferta 15% off",
};

export const AFTER_TRIAL_SUBSCRIPTION_PRICE = {};

export const MONTHLY_TREASURY = "Tesoreria Mensual";
export const ANNUAL_TREASURY = "Tesoreria Anual";
