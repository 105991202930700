import { Box, MenuItem, Select } from "@mui/material";
import React from "react";
import { Suggestion } from "../Suggestion";

export const DynamicSelect = ({
  options,
  initialValue,
  displayOption,
  register,
  name,
  required,
  onChange,
  value,
  placeholder,
  disabled,
  isSuggested,
  setValue,
  ...rest
}) => {
  const renderSuggestionIcon = () => {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          mr: 2,
          backgroundColor: "transparent",
        }}
      >
        <Suggestion />
      </Box>
    );
  };

  return (
    <Select
      {...rest}
      startAdornment={<>{isSuggested && renderSuggestionIcon()}</>}
      sx={{
        fontSize: "14px",
        backgroundColor: isSuggested ? "#FAFAFF" : "inherit",
        color: isSuggested ? "#6938EF" : "inherit",
        fontWeight: isSuggested ? "500" : "inherit",
        "& .MuiSelect-select": {
          fontSize: "14px",
        },
        ...(isSuggested && {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#BDB4FE",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#BDB4FE",
          },
        }),
      }}
      {...register(name, {
        required: required,
      })}
      onChange={(e) => {
        register(name).onChange(e);
        onChange(e);
      }}
      value={options.find((option) => option.id === value?.id) || ""}
      displayEmpty
      disabled={disabled}
      renderValue={(selected) => {
        if (!selected) return placeholder;
        return displayOption(selected);
      }}
    >
      {initialValue && (
        <MenuItem value={initialValue} style={{ display: "none" }} />
      )}
      {options.map((option) => (
        <MenuItem key={option.id} value={option} sx={{ fontSize: "14px" }}>
          {displayOption(option)}
        </MenuItem>
      ))}
    </Select>
  );
};
