import { DynamicForm, ExpandCausationHeader } from "./components";
import * as amplitude from "@amplitude/analytics-browser";
import React, { useContext, useEffect, useRef } from "react";
import { Dialog } from "@mui/material";
import "intro.js/introjs.css";
import { CausationResponseModal } from "../CausationResponseModal";

import { MODAL_RESPONSE_INITIAL_STATE } from "./constants";
import { SubscriptionModal, TableSkeleton } from "../..";
import { CausationContext, UserContext } from "contexts";
import { configSiigoContador } from "./siigoContador";
import { configSiigoNube } from "./siigoNube";
import { configSiigoPyme } from "./siigoPyme";
import { configWorldOffice } from "./worldOffice";

export function ExpandCausationDetails() {
  const {
    id,
    isLoading,
    subscription,
    modalResponse,
    setModalResponse,
    erpName,
    startTime,
  } = useContext(CausationContext);
  const { currentUser } = useContext(UserContext);

  const formRef = useRef(null);

  useEffect(() => {
    amplitude.track("CAUSATION_LOADED", {
      user_id: currentUser.id,
      company_id: currentUser.company_id,
      invoice_id: parseInt(id),
    });
    startTime.current = Date.now();
  }, [id]);

  const getConfigByErp = {
    siigoNube: configSiigoNube,
    siigoContador: configSiigoContador,
    siigoPyme: configSiigoPyme,
    worldOffice: configWorldOffice,
  };

  return (
    <>
      {subscription?.showModalSubscription && (
        <SubscriptionModal subscription={subscription} />
      )}
      <CausationResponseModal
        open={modalResponse.open}
        type={modalResponse.type}
        text={modalResponse.textError}
        statusCode={modalResponse.statusCode}
        onClose={() => {
          setModalResponse(MODAL_RESPONSE_INITIAL_STATE);
        }}
        id={id}
        supportDocument={modalResponse?.supportDocument}
        checkSupportDocument={modalResponse?.checkSupportDocument}
      />
      <Dialog
        fullScreen
        open={true}
        style={{
          zIndex: 1000,
        }}
        disableEnforceFocus
      >
        {isLoading ? (
          <TableSkeleton />
        ) : (
          <>
            <ExpandCausationHeader formRef={formRef} startTime={startTime} />
            <DynamicForm
              ref={formRef}
              config={getConfigByErp[erpName] || configSiigoNube}
            />
          </>
        )}
      </Dialog>
    </>
  );
}
