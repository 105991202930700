import React from "react";
import { DataGrid, GridCellModes } from "@mui/x-data-grid";
import { AddNewItem } from "./AddNewItem";
import { MultipleSelect } from "../MultipleSelected";
import { Stack } from "@mui/material";
import { ItemTotalizer } from "../ItemTotalizer";

export function DynamicTable({
  columns,
  data,
  hideFooter,
  sx,
  style,
  addNewTableInfo,
  getSearchOptionsProducts,
  handleChangeMultipleSelect,
  hasCompanyWareHouse,
  search,
  multipleSelectColumns,
  isCaused,
  handleSetItems,
  isCreditNote,
}) {
  const [cellModesModel, setCellModesModel] = React.useState({});

  const labelDisplayedRows = ({ from, to, count, estimated }) => {
    if (!estimated) {
      return `${from}–${to} de ${count !== -1 ? count : `${to}`}`;
    }
    return `${from}–${to} de ${
      count !== -1 ? count : `${estimated > to ? estimated : to}`
    }`;
  };

  const handleCellClick = React.useCallback((params, event) => {
    if (!params.isEditable) {
      return;
    }

    if (
      event.target.nodeType === 1 &&
      !event.currentTarget.contains(event.target)
    ) {
      return;
    }

    setCellModesModel((prevModel) => {
      return {
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View },
              }),
              {}
            ),
          }),
          {}
        ),
        [params.id]: {
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
            {}
          ),
          [params.field]: { mode: GridCellModes.Edit },
        },
      };
    });
  }, []);

  const handleCellModesModelChange = React.useCallback((newModel) => {
    setCellModesModel(newModel);
  }, []);

  const numberItemSelected = data?.filter((item) => item.isSelected)?.length;

  const {
    product: products,
    taxIva: taxesIva,
    taxRetefuente: taxesRete,
  } = search;

  // Modificamos las columnas para cambiar la última columna
  const processedColumns = React.useCallback(() => {
    const cols = columns();
    if (cols && cols.length > 0) {
      // Modificar la última columna visible con una clase según isCaused
      const lastColumnIndex = cols.length - 1;
      if (lastColumnIndex >= 0) {
        cols[lastColumnIndex] = {
          ...cols[lastColumnIndex],
          cellClassName: isCaused ? "caused-last-column" : "no-bottom-border",
        };
      }
    }
    return cols;
  }, [columns, isCaused]);

  return (
    <>
      <div style={{ width: "97%", margin: "0px auto" }}>
        <ItemTotalizer
          handleSetItems={handleSetItems}
          data={data}
          isCaused={isCaused}
        />
        {!isCaused && (
          <MultipleSelect
            numberItemSelected={numberItemSelected}
            products={products}
            taxesIva={taxesIva}
            taxesRete={taxesRete}
            getSearchOptionsProducts={getSearchOptionsProducts}
            handleChangeMultipleSelect={handleChangeMultipleSelect}
            hasCompanyWareHouse={hasCompanyWareHouse}
            multipleSelectColumns={multipleSelectColumns}
          />
        )}
        <DataGrid
          autoHeight
          columns={processedColumns()}
          rows={data}
          hideFooter={hideFooter}
          disableSelectionOnClick
          disableColumnMenu
          disableColumnReorder
          getRowId={(row) => row.id}
          disableColumnSort
          getRowHeight={() => "auto"}
          sx={{
            ...sx,
            "& .MuiDataGrid-row": {
              minHeight: "30px !important",
            },
            ...(!isCaused && {
              "& .no-bottom-border": {
                borderBottom: "none !important",
              },
            }),
            ...(isCaused && {
              "& .caused-last-column": {
                borderTop: "1px solid #EAECF0 !important",
                borderRight: "1px solid #EAECF0 !important",
              },
            }),
          }}
          style={style}
          cellModesModel={cellModesModel}
          onCellClick={handleCellClick}
          onCellModesModelChange={handleCellModesModelChange}
          slots={{
            noRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                No hay ítems
              </Stack>
            ),
          }}
          initialState={{
            ...data.initialState,
            pagination: { paginationModel: { pageSize: 25 } },
          }}
          localeText={{
            MuiTablePagination: {
              labelRowsPerPage: "Mostrar",
              labelDisplayedRows,
            },
            noRowsLabel: "No hay ítems",
          }}
          pageSizeOptions={[25, 50, 100]}
          hideFooterSelectedRowCount={true}
        />
        {!isCaused && !isCreditNote && (
          <AddNewItem addNewTableInfo={addNewTableInfo} />
        )}
      </div>
    </>
  );
}
