import React, { useContext, useState } from "react";
import * as amplitude from "@amplitude/analytics-browser";
import { styles } from "../styles";
import { Box, Stack } from "@mui/material";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  SubscriptionButton,
  SecondaryButton,
  ThirdButton,
} from "../../../buttons";
import { causationStates, erpNames } from "../../../../constants";
import { CausationContext, UserContext } from "contexts";
import { updateInvoices } from "services";
import { XIcon } from "assets";

export function ExpandCausationHeader({ startTime, formRef }) {
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState(null);
  const {
    handleSaveCausation,
    stateCausation = causationStates.draft,
    id,
    requestInProcess,
    subscription,
    invoice,
    erpName,
  } = useContext(CausationContext);
  const { causationScreen, currentUser } = useContext(UserContext);

  const setSyncBanner = causationScreen?.syncBanner?.setter;

  const hideSyncBanner = () => {
    setSyncBanner((syncBanner) => {
      return {
        ...syncBanner,
        visible: false,
      };
    });
  };

  const handleCausar = async () => {
    setActiveButton("causar");
    hideSyncBanner();
    await formRef.current?.submit();
    amplitude.track("CAUSATION_CREATED", {
      timeSpent: Date.now() - startTime?.current,
      user_id: currentUser.id,
    });
    setActiveButton(null);
  };

  const handleMarkAsCaused = async () => {
    setActiveButton("mark");
    updateInvoices([id]);
    hideSyncBanner();
    await handleSaveCausation(true);
    setActiveButton(null);
  };

  const handleSave = async () => {
    setActiveButton("save");
    hideSyncBanner();
    await handleSaveCausation();
    setActiveButton(null);
  };

  return (
    <Box sx={styles.modalHeader}>
      <div style={styles.headerRow}>
        <div
          onClick={() => {
            hideSyncBanner();
            navigate("/payments/invoices", { replace: true });
          }}
          style={{ cursor: "pointer" }}
        >
          <XIcon width={16} height={16} strokeWidth={1} />
        </div>
        <div
          className="modalTitle"
          style={{ marginLeft: "16px", fontSize: "16px", marginTop: "3px" }}
        >
          Detalle del documento
        </div>
      </div>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        {!id || (id && invoice?.causation_state !== causationStates.caused) ? (
          <Stack gap={"8px"} flexDirection={"row"}>
            {id && stateCausation === causationStates.draft && (
              <ThirdButton
                text={
                  requestInProcess && activeButton === "mark" ? (
                    <Box
                      sx={{
                        minWidth: "120px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Spinner animation="border" size="sm" />
                    </Box>
                  ) : (
                    "Marcar como causada"
                  )
                }
                action={handleMarkAsCaused}
                width="max-content"
                isDisable={requestInProcess}
              />
            )}
            <SecondaryButton
              text={
                requestInProcess && activeButton === "save" ? (
                  <Box
                    sx={{
                      minWidth: "64px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Spinner animation="border" size="sm" />
                  </Box>
                ) : (
                  "Guardar"
                )
              }
              action={handleSave}
              width="fit-content"
              isDisable={requestInProcess}
            />
            <SubscriptionButton
              subscription={subscription}
              isDisable={requestInProcess}
              text={
                requestInProcess && activeButton === "causar" ? (
                  <Box
                    sx={{
                      minWidth: "64px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Spinner animation="border" size="sm" />
                  </Box>
                ) : (
                  "Causar"
                )
              }
              action={handleCausar}
              width="fit-content"
              buttonType="primary"
            />
          </Stack>
        ) : null}
      </Box>
    </Box>
  );
}
