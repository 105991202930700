export const styles = {
  title: {
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "24px",
    color: "#101828",
  },
  subtitle: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "24px",
    color: "#667085",
    marginLeft: "2.5%",
  },
  firstStatusOptions: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#667085",
    marginTop: "24px",
    textTransform: "unset !important",
    paddingBottom: "12px",
  },
  statusOptions: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#667085",
    marginTop: "24px",
    textTransform: "unset !important",
    paddingBottom: "12px",
  },
  firstStatusOptionsSelected: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#5925DC",
    marginTop: "24px",
    borderBottom: "2px solid #7f56d9",
    borderRadius: "0px",
    textTransform: "unset !important",
    paddingBottom: "12px",
  },
  statusOptionsSelected: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#5925DC",
    marginTop: "24px",
    borderBottom: "2px solid #7f56d9",
    borderRadius: "0px",
    textTransform: "unset !important",
    paddingBottom: "12px",
  },
  expirationContainer: {
    marginTop: "12px",
    height: "80px",
    display: "flex",
    flexDirection: "row",
    marginLeft: "2.5%",
    marginRight: "2.5%",
  },
  expirationFirstItem: {
    height: "80px",
    display: "flex",
    flexDirection: "column",
    width: "-webkit-fill-available",
    borderTopLeftRadius: "8px",
    borderBottomLeftRadius: "8px",
    border: "1px solid #EAECF0",
  },
  expirationSecondItem: {
    height: "80px",
    display: "flex",
    flexDirection: "column",
    width: "-webkit-fill-available",
    borderTop: "1px solid #EAECF0",
    borderBottom: "1px solid #EAECF0",
    borderRight: "1px solid #EAECF0",
    backgroundColor: "#FCFCFD",
  },
  expirationThirdItem: {
    height: "80px",
    display: "flex",
    flexDirection: "column",
    width: "-webkit-fill-available",
    borderTop: "1px solid #EAECF0",
    borderBottom: "1px solid #EAECF0",
    backgroundColor: "#FCFCFD",
  },
  expirationLastItem: {
    height: "80px",
    display: "flex",
    flexDirection: "column",
    width: "-webkit-fill-available",
    borderTopRightRadius: "8px",
    borderBottomRightRadius: "8px",
    border: "1px solid #EAECF0",
    backgroundColor: "#FCFCFD",
  },
  expirationItemTitle: {
    color: "#667085",
    fontWeight: "500",
    fontSize: "14px",
    paddingTop: "12px",
    paddingLeft: "12px",
    lineHeight: "20px",
  },
  expirationItemAmount: {
    color: "#101828",
    fontWeight: "600",
    fontSize: "18px",
    paddingLeft: "12px",
    lineHeight: "12px",
  },
  expirationExpiredTitle: {
    color: "#B42318",
    fontWeight: "500",
    fontSize: "14px",
    paddingTop: "12px",
    paddingLeft: "12px",
    lineHeight: "20px",
  },
  footer: {
    backgroundColor: "#FFFFFF",
    borderTop: "1px solid #EAECF0",
    position: "fixed",
    width: "100%",
    bottom: "0",
    color: "white",
    fontSize: "25px",
    boxShadow: "0px -6px 16px -4px rgba(16, 24, 40, 0.08)",
    height: "64px",
    marginLeft: "auto",
    marginRight: "auto",
    justifyContent: "flex-end",
    display: "flex",
    alignItems: "center",
  },
  footerElements: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "95%",
    justifyContent: "flex-end",
    display: "flex",
    alignItems: "center",
  },
  footerPrimaryButton: {
    backgroundColor: "#6938EF",
    borderRadius: "8px",
    height: "40px",
    color: "#FFFFFF",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "20px",
  },
  footerSecondaryButton: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #D0D5DD",
    borderRadius: "8px",
    height: "40px",
    color: "#344054",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "20px",
    marginRight: "12px",
  },
  footerText: {
    color: "#000000",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "24px",
    marginRight: "12px",
    display: "flex",
    alignSelf: "center",
    marginTop: "auto",
    marginBottom: "auto",
  },
  titleContainer: {
    justifyContent: "space-between",
    display: "flex",
    marginLeft: "2.5%",
    marginRight: "2.5%",
    marginTop: "16px",
    alignItems: "center",
    height: "40px",
  },
  titleButton: {
    backgroundColor: "#6938EF",
    borderRadius: "8px",
    height: "100%",
    color: "#FFFFFF",
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "20px",
    borderColor: "transparent",
    outline: "0",
    boxShadow: "0 0 0 0.25rem rgb(13 110 253 / 0%)",
    padding: "8px 16px 8px 12px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  rowSpaceBetween: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  separator: {
    height: "1px",
    width: "100%",
    backgroundColor: "#E9ECF0",
    margin: "revert",
    opacity: "1",
    border: "0",
    marginTop: "-2px",
  },
  dropdownItemContainer: {
    display: "flex",
    flexDirection: "row",
    height: "40px",
    alignItems: "center",
  },
  dropdownItemIconContainer: {
    width: "32px",
    height: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "4px",
    background: "#FFF",
    boxShadow: "0px 2px 8px 0px rgba(3, 7, 18, 0.10)",
    margin: "0px",
  },
  dropdownLongItemContainer: {
    display: "flex",
    flexDirection: "row",
    height: "50px",
  },
  dropdownItemTextContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginLeft: "16px",
    textAlign: "initial",
    marginTop: "16px",
  },
  dropdownItemTitle: {
    color: "#1D2939",
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "8px",
  },
  dropdownItemText: {
    color: "#667085",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "8px",
  },
  BOContainer: {
    width: "60%",
    marginLeft: "2.5%",
  },
  container: {
    width: "100%",
  },
  emptyStateTitle: {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "20px",
    textAlign: "center",
  },
  emptyStateSubtitle: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "center",
    color: "#475467",
  },
  emptyStateButtonTitle: {
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "20px",
  },
  comingSoonText: {
    color: "#054F31",
    fontSize: "9px",
    fontWeight: "600",
    lineHeight: "20px",
    textAlign: "flex-end",
    right: "12px",
    position: "absolute",
  },
  emptyStateButtonSubtitle: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
  },
  emptyStateBox: {
    minHeight: "400px",
    display: "flex",
    justifyContent: "center",
  },
  emptyStateStack: {
    maxWidth: "500px",
    alignItems: "center",
    width: "90%",
    padding: "32px 0px",
  },
  emptyStateBtnInGroup: {
    padding: "14px 16px",
    border: "1px solid #EAECF0",
    background: "#FFF",
    textTransform: "inherit",
    textAlign: "inherit",
    justifyContent: "flex-start",
    color: "#344054",
    WebkitTransition: "none",
    transition: "none",
    "&:hover": {
      border: "1px solid #EAECF0",
    },
    "&:disabled": {
      borderColor: "#5925dc80",
    },
    "& .MuiButton-startIcon": {
      width: "32px",
      height: "32px",
      borderRadius: "4px",
      background: "#FFF",
      boxShadow: "0px 2px 8px 0px rgba(3, 7, 18, 0.10)",
      margin: "0px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  disabledEmptyStateBtnInGroup: {
    padding: "14px 16px",
    border: "1px solid #EAECF0",
    background: "#FFF",
    textTransform: "inherit",
    textAlign: "inherit",
    justifyContent: "flex-start",
    color: "#344054",
    WebkitTransition: "none",
    transition: "none",
    "&:hover": {
      border: "1px solid #EAECF0",
    },
    "&:disabled": {
      borderColor: "#EAECF0",
    },
    "& .MuiButton-startIcon": {
      width: "32px",
      height: "32px",
      borderRadius: "4px",
      background: "#FFF",
      boxShadow: "0px 2px 8px 0px rgba(3, 7, 18, 0.10)",
      margin: "0px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  syncContainer: {
    color: "#667085",
    fontSize: "14px",
  },
  syncLoading: {
    borderRadius: "8px",
    border: "1px solid #D0D5DD",
    width: "100px",
    height: "35px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  actionsDropdownItemText: {
    color: "#344054",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "20px",
  },
  actionsDropdownItemDeleteText: {
    color: "#B42318",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "20px",
  },
  actionsDropdownPadding: {
    padding: "2px 16px 2px 16px",
  },
  newDropdownPadding: {
    padding: "10px 16px 10px 16px",
  },
  noPadding: {
    padding: "0px",
  },
  tagButton: {
    backgroundColor: "#FFFFFF",
    borderRadius: "8px",
    height: "40px",
    color: "#344054",
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "20px",
    border: "1px solid #D0D5DD",
    outline: "0",
    boxShadow: "0 0 0 0.25rem rgb(13 110 253 / 0%)",
    padding: "8px 16px 8px 12px",
    justifyContent: "space-between",
    textTransform: "capitalize",
  },
  tagButtonTextContainer: {
    fontSize: "14px",
    display: "flex",
    alignItems: "baseline",
    fontWeight: "500",
  },
  tagQuantity: {
    marginLeft: 6,
    color: "#5925DC",
    backgroundColor: "#EBE9FE",
    borderRadius: "6px",
    padding: "2px 9px 2px 9px",
    alignSelf: "center",
  },
  infoPaymentContainer: {
    background: "#FCFCFD",
    padding: "16px 15px",
    borderRadius: "8px 8px 0px 0px",
    borderBottom: "1px solid #EAECF0",
    boxShadow: "0px 2px 8px 0px rgba(3, 7, 18, 0.10)",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  infoPaymentText: {
    alignSelf: "stretch",
    color: "#344054",
    fontSize: "14px",
    fontStyle: "normal",
    lineHeight: "20px",
    letterSpacing: "-0.14px",
  },
  infoPaymentBankContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "30px",
  },
  infoPaymentBank: {
    borderRadius: "30px",
    border: "1px solid #EAECF0",
    background: "#FFF",
    boxhadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
    height: "36px",
    width: "210px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "4px",
    fontWeight: "bold",
    fontSize: "14px",
  },
  infoPaymentFooter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "11px",
    padding: "15px 16px",
    background: "#F4F3FF",
    borderRadius: "0px 0px 8px 8px",
    boxShadow: "0px 2px 8px 0px rgba(3, 7, 18, 0.10)",
  },
  emptyInfoText: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "flex-start",
    color: "#1D2939",
  },
  emptyRow: {
    display: "flex",
    flexDirection: "row",
    gap: "12px",
    justifyContent: "flex-start",
  },
  emptyStateInfoContainer: {
    background: "#F4F3FF",
    padding: "16px 15px",
    borderRadius: "8px",
    borderBottom: "1px solid #EAECF0",
    boxShadow: "0px 2px 8px 0px rgba(3, 7, 18, 0.10)",
    display: "flex",
    flexDirection: "row",
    gap: "8px",
    width: "-webkit-fill-available",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  emptyStateInfo: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "left",
    color: "#344054",
  },
  emptyStateInfoLink: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "left",
    color: "#5925DC",
    textDecoration: "underline",
    cursor: "pointer",
  },
  communicationContainer: {
    background: "#FFFCF5",
    padding: "16px 15px",
    borderRadius: "8px",
    border: "1px solid #FEC84B",
    display: "flex",
    flexDirection: "row",
    gap: "8px",
    width: "-webkit-fill-available",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginTop: "24px",
    marginLeft: "2.5%",
    marginRight: "2.5%",
  },
  communicationTitle: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "left",
    color: "#B54708",
    marginLeft: "-6px",
  },
  communicationInfo: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "left",
    color: "#DC6803",
    marginLeft: "-6px",
  },
  labelButton: {
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "16px",
    color: "#5925dc",
    "&:hover": {
      cursor: "pointer",
    },
  },
};
