import { ArrowRightWithoutStick } from "assets";
import React from "react";
import { getWppLink, openInNewTab } from "utils";
import { styles } from "./styles";
import { SUBSCRIPTION_TYPE } from "constants";

export const ContactHelp = ({ type, isTrial = false }) => {
  const getHelpDetails = (type) => {
    if (type === SUBSCRIPTION_TYPE.CAUSATION) {
      return {
        phoneNumber: "+573002123402",
        message:
          "Hola! Quiero saber más acerca de los planes especiales para Contadores.",
      };
    }
    return {
      phoneNumber: "+573103619890",
      message: "Hola! Tengo dudas con mi suscripción.",
    };
  };

  const { phoneNumber, message } = getHelpDetails(type);

  return (
    <div style={styles.contactHelpContainer}>
      <p style={styles.contactHelpText}>
        <span style={{ fontWeight: 500 }}>¿Tienes dudas? </span>
        Contacta a ventas.
      </p>
      <p
        style={styles.contactHelpButton}
        onClick={() => openInNewTab(getWppLink(phoneNumber, message))}
      >
        Contactar{" "}
        <ArrowRightWithoutStick stroke={"#5925DC"} width="20px" height="20px" />
      </p>
    </div>
  );
};
