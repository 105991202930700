import { BalanceIcon } from "assets/svg/BalanceIcon";
import { styles } from "../../../Table/styles";
import { NumericFormat } from "react-number-format";
import { format, parseISO } from "date-fns";
import { Divider } from "@mui/material";
import SyncPendingAmount from "utils/SyncPendingAmount";
import { Show } from "commons/components";

const CurrentAccount = ({ invoice, onSuccess }) => {
  const { can_sync_pending_amount } = invoice;
  return (
    <div style={styles.container}>
      <div style={styles.cardHeaderContainer}>
        <div style={styles.cardHeaderTitle}>
          <BalanceIcon />
          <span style={styles.cardTitle}>Estado de cuenta Siigo</span>
        </div>
        <Show condition={can_sync_pending_amount}>
          <SyncPendingAmount invoiceId={invoice.id} onSuccess={onSuccess} />
        </Show>
      </div>
      <Divider />
      <div style={styles.cardRowSpaceBetween}>
        <span style={styles.cardText}>Total factura</span>
        <NumericFormat
          value={parseInt(invoice.original_total_amount)}
          displayType="text"
          thousandSeparator="."
          decimalSeparator=","
          prefix="$"
          decimalScale={2}
        />
      </div>
      {invoice.current_account.map((row) => {
        return (
          <div key={row.name} style={styles.cardRowSpaceBetween}>
            <span style={styles.cardText}>
              {row.name} ({format(parseISO(row.date), "dd/MM/yyyy")})
            </span>
            <span>
              <span>- </span>
              <NumericFormat
                value={parseInt(row.debit)}
                displayType="text"
                thousandSeparator="."
                decimalSeparator=","
                prefix="$"
                decimalScale={2}
              />
            </span>
          </div>
        );
      })}
      <div style={styles.cardRowSpaceBetween}>
        <span style={styles.cardText}>
          <strong>Monto pendiente</strong>
        </span>
        <strong>
          <NumericFormat
            value={parseInt(invoice.amount_total)}
            displayType="text"
            thousandSeparator="."
            decimalSeparator=","
            prefix="$"
            decimalScale={2}
          />
        </strong>
      </div>
    </div>
  );
};

export default CurrentAccount;
