export const styles = {
  title: {
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "28px",
    color: "#344054",
    marginBottom: "8px",
  },
  subtitleProviderAdvance: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    color: "#667085",
    marginBottom: "16px",
  },
  textAddNewItem: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "20px",
    color: "#5925DC",
    cursor: "pointer",
    marginTop: "12px",
    display: "flex",
    gap: "8px",
  },
  warningText: {
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "20px",
    color: "#F04438",
    marginTop: "8px",
  },
  selectAdvance: {
    backgroundColor: "#FFF",
    width: "100%",
    borderRadius: "8px",
    border: "1px",
    opacity: "0px",
    "& .MuiInputBase-input.MuiOutlinedInput-input": {
      padding: "8px 14px",
      fontSize: "14px",
    },
  },
  selectRenderContainer: {
    display: "flex",
    justifyContent: "space-around",
  },
  selectRenderIcon: {
    display: "flex",
    alignItems: "center",
  },
  inputAmount: {
    backgroundColor: "#FFF",
    width: "100%",
    borderRadius: "8px",
    border: "1px",
    opacity: "0px",
    "& .MuiInputBase-input.MuiOutlinedInput-input": {
      padding: "8px 14px",
      fontSize: "14px",
    },
  },
  column: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    minWidth: "140px",
    marginBottom: "4px",
  },
  tooltipTitle: {
    color: "#5522FF",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "24px",
    marginBottom: "6px",
  },
  tooltipSubtitle: {
    color: "#5522FF",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    width: "100%",
    minWidth: "auto",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    gap: "8px",
    width: "100%",
    flexWrap: "wrap",
  },
  deleteButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "40px",
    marginLeft: "auto",
  },
};
