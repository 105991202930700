import { Box } from "@mui/material";
import { CausationContext } from "contexts";
import React, { useContext } from "react";
import Switch from "react-ios-switch";
import { styles } from "../styles";

export const DynamicSwitch = () => {
  const { invoice, checked, setChecked } = useContext(CausationContext);

  return (
    <Box sx={styles.switchContainer}>
      <Switch
        checked={checked}
        className={undefined}
        handleColor="white"
        name={undefined}
        offColor="#D0D5DD"
        onChange={() => {
          setChecked(!checked);
        }}
        onColor="#667085"
        pendingOffColor={undefined}
        pendingOnColor={undefined}
        style={{ transform: "scale(0.6)", fontSize: "14px" }}
      />
      <span style={styles.switchText}>Visualizar archivo</span>
    </Box>
  );
};
