import { AIStarsIcon } from "assets";
import React from "react";

export const Suggestion = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        color: "#6B3ADB",
        fontSize: "12px",
        fontWeight: "bold",
        height: "24px",
        lineHeight: "24px",
      }}
    >
      <AIStarsIcon color="#6B3ADB" size="16" />
    </div>
  );
};
