import { PrimaryButton, SecondaryButton } from "commons/buttons";
import { FormBilling } from "commons/components/FormBilling";
import { stepsBilling } from "constants";
import { toastOptions } from "constants";
import { UserContext } from "contexts";
import React, { useContext, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { toast } from "react-toastify";
import { createBillingCompany } from "services";
import { TrackJS } from "trackjs";

export const Billing = ({ handleChangeStep, createSubscription }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm();

  const formValues = useWatch({ control });
  const [isLoading, setIsLoading] = useState(false);

  const { currentCompany } = useContext(UserContext);

  const onSubmited = handleSubmit(async (data) => {
    try {
      setIsLoading(true);
      const formatBilling = {
        documentNumber: data.document_number,
        documentType: data.document_type,
        phone: data.phone,
        address: data?.address || "",
        email: data.email,
        contactName: data.contact_name,
        contactSurname: data.contact_surname,
        municipality: data.city?.state_code,
        organizationType: data.organization_type,
        city: data.city?.city_code,
        companyId: currentCompany.id,
        businessName: data.business_name,
      };

      const respBilling = await createBillingCompany(formatBilling);
      if (!respBilling) {
        return toast.error(
          "No se pudo actualizar la información de facturación",
          toastOptions
        );
      }

      toast.success("Información de facturación creada", toastOptions);
      await createSubscription();
    } catch (error) {
      TrackJS.console.error(error);
      toast.error(
        "No se pudo actualizar la información de facturación",
        toastOptions
      );
    } finally {
      setIsLoading(false);
    }
  });

  return (
    <div
      style={{
        width: "100%",
        minWidth: "568px",
        maxWidth: "568px",
        margin: "0 auto",
      }}
    >
      <p
        style={{
          marginBottom: "0px",
          color: "#101828",
          fontSize: "18px",
          fontWeight: "600",
          textAlign: "left",
        }}
      >
        Agrega tus datos de facturación
      </p>
      <span
        style={{
          paddingTop: "8px",
          color: "#475467",
          fontSize: "14px",
          fontWeight: 500,
        }}
      >
        Generaremos la factura con los datos que ingreses aquí.
      </span>
      <p
        style={{
          padding: "16px 0px",
          marginBottom: "0px",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 600,
        }}
      >
        Datos fiscales
      </p>
      <FormBilling
        errors={errors}
        formValues={formValues}
        setValue={setValue}
        register={register}
        isEditable={true}
        isCreateBilling={true}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
          gap: "20px",
          marginTop: "24px",
        }}
      >
        <SecondaryButton
          isDisable={isLoading}
          text="Cancelar"
          action={() => handleChangeStep(stepsBilling.initial)}
          width="50%"
        />
        <PrimaryButton
          loading={isLoading}
          text={"Guardar"}
          action={onSubmited}
          width="50%"
        />
      </div>
    </div>
  );
};
