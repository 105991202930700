import { TrackJS } from "trackjs";
import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import { useState } from "react";
import { SyncIcon } from "assets";
import { syncPendingAmount } from "services";
import { toastOptions } from "constants";
import { toast } from "react-toastify";

const SyncPendingAmount = ({ invoiceId, onSuccess = () => {} }) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <Tooltip title="Actualiza el estado de cuenta según Siigo">
      {isLoading ? (
        <IconButton sx={{ width: 35, height: 35 }}>
          <CircularProgress style={{ height: "15px", width: "15px" }} />
        </IconButton>
      ) : (
        <IconButton
          onClick={async () => {
            try {
              setIsLoading(true);
              await syncPendingAmount(invoiceId);
              await onSuccess();
              toast.success("Actualización exitosa.", toastOptions);
            } catch (error) {
              TrackJS.console.error(error);
              toast.error(
                "Siigo se encuentra con intermitencias. Por favor, vuelve a intentar de nuevo en unos minutos.",
                toastOptions
              );
            } finally {
              setIsLoading(false);
            }
          }}
          sx={{ width: 35, height: 35 }}
        >
          <SyncIcon />
        </IconButton>
      )}
    </Tooltip>
  );
};

export default SyncPendingAmount;
