import React, { useContext, useImperativeHandle, forwardRef } from "react";
import { PdfViewContainer } from "./PdfViewContainer";
import { SyncInformation } from "./SyncInformation/SyncInformation";
import "../causation-styles.css";
import { DynamicSelect } from "./Select/DynamicSelect";
import { InputForm } from "commons/components";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { es } from "date-fns/locale";
import { DynamicSwitch } from "./DynamicSwitch";
import { PdfInformation } from "./PdfInformation";
import { DynamicTable } from "./Table/DynamicTable";
import { CausationContext, UserContext } from "contexts";
import { Autocomplete, TextField } from "@mui/material";
import { CalendarIcon } from "assets";
import { Payments } from "./Payments";
import { Observation } from "./Observation";
import { ExpandCausationCountGeneral } from "./ExpandCausationCountGeneral";
import { InputProvider } from "./InputProvider";
import { FormProvider } from "react-hook-form";
import { Controller } from "react-hook-form";
import { BannerCausation } from "./BannerCausation";
import { Box } from "@mui/material";
import { Suggestion } from "./Suggestion";
import { CheckboxPayana } from "commons/components/CheckboxPayana.js";

const DynamicFormContent = ({
  config,
  renderField,
  causationProps,
  userProps,
}) => {
  return (
    <div className="form-row" style={config.components.style}>
      {config.components
        .fields({ ...causationProps, ...userProps })
        .map((field) => renderField(field))}
    </div>
  );
};

export const DynamicForm = forwardRef(({ config, isNested = false }, ref) => {
  const userProps = useContext(UserContext);
  const causationProps = useContext(CausationContext);
  const {
    id,
    formValues,
    search,
    handleSendCausation,
    handleSubmit,
    errors,
    register,
    setValue,
    control,
    formState,
    getValues,
    watch,
    trigger,
    hasErpCausation,
    ...methods
  } = causationProps;

  const { currentCompany } = userProps;

  const onSubmit = async (data) => {
    try {
      await handleSendCausation(data);
    } catch (error) {
      console.error("Error al enviar el formulario:", error);
    }
  };

  useImperativeHandle(ref, () => ({
    submit: async () => {
      return handleSubmit(onSubmit)();
    },
  }));

  const renderField = (field) => {
    const fieldContent = (() => {
      switch (field.type) {
        case "checkbox":
          if (!field.show) return null;

          return (
            <div style={field.innerStyle}>
              <CheckboxPayana
                {...field.props({ ...causationProps, ...userProps })}
              />
              <p style={{ fontSize: "14px", marginBottom: "0px" }}>
                {field.label}
              </p>
            </div>
          );
        case "text":
          return (
            <InputForm
              label={field.label}
              name={field.name}
              placeholder={field.placeholder}
              {...field.props({ ...causationProps, ...userProps })}
              errors={errors}
              register={register}
              disabled={field.disabled}
              required={field.required}
              showRequiredAsterisk={field.showRequiredAsterisk}
            />
          );
        case "date":
          const dateFieldName = field.name;
          register(dateFieldName, {
            required: field.required,
          });

          return (
            <InputForm
              label={field.label}
              name={field.name}
              placeholder={field.placeholder}
              customInput={
                <Controller
                  name={field.name}
                  control={control}
                  defaultValue={formValues[field.name] || null}
                  rules={{ required: field.required }}
                  render={({ field: { onChange, value, ref } }) => (
                    <LocalizationProvider
                      adapterLocale={es}
                      dateAdapter={AdapterDateFns}
                    >
                      <DatePicker
                        value={value}
                        onChange={(newValue) => {
                          onChange(newValue);
                        }}
                        format={field.format}
                        disabled={field.disabled}
                        slotProps={{
                          ...field.slotProps,
                          textField: {
                            inputRef: ref,
                            required: field.required,
                            error: !!errors[field.name],
                            helperText: errors[field.name]?.message,
                          },
                        }}
                        slots={{
                          openPickerIcon: () => (
                            <CalendarIcon stroke="#667085" />
                          ),
                        }}
                        sx={{
                          "& .MuiInputBase-root": {
                            fontSize: "14px",
                            height: "40px",
                          },
                        }}
                        {...field.props({ ...causationProps, ...userProps })}
                      />
                    </LocalizationProvider>
                  )}
                />
              }
              errors={errors}
            />
          );

        case "select":
          return (
            <InputForm
              label={field.label}
              name={field.name}
              placeholder={field.placeholder}
              customInput={
                <DynamicSelect
                  {...field.props({ ...causationProps })}
                  register={register}
                  name={field.name}
                  required={field.required}
                  value={formValues[field.name]}
                  placeholder={field.placeholder}
                  disabled={field.disabled}
                  setValue={setValue}
                />
              }
              errors={errors}
            />
          );

        case "autocomplete":
          if (!field?.show) return null;

          const registerProps = register
            ? register(field.name, {
                required: field.props({ ...causationProps })?.required,
              })
            : { ref: null, onChange: () => {} };

          const { ref, onChange: onChangeRegister } = registerProps;
          const isSuggested = field.props({ ...causationProps })?.isSuggested;

          const getFormValuesValue = (name) => {
            if (name === "costCenter") {
              if (
                formValues?.documentType &&
                formValues?.documentType?.use_cost_center
              ) {
                return formValues[name];
              }

              return "";
            }
            return formValues[name];
          };

          return (
            <InputForm
              label={field.label}
              name={field.name}
              placeholder={field.placeholder}
              errors={errors}
              renderHelperText={field.renderHelperText}
              customInput={
                <Autocomplete
                  {...field.props({ ...causationProps })}
                  options={field.options || search[field.name]}
                  value={field.value || getFormValuesValue(field.name)}
                  disabled={field.props({ ...causationProps })?.disabled}
                  onChange={(event, newValue) => {
                    const syntheticEvent = {
                      target: {
                        name: field.name,
                        value: newValue,
                      },
                    };

                    onChangeRegister(syntheticEvent);

                    if (field.props({ ...causationProps }).onChange) {
                      field
                        .props({ ...causationProps })
                        .onChange(event, newValue);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!errors[field.name] || field?.error}
                      helperText={errors[field.name]?.message || field?.error}
                      InputProps={{
                        ...params.InputProps,
                        style: { fontSize: 14 },
                        ...(isSuggested && {
                          startAdornment: (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                                mr: 2,
                                backgroundColor: "transparent",
                              }}
                            >
                              <Suggestion />
                            </Box>
                          ),
                        }),
                      }}
                      placeholder={field.placeholder}
                    />
                  )}
                  sx={{
                    "& .MuiAutocomplete-input": {
                      fontSize: "14px",
                      color: isSuggested ? "#6938EF" : "inherit",
                      fontWeight: isSuggested ? "500" : "inherit",
                    },
                    ...(isSuggested && {
                      backgroundColor: "#FAFAFF",
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#BDB4FE",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#BDB4FE",
                      },
                    }),
                    ...field.props({ ...causationProps })?.sx,
                  }}
                />
              }
            />
          );
        case "provider":
          return (
            <InputForm
              label={field.label}
              name={field.name}
              placeholder={field.placeholder}
              renderHelperText={field.renderHelperText}
              customInput={
                <InputProvider
                  causationProps={causationProps}
                  field={field}
                  disabled={field.disabled}
                  register={register}
                />
              }
            />
          );
        case "pdfViewContainer":
          return id ? <PdfViewContainer /> : null;
        case "container":
          return (
            <DynamicFormContent
              config={field.children}
              renderField={renderField}
              causationProps={causationProps}
              userProps={userProps}
            />
          );
        case "syncInformation":
          return (
            <SyncInformation
              {...field.props({ ...causationProps, ...userProps })}
              logo={field.logo}
              updateText={field.updateText}
              paramsText={field.paramsText}
              disabled={field.disabled}
              hasErpCausation={hasErpCausation}
              currentCompany={currentCompany}
            />
          );
        case "switch":
          return <DynamicSwitch />;
        case "pdfInformation":
          if (!field.show) return null;

          return <PdfInformation />;
        case "table":
          return (
            <DynamicTable
              {...field.props({ ...causationProps })}
              isCaused={field.isCaused}
              handleSetItems={(newItems) => setValue("items", newItems)}
              control={control}
            />
          );
        case "paymentMethods":
          return (
            <Payments
              {...field.props({ ...causationProps })}
              columns={field.columns}
              isCaused={field.isCaused}
              setValue={setValue}
              show={field.show}
            />
          );
        case "observations":
          return (
            <Observation
              {...field.props({ ...causationProps })}
              disabled={field.disabled}
            />
          );
        case "generalInformation":
          return (
            <ExpandCausationCountGeneral
              {...field.props({ ...causationProps })}
              isSuggested={causationProps?.formValues?.isSuggested}
              setValue={setValue}
            />
          );
        case "bannerCausation":
          return (
            <BannerCausation
              label={field.label}
              subLabel={field.subLabel}
              invoice={causationProps.invoice}
            />
          );
        case "banner":
          return Object.values(
            causationProps.formValues?.isSuggested || {}
          ).some((value) => value) && !causationProps.isCreditNote ? (
            <div style={field.customStyle}>
              {field.icon}
              <p style={field.textStyle}>{field.text}</p>
            </div>
          ) : null;
        default:
          return null;
      }
    })();

    return (
      <div
        key={field.name}
        className={`col col-${field.cols || 12}`}
        style={field.style}
      >
        {fieldContent}
      </div>
    );
  };

  const content = (
    <div className="form-row" style={config.components.style}>
      {config.components
        .fields({ ...causationProps, ...userProps })
        .map((field) => renderField(field))}
    </div>
  );

  if (isNested) {
    return content;
  }

  const formMethods = {
    register,
    setValue,
    getValues,
    control,
    formState,
    watch,
    trigger,
    ...methods,
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)} className="dynamic-form">
        {content}
      </form>
    </FormProvider>
  );
});
