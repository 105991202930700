import React from "react";
import { useMediaQuery } from "react-responsive";
import { Stack, Box } from "@mui/material";
import { InvoiceForm } from ".";
import FetchingPDF from "./FetchingPDF";

export const InvoiceFormWithImage = ({
  invoiceId,
  formValues,
  setFormValues,
  invoice,
  supplier,
  service,
  setSupplier,
  selectedFiles,
  setService,
  setSelectedFiles,
  suppliers,
  createdSupplierName,
  setDrawerIsOpen,
  getOptions,
  approvationNeeded = false,
  tags,
  onSelect,
  onRemove,
  setTags,
  setTagsSelected,
  filePath,
  filePathIsFromDian,
  conceptError,
  setConceptError,
  collectionNumberError,
  setCollectionNumberError,
  openSupplierForm,
  isPayanaInvoice,
  refreshInvoiceData,
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });
  if (isMobile) {
    return (
      <Stack
        direction={"column"}
        spacing={2}
        sx={{ margin: "auto", height: "100%" }}
      >
        <Box>
          <InvoiceForm
            isPayanaInvoice={isPayanaInvoice}
            invoiceId={invoiceId}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            formValues={formValues}
            setFormValues={setFormValues}
            invoice={invoice}
            supplier={supplier}
            service={service}
            setSupplier={setSupplier}
            setService={setService}
            suppliers={suppliers}
            createdSupplierName={createdSupplierName}
            setDrawerIsOpen={setDrawerIsOpen}
            getOptions={getOptions}
            approvationNeeded={approvationNeeded}
            tags={tags}
            onSelect={onSelect}
            onRemove={onRemove}
            setTags={setTags}
            setTagsSelected={setTagsSelected}
            conceptError={conceptError}
            setConceptError={setConceptError}
            collectionNumberError={collectionNumberError}
            setCollectionNumberError={setCollectionNumberError}
            refreshInvoiceData={refreshInvoiceData}
          />
        </Box>
        <Box>
          {filePath ? (
            <iframe
              src={`${filePath}#toolbar=0#view=fitH`}
              height="100%"
              width="100%"
              title="invoice_file"
            />
          ) : null}
        </Box>
      </Stack>
    );
  } else {
    return (
      <Stack
        direction={"row"}
        spacing={2}
        sx={{ margin: "auto", height: "100%" }}
      >
        {filePathIsFromDian ? (
          <FetchingPDF />
        ) : filePath ? (
          <Box sx={{ width: "50%" }}>
            <iframe
              src={`${filePath}#toolbar=0#view=fitH`}
              height="100%"
              width="100%"
              title="invoice_file"
            />
          </Box>
        ) : null}
        <Box sx={{ width: filePath ? "50%" : "100%" }}>
          <InvoiceForm
            isPayanaInvoice={isPayanaInvoice}
            openSupplierForm={openSupplierForm}
            invoiceId={invoiceId}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            formValues={formValues}
            setFormValues={setFormValues}
            invoice={invoice}
            supplier={supplier}
            service={service}
            setSupplier={setSupplier}
            setService={setService}
            suppliers={suppliers}
            createdSupplierName={createdSupplierName}
            setDrawerIsOpen={setDrawerIsOpen}
            getOptions={getOptions}
            approvationNeeded={approvationNeeded}
            tags={tags}
            onSelect={onSelect}
            onRemove={onRemove}
            setTags={setTags}
            setTagsSelected={setTagsSelected}
            conceptError={conceptError}
            setConceptError={setConceptError}
            collectionNumberError={collectionNumberError}
            setCollectionNumberError={setCollectionNumberError}
            refreshInvoiceData={refreshInvoiceData}
          />
        </Box>
      </Stack>
    );
  }
};
