import React from "react";
import { styles } from "../styles";
import { causationStates } from "constants";

export function BannerCausation({ label, subLabel, invoice }) {
  return invoice?.causation_state === causationStates.caused ? (
    <div style={styles.bannerContainer}>
      <p style={styles.bannerText}>
        <strong>{label}</strong> {subLabel}
      </p>
    </div>
  ) : null;
}
