import { Box, Stack, Typography } from "@mui/material";
import { styles } from "../styles";
import { UserContext } from "../../../contexts";
import { useContext } from "react";
import { EmptyCheckIcon } from "../../../assets";
import { PrimaryButton } from "../../../commons/buttons";

export const CollectionsDisabled = () => {
  const { currentUserIsAdmin } = useContext(UserContext);

  const items = [
    "Importar tus cobros desde un archivo Excel, manualmente o desde Siigo.",
    "Enviar automáticamente recordatorios de vencimiento a tus clientes por email y WhatsApp.",
    "Brindar a tus clientes múltiples medios de pago como PSE, tarjeta de crédito y efectivo.",
    "Recibir la acreditación de tus cobros desde Bancolombia y Davivienda en menos de dos horas.",
    "Identificar y conciliar todos los cobros recibidos de forma exitosa.",
  ];

  const renderItems = () => {
    return items.map((item, index) => (
      <div style={styles.emptyRow}>
        <div style={{ width: "24px", height: "24px" }}>
          <EmptyCheckIcon bgColor={"#D1FADF"} color={"#039855"} />
        </div>
        <Typography sx={styles.emptyInfoText}>{item}</Typography>
      </div>
    ));
  };

  return (
    <>
      <Box
        sx={{
          ...styles.emptyStateBox,
          backgroundColor: "white",
          width: "fit-content",
          margin: "24px auto 0",
          borderRadius: "12px",
        }}
      >
        <Stack spacing={2} p={4} sx={styles.emptyStateStack}>
          <Box>
            <Typography sx={styles.emptyStateTitle}>
              Comienza a enviar cobros
            </Typography>
            <Typography
              sx={{
                ...styles.emptyStateSubtitle,
                maxWidth: "380px",
                marginTop: "6px",
                color: "#667085",
              }}
            >
              Agiliza el proceso de envío de cobros, ahorra hasta un 50% del
              tiempo y mejora tu tasa de cobrabilidad.
            </Typography>
          </Box>
          <div
            style={{
              padding: 24,
              background: "#FCFAFF",
              boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.06)",
              borderRadius: 8,
              border: "1px #D6BBFB solid",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: "16px",
              display: "flex",
            }}
          >
            <div style={{ width: "100%" }}>
              <Typography sx={styles.emptyStateTitle}>
                ¿Qué podrás hacer con este módulo?
              </Typography>
            </div>
            {renderItems()}
          </div>
          {currentUserIsAdmin && (
            <PrimaryButton
              text="Quiero saber más"
              action={() =>
                window.Intercom(
                  "showNewMessage",
                  "Hola! Me interesa conocer cómo funciona la solución de cobros de Payana."
                )
              }
              width="100%"
            />
          )}
        </Stack>
      </Box>
    </>
  );
};
