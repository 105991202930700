import React from "react";
import {
  Box,
  Divider,
  MenuItem,
  Select,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { NumericFormat } from "react-number-format";
import { INITIAL_TAX } from "../constants";
import { styles } from "../styles";
import { Suggestion } from "./Suggestion";

const formatAmount = ({
  value = 0,
  options = {
    displayType: "text",
    thousandSeparator: ".",
    decimalSeparator: ",",
    prefix: "$",
    decimalScale: "2",
  },
  styles = {},
}) => {
  return (
    <NumericFormat
      style={{ ...styles }}
      value={value}
      displayType={options.displayType}
      thousandSeparator={options.thousandSeparator}
      decimalSeparator={options.decimalSeparator}
      prefix={options.prefix}
      decimalScale={options.decimalScale}
    />
  );
};

export function ExpandCausationCountGeneral({
  content,
  handleOnChangeGeneralTax,
  taxReteIva,
  taxReteIca,
  amountTotalNeto,
  allowReteIva = true,
  allowReteIca = true,
  isCaused,
  isSuggested = {},
  setValue,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const taxOptionSelected = (type) => {
    if (type === "ReteIVA") {
      return (
        taxReteIva.find((tax) => tax.id === content.reteIva.id) || INITIAL_TAX
      );
    }

    if (type === "ReteICA") {
      return (
        taxReteIca.find((tax) => tax.id === content.reteIca.id) || INITIAL_TAX
      );
    }
  };

  const renderSuggestionIcon = () => {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          mr: 2,
          backgroundColor: "transparent",
        }}
      >
        <Suggestion />
      </Box>
    );
  };

  const responsiveBoxStyle = {
    ...styles.countGeneralBoxContainer,
    minWidth: isMobile ? "100%" : 360,
    width: "100%",
  };

  const responsiveTaxesSelectStyle = {
    ...styles.taxesSelect,
    minWidth: isMobile ? "120px" : "150px",
    fontSize: "14px",
    "& fieldset": {
      borderTop: "none",
      borderBottom: "none",
      borderLeft: "none",
      borderRadius: "0px",
    },
  };

  return (
    <Box sx={responsiveBoxStyle}>
      <Stack sx={styles.countGeneralStackContainer}>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography
            style={{
              ...styles.countGeneralText,
              fontSize: "16px",
              color: "#344054",
              marginBottom: "12px",
              fontWeight: 600,
            }}
          >
            Resumen
          </Typography>
        </Stack>
        <Stack gap={"12px"}>
          <AmountRow
            label="Total bruto"
            value={content.totalGross}
            styles={styles}
            isMobile={isMobile}
          />
          <AmountRow
            label="Descuento"
            value={content.discount}
            styles={styles}
            isMobile={isMobile}
          />
          <AmountRow
            label="Subtotal"
            value={content.subtotal}
            styles={styles}
            isMobile={isMobile}
          />
          <AmountRow
            label="IVA"
            value={content.ivaTotal}
            styles={styles}
            isMobile={isMobile}
          />
          <AmountRow
            label="Retefuente"
            value={content.retefuenteTotal}
            styles={styles}
            isMobile={isMobile}
          />
          {allowReteIva && (
            <Stack
              direction={isMobile ? "column" : "row"}
              justifyContent={"space-between"}
              alignItems={isMobile ? "flex-start" : "center"}
              spacing={isMobile ? 1 : 0}
              sx={{ width: "100%" }}
            >
              <Typography style={styles.countGeneralText}>ReteIVA</Typography>
              <div
                style={{
                  ...styles.taxesContainer,
                  width: isMobile ? "100%" : "auto",
                  ...(isSuggested.reteIva
                    ? {
                        borderLeft: "none",
                        borderTopLeftRadius: "8px",
                        borderBottomLeftRadius: "8px",
                      }
                    : {
                        borderLeft: "1px solid #D0D5DD",
                        borderTopLeftRadius: "8px",
                        borderBottomLeftRadius: "8px",
                      }),
                }}
              >
                <Select
                  name="ReteIVA"
                  size="small"
                  displayEmpty={true}
                  onChange={(e) => {
                    handleOnChangeGeneralTax(e);
                    setValue("isSuggested.reteIva", false, {
                      shouldValidate: true,
                    });
                  }}
                  value={taxOptionSelected("ReteIVA")}
                  startAdornment={
                    <>{isSuggested.reteIva && renderSuggestionIcon()}</>
                  }
                  sx={{
                    ...responsiveTaxesSelectStyle,
                    ...styles.countGeneralText,
                    fontSize: "14px",
                    backgroundColor: isSuggested.reteIva
                      ? "#FAFAFF"
                      : "inherit",
                    color: isSuggested.reteIva ? "#6938EF" : "inherit",
                    fontWeight: isSuggested.reteIva ? "500" : "inherit",
                    "& .MuiSelect-select": {
                      fontSize: "14px",
                    },
                    ...(isSuggested.reteIva && {
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #BDB4FE",
                        borderTopLeftRadius: "8px",
                        borderBottomLeftRadius: "8px",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #BDB4FE",
                        borderTopLeftRadius: "8px",
                        borderBottomLeftRadius: "8px",
                      },
                    }),
                  }}
                  disabled={isCaused}
                >
                  <MenuItem
                    value={INITIAL_TAX}
                    style={{ height: "36px" }}
                  ></MenuItem>
                  {taxReteIva.map((tax) => (
                    <MenuItem
                      key={tax.id}
                      value={tax}
                      style={styles.countGeneralText}
                    >
                      {tax.description}
                    </MenuItem>
                  ))}
                </Select>
                {formatAmount({
                  value: content.reteIvaValue,
                  styles: {
                    textAlign: "right",
                    width: isMobile ? "60px" : "80px",
                    ...styles.countGeneralTextNum,
                    padding: "8px 0px",
                  },
                })}
              </div>
            </Stack>
          )}
          {allowReteIca && (
            <Stack
              direction={isMobile ? "column" : "row"}
              justifyContent={"space-between"}
              alignItems={isMobile ? "flex-start" : "center"}
              spacing={isMobile ? 1 : 0}
              sx={{ width: "100%" }}
            >
              <Typography style={styles.countGeneralText}>ReteICA</Typography>
              <div
                style={{
                  ...styles.taxesContainer,
                  width: isMobile ? "100%" : "auto",
                  ...(isSuggested.reteIca
                    ? {
                        borderLeft: "none",
                        borderTopLeftRadius: "8px",
                        borderBottomLeftRadius: "8px",
                      }
                    : {
                        borderLeft: "1px solid #D0D5DD",
                        borderTopLeftRadius: "8px",
                        borderBottomLeftRadius: "8px",
                      }),
                }}
              >
                <Select
                  name="ReteICA"
                  size="small"
                  displayEmpty={true}
                  onChange={(e) => {
                    handleOnChangeGeneralTax(e);
                    setValue("isSuggested.reteIca", false, {
                      shouldValidate: true,
                    });
                  }}
                  value={taxOptionSelected("ReteICA")}
                  startAdornment={
                    <>{isSuggested.reteIca && renderSuggestionIcon()}</>
                  }
                  sx={{
                    ...responsiveTaxesSelectStyle,
                    ...styles.countGeneralText,
                    fontSize: "14px",
                    backgroundColor: isSuggested.reteIca
                      ? "#FAFAFF"
                      : "inherit",
                    color: isSuggested.reteIca ? "#6938EF" : "inherit",
                    fontWeight: isSuggested.reteIca ? "500" : "inherit",
                    "& .MuiSelect-select": {
                      fontSize: "14px",
                    },
                    ...(isSuggested.reteIca && {
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #BDB4FE",
                        borderTopLeftRadius: "8px",
                        borderBottomLeftRadius: "8px",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #BDB4FE",
                        borderTopLeftRadius: "8px",
                        borderBottomLeftRadius: "8px",
                      },
                    }),
                  }}
                  disabled={isCaused}
                >
                  <MenuItem
                    value={INITIAL_TAX}
                    style={{ height: "36px" }}
                  ></MenuItem>
                  {taxReteIca.map((tax) => (
                    <MenuItem
                      key={tax.id}
                      value={tax}
                      style={styles.countGeneralText}
                    >
                      {tax.description}
                    </MenuItem>
                  ))}
                </Select>
                {formatAmount({
                  value: content.reteIcaValue,
                  styles: {
                    textAlign: "right",
                    width: isMobile ? "60px" : "80px",
                    ...styles.countGeneralTextNum,
                  },
                })}
              </div>
            </Stack>
          )}
          <Divider style={{ background: "#D0D5DD" }} />
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            style={styles.totalText}
          >
            <Typography style={styles.totalText}>Total neto</Typography>
            {formatAmount({
              value: amountTotalNeto,
              styles: styles.totalTextNum,
            })}
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
}

const AmountRow = ({ label, value, styles, isMobile }) => (
  <Stack
    direction={"row"}
    justifyContent={"space-between"}
    sx={{
      flexWrap: isMobile ? "wrap" : "nowrap",
      gap: isMobile ? "4px" : "0",
    }}
  >
    <Typography style={styles.countGeneralText}>{label}</Typography>
    {formatAmount({
      value: value,
      styles: styles.countGeneralTextNum,
    })}
  </Stack>
);
