export const TimerIcon = ({
  circle = true,
  width = 56,
  height = 56,
  isProcessing = false,
}) => (
  <svg width={width} height={height} viewBox={`0 0 24 24`} fill="none">
    {circle && <rect width={56} height={56} rx={28} fill="#D0D5DD" />}
    {isProcessing ? (
      <path
        d="M9.5 18.5H15.5M7.1 2H17.9C18.4601 2 18.7401 2 18.954 2.10899C19.1422 2.20487 19.2951 2.35785 19.391 2.54601C19.5 2.75992 19.5 3.03995 19.5 3.6V5.67452C19.5 6.1637 19.5 6.40829 19.4447 6.63846C19.3957 6.84254 19.3149 7.03763 19.2053 7.21657C19.0816 7.4184 18.9086 7.59135 18.5627 7.93726L15.6314 10.8686C15.2354 11.2646 15.0373 11.4627 14.9632 11.691C14.8979 11.8918 14.8979 12.1082 14.9632 12.309C15.0373 12.5373 15.2354 12.7354 15.6314 13.1314L18.5627 16.0627C18.9086 16.4086 19.0816 16.5816 19.2053 16.7834C19.3149 16.9624 19.3957 17.1575 19.4447 17.3615C19.5 17.5917 19.5 17.8363 19.5 18.3255V20.4C19.5 20.9601 19.5 21.2401 19.391 21.454C19.2951 21.6422 19.1422 21.7951 18.954 21.891C18.7401 22 18.4601 22 17.9 22H7.1C6.53995 22 6.25992 22 6.04601 21.891C5.85785 21.7951 5.70487 21.6422 5.60899 21.454C5.5 21.2401 5.5 20.9601 5.5 20.4V18.3255C5.5 17.8363 5.5 17.5917 5.55526 17.3615C5.60425 17.1575 5.68506 16.9624 5.79472 16.7834C5.9184 16.5816 6.09135 16.4086 6.43726 16.0627L9.36863 13.1314C9.76465 12.7354 9.96265 12.5373 10.0368 12.309C10.1021 12.1082 10.1021 11.8918 10.0368 11.691C9.96266 11.4627 9.76464 11.2646 9.36863 10.8686L6.43726 7.93726C6.09136 7.59136 5.9184 7.4184 5.79472 7.21657C5.68506 7.03763 5.60425 6.84254 5.55526 6.63846C5.5 6.40829 5.5 6.1637 5.5 5.67452V3.6C5.5 3.03995 5.5 2.75992 5.60899 2.54601C5.70487 2.35785 5.85785 2.20487 6.04601 2.10899C6.25992 2 6.53995 2 7.1 2Z"
        stroke="#667085"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    ) : (
      <path
        d="M28.0001 28L23.015 23.8458C22.2744 23.2286 21.9041 22.92 21.6378 22.5417C21.4019 22.2065 21.2268 21.8325 21.1203 21.4367C21.0001 20.99 21.0001 20.5079 21.0001 19.5438V16.3334M28.0001 28L32.9851 23.8458C33.7258 23.2286 34.0961 22.92 34.3623 22.5417C34.5982 22.2065 34.7734 21.8325 34.8799 21.4367C35.0001 20.99 35.0001 20.5079 35.0001 19.5438V16.3334M28.0001 28L23.015 32.1542C22.2744 32.7714 21.9041 33.0801 21.6378 33.4584C21.4019 33.7936 21.2268 34.1676 21.1203 34.5634C21.0001 35.0101 21.0001 35.4921 21.0001 36.4563V39.6667M28.0001 28L32.9851 32.1542C33.7258 32.7715 34.0961 33.0801 34.3623 33.4584C34.5982 33.7936 34.7734 34.1676 34.8799 34.5634C35.0001 35.0101 35.0001 35.4921 35.0001 36.4563V39.6667M18.6667 16.3334H37.3334M18.6667 39.6667H37.3334"
        stroke="#364152"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    )}
  </svg>
);
