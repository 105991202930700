import { PlusIcon2 } from "../../../assets";
import { PrimaryButton, SecondaryButton } from "../../../commons/buttons";

const styles = {
  row: {
    display: "flex",
    flexDirection: "row",
    marginTop: "16px",
    gap: "16px",
  },
};

export const ServicesCreateButtons = ({
  openManualCreate,
  openMassiveCreate,
}) => (
  <div style={styles.row}>
    <PrimaryButton
      text="Carga manual"
      action={openManualCreate}
      width="150px"
      loading={false}
    />
  </div>
);
