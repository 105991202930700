import { styles } from "./styles";

function PaymentMethodItem({
  icon,
  type,
  id,
  label,
  onClick,
  selectedPaymentMethod,
  disabled,
  disabledLegend,
  isCreditCardEnabled,
  isBankingCorrespondentEnabled,
}) {
  const isSelected = selectedPaymentMethod === type;
  return (
    <div
      style={
        disabled
          ? styles.radioButtonDisabledContainer
          : isSelected
          ? styles.radioButtonSelectedContainer
          : styles.radioButtonContainer
      }
      onClick={() => !disabled && onClick()}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {icon}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "8px",
          }}
        >
          <div style={styles.radioTextContainer}>
            <p
              style={
                disabled
                  ? styles.radioTitleDisabled
                  : isSelected
                  ? styles.radioSelectedTitle
                  : styles.radioTitle
              }
            >
              {label}
            </p>
          </div>
          {disabled && disabledLegend}
        </div>
      </div>
      {(isCreditCardEnabled || isBankingCorrespondentEnabled) && (
        <div className="form-check form-check-reverse">
          <input
            style={{ cursor: "pointer" }}
            className="form-check-input"
            type="radio"
            name="paymentMethod"
            id={id}
            disabled={disabled}
            value={type}
            onChange={onClick}
            checked={isSelected}
          />
        </div>
      )}
    </div>
  );
}

export default PaymentMethodItem;
