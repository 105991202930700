import React from "react";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { es } from "date-fns/locale";
import { typePaymentOnChange } from "../../constants";
import { CalendarIcon } from "assets";
import { Controller } from "react-hook-form";

export const InputDate = ({
  payment,
  handleOnChangePayment,
  index,
  control,
  name = `payments.${index}.date`,
  isCaused,
  required = false,
}) => {
  const isRequired = payment?.data?.due_type === -1 ? true : false;

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={payment?.data?.dueDate || null}
      rules={{
        required: required ? isRequired : false,
        validate: () => {
          if (!isRequired || !required) return true;
          if (!payment?.data?.dueDate) {
            return "Selecciona una fecha.";
          }
          if (
            !(payment?.data?.dueDate instanceof Date) ||
            isNaN(payment?.data?.dueDate)
          ) {
            return "La fecha ingresada no es válida.";
          }
          return true;
        },
      }}
      render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
        <LocalizationProvider adapterLocale={es} dateAdapter={AdapterDateFns}>
          <DatePicker
            disabled={
              payment?.accounting_concept === "" ||
              payment?.data?.due_type !== -1 ||
              isCaused
            }
            value={payment?.data?.dueDate || null}
            name="paymentDueDate"
            format="dd/MM/yyyy"
            onChange={(newValue) => {
              onChange(newValue);
              handleOnChangePayment(
                index,
                newValue,
                typePaymentOnChange.dueDate
              );
            }}
            slotProps={{
              textField: {
                InputProps: {
                  style: { fontSize: 14 },
                },
                size: "small",
                error: !!error,
                helperText: error?.message,
              },
            }}
            slots={{
              openPickerIcon: () => <CalendarIcon stroke="#667085" />,
            }}
            sx={{
              backgroundColor: "#FFF",
              borderRadius: "8px",
              width: "100%",
              fontSize: "14px",
            }}
          />
        </LocalizationProvider>
      )}
    />
  );
};
