import { AutocompleteTable } from "commons/components";
import React, { useEffect, useState } from "react";
import { getItemWarehouses } from "services";

export const AutocompleteWareHouse = ({
  isCaused,
  params,
  handleOnChangeItem,
  register,
  index,
  error,
  isSuggested = false,
  onChangeCallback,
}) => {
  const [optionsWareHouses, setOptionsWareHouses] = useState(
    params?.value ? [params.value] : []
  );
  const { row } = params;
  const getWareHouses = async (productId = null, query = "") => {
    try {
      const response = await getItemWarehouses(productId, query);
      if (!Array.isArray(response)) return;
      setOptionsWareHouses(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!row.product?.is_inventory_control) return;

    getWareHouses(row.product.id);
  }, []);

  return (
    <AutocompleteTable
      required={true}
      error={error && "Este campo es requerido"}
      register={register}
      name={`items.${index}.warehouse`}
      disabled={isCaused}
      options={optionsWareHouses}
      getOptionLabel={(option) => option?.wareHouse}
      isSuggested={isSuggested}
      onChange={(event, option) => {
        event.preventDefault();
        handleOnChangeItem(params, "warehouse", option);
        if (onChangeCallback) {
          onChangeCallback();
        }
      }}
      onInputChange={(event) => {
        if (event?.target?.value?.length < 3) return;
        getWareHouses(row.product.id, event?.target?.value);
      }}
      value={params?.value}
      tableConfig={{
        columns: [
          {
            headerName: "Bodega",
            getText: (option) => option?.wareHouse,
            width: "200px",
          },
          {
            headerName: "Saldo",
            getText: (option) => option?.value,
            width: "50px",
          },
        ],
        rowClassName: "causation",
        getRowTooltipText: (option) => `${option?.wareHouse}`,
      }}
      {...(isSuggested && {
        sx: {
          width: "100%",
          "& .MuiAutocomplete-input": {
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            color: "#6938EF",
            fontWeight: "500",
          },
        },
      })}
    />
  );
};
