import { Autocomplete, Box, TextField } from "@mui/material";
import CustomPaper from "./CustomPaper";
import OptionComponent from "./OptionComponent";
import { Tooltip } from "../Tooltip";
import { Suggestion } from "commons/modals/ExpandCausationModal/components";

const AutocompleteTable = ({
  disabled,
  name,
  options = [], // Set default empty array for options
  tabIndex,
  getOptionLabel,
  onChange,
  onInputChange,
  value,
  tableConfig,
  error, // Agregamos prop de error
  required,
  register,
  placeholder = "",
  isSuggested = false,
  ...props
}) => {
  const { columns, getRowTooltipText, rowClassName } = tableConfig;

  // Hacer el register opcional
  const registerProps = register
    ? register(name, { required })
    : { ref: null, onChange: () => {} };

  const { ref, onChange: onChangeRegister } = registerProps;

  return (
    <Autocomplete
      value={value}
      onChange={(event, newValue) => {
        const syntheticEvent = {
          target: {
            name: name,
            value: newValue,
          },
        };

        onChangeRegister(syntheticEvent);

        if (onChange) {
          onChange(event, newValue);
        }
      }}
      PaperComponent={CustomPaper(columns)}
      disabled={disabled}
      onKeyDown={(event) => {
        if (event.key === " ") {
          event.stopPropagation();
        }
      }}
      ListboxProps={{
        style: {
          padding: 0,
          marginRight: "-15px",
        },
      }}
      name={name}
      options={options}
      size="small"
      tabIndex={tabIndex}
      sx={{
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "flex-start",
        display: "flex",
        "& fieldset": {
          border: "none",
        },
        "& .MuiAutocomplete-input": {
          fontSize: "14px",
          color: isSuggested ? "#6938EF" : "inherit",
          fontWeight: isSuggested ? "500" : "inherit",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
          maxWidth: "100%",
        },
        "& .MuiAutocomplete-option": {
          fontSize: "14px",
        },
        ...(props.sx || {}),
      }}
      freeSolo
      autoHighlight
      getOptionLabel={getOptionLabel}
      onInputChange={onInputChange}
      renderOption={(props, option) => (
        <OptionComponent
          columns={columns}
          props={props}
          option={option}
          className={rowClassName}
          getRowTooltipText={getRowTooltipText}
        />
      )}
      renderInput={(params) => {
        return (
          <Tooltip title={error || params.inputProps.value}>
            <TextField
              {...params}
              inputRef={ref}
              error={!!error}
              placeholder={placeholder}
              InputProps={{
                ...params.InputProps,
                style: { fontSize: 14 },
                ...(isSuggested && {
                  startAdornment: (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        backgroundColor: "transparent",
                      }}
                    >
                      <Suggestion />
                    </Box>
                  ),
                }),
              }}
            />
          </Tooltip>
        );
      }}
    />
  );
};

export default AutocompleteTable;
