import { InputAmount } from "../components/Payments/InputAmount";
import { InputDate } from "../components/Payments/InputDate";
import { PaymentSelect } from "../components/Payments/PaymentSelect";
import { SelectAdvance } from "../components/Payments/SelectAdvance";
import { tableColumns } from "./tableColumns";
import { multipleSelectColumns } from "./multipleSelectColumns";
import { Typography } from "@mui/material";
import { SheetIcon } from "assets";
import { InputShareNumber } from "../components";

export const configWorldOffice = {
  version: "1",
  components: {
    fields: () => [
      {
        type: "container",
        name: "container-form",
        className: "form-row",
        cols: 12,
        children: {
          components: {
            fields: () => [
              {
                type: "bannerCausation",
                name: "bannerCausation",
                cols: 12,
                label: "Factura causada correctamente.",
                subLabel:
                  "Si deseas hacer una modificación deberás hacerlo directamente en World Office.",
              },
            ],
          },
        },
      },
      {
        type: "container",
        name: "container-form",
        className: "form-row",
        cols: 12,
        children: {
          components: {
            fields: (params) => [
              {
                type: "pdfInformation",
                name: "pdfInformation",
                cols: 12,
                show: true,
              },
              {
                type: "pdfViewContainer",
                name: "pdfViewContainer",
                cols: params.checked ? 7 : 0,
                style: {
                  padding: "24px",
                },
              },
              {
                type: "container",
                name: "container-form-right",
                cols: params.checked ? 5 : 8,
                style: {
                  padding: params.checked ? "24px" : "0px 0px 24px 0px",
                  margin: params.checked ? "0px" : "auto",
                },
                children: {
                  components: {
                    fields: () => [
                      {
                        type: "syncInformation",
                        name: "syncInformation",
                        cols: 12,
                        disabled: params.isCaused,
                        style: {
                          marginBottom: "12px",
                        },
                        logo: <SheetIcon style={{ marginBottom: "2px" }} />,
                        paramsText: "Parámetros de World Office",
                        props: ({
                          isSiigoContador,
                          lastSyncCausationAt,
                          formValues,
                          handleOnChangeAutoComplete,
                          setSupplierFound,
                          causationScreen,
                        }) => ({
                          isSiigoContador,
                          lastSyncCausationAt,
                          formValues,
                          handleOnChangeAutoComplete,
                          setSupplierFound,
                          causationScreen,
                        }),
                      },
                      {
                        type: "container",
                        name: "container-form",
                        className: "form-row",
                        cols: 12,
                        children: {
                          components: {
                            fields: (params) => [
                              {
                                type: "select",
                                name: "documentType",
                                cols: 9,
                                style: {
                                  marginBottom: "12px",
                                },
                                label: "Tipo de documento",
                                required: true,
                                disabled: params.isCaused,
                                placeholder: "Selecciona el tipo de documento",
                                props: ({
                                  search,
                                  formValues,
                                  handleOnChange,
                                }) => ({
                                  name: "documentType",
                                  size: "small",
                                  variant: "outlined",
                                  displayEmpty: true,
                                  style: { width: "100%", fontSize: "14px" },
                                  disabled: false,
                                  options: search["invoiceType"],
                                  initialValue:
                                    "Selecciona el tipo de documento",
                                  value: formValues["documentType"],
                                  isSuggested:
                                    formValues.isSuggested.documentType,
                                  onChange: (event) => {
                                    handleOnChange(event);
                                  },
                                  displayOption: (option) =>
                                    `${option.erp_doc_class} - ${
                                      option?.internal_description ||
                                      option?.name
                                    }`,
                                }),
                              },
                              {
                                type: "text",
                                name: "consecutive",
                                cols: 2.5,
                                label: "Consecutivo",
                                style: {
                                  marginLeft: "12px",
                                },
                                props: (params) => ({
                                  value: params.formValues?.consecutive || "",
                                  disabled: params.isCaused,
                                  onChange: (event) => {
                                    params.handleOnChange(event);
                                  },
                                }),
                                required: true,
                                showRequiredAsterisk: false,
                              },
                            ],
                          },
                        },
                      },
                      {
                        type: "provider",
                        name: "supplier",
                        cols: 12,
                        style: {
                          marginBottom: "12px",
                        },
                        label: "Proveedor",
                        required: true,
                        disabled: params.isCaused,
                        placeholder: "Buscar proveedor",
                        textProviderNotFoundUpload:
                          "Si no encuentras el proveedor, cárgalo en World Office y vuelve a actualizar.",
                        textProviderNotFoundCreate:
                          "No encontramos el proveedor en World Office, créalo y vuelve a actualizar.",
                        props: (params) => ({
                          name: "supplier",
                          size: "small",
                          freeSolo: true,
                          getOptionLabel: (option) => option?.full_name || "",
                          onChange: (event, value) => {
                            params.handleOnChangeAutoComplete(
                              "supplier",
                              value
                            );
                            params.setSupplierFound(true);
                          },
                          onInputChange: (event) => {
                            params.getSearchOptions(
                              "supplier",
                              event?.target?.value
                            );
                            params.setSupplierFound(true);
                          },
                          filterOptions: (options, { inputValue }) => {
                            const inputValueLower = inputValue.toLowerCase();
                            return options.filter(
                              (option) =>
                                option.full_name
                                  .toLowerCase()
                                  .includes(inputValueLower) ||
                                option.identification
                                  .toString()
                                  .includes(inputValueLower)
                            );
                          },
                          renderOption: (props, option) => {
                            return (
                              <li
                                {...props}
                                key={option?.id}
                                style={{ fontSize: 14 }}
                              >
                                {option?.full_name || ""}
                              </li>
                            );
                          },
                          placeholder: "Buscar proveedor",
                          renderHelperText: (
                            formValues,
                            supplierFound,
                            erpName
                          ) => {
                            if (
                              !formValues.supplier?.id &&
                              !formValues.supplier?.full_name
                            ) {
                              return (
                                <Typography
                                  style={{
                                    color: "#667085",
                                    fontWeight: "400",
                                    fontSize: "14px",
                                  }}
                                >
                                  Si no encuentras el proveedor, cárgalo en
                                  World Office y vuelve a actualizar.
                                </Typography>
                              );
                            } else if (
                              formValues.supplier?.full_name &&
                              !supplierFound
                            ) {
                              return (
                                <Typography
                                  style={{
                                    color: "#F04438",
                                    fontWeight: "400",
                                    fontSize: "14px",
                                  }}
                                >
                                  No encontramos el proveedor en World Office,
                                  créalo y vuelve a actualizar.
                                </Typography>
                              );
                            }
                            return null;
                          },
                        }),
                      },
                      {
                        type: "container",
                        name: "container-form-right",
                        cols: 12,
                        children: {
                          components: {
                            style: {
                              justifyContent: "space-between",
                              display: "flex",
                              flexDirection: "row",
                              gap: "12px",
                              alignItems: "flex-start",
                            },
                            fields: () => [
                              {
                                type: "text",
                                name: "nroComprobante",
                                cols: 5.5,
                                style: {
                                  marginBottom: "12px",
                                },
                                props: (params) => ({
                                  value: params.formValues?.nroComprobante,
                                }),
                                label: "Número de comprobante",
                                placeholder: "Ingresa el número de comprobante",
                                required: true,
                                disabled: params.isCaused,
                                showRequiredAsterisk: false,
                              },
                              {
                                type: "date",
                                name: "dateElaboration",
                                required: true,
                                cols: 5.5,
                                style: { paddingRight: "8px" },
                                label: "Fecha de elaboración",
                                placeholder: "Ingresa la fecha de elaboración",
                                disabled: params.isCaused,
                                props: (params) => ({
                                  value: params.formValues?.dateElaboration,
                                }),
                                slotProps: {
                                  textField: {
                                    InputProps: {
                                      style: { fontSize: 14 },
                                    },
                                    size: "small",
                                    error: false,
                                  },
                                },
                              },
                            ],
                          },
                        },
                      },
                      {
                        type: "switch",
                        name: "Switch-pdf",
                        cols: 12,
                      },
                    ],
                  },
                },
              },
              {
                type: "container",
                name: "container-table",
                cols: 12,
                style: {
                  marginTop: "24px",
                },
                children: {
                  components: {
                    fields: () => [
                      {
                        type: "table",
                        name: "table",
                        cols: 12,
                        items: "items",
                        isCaused: params.isCaused,
                        props: (props) => ({
                          data: props.formValues.items,
                          hideFooter: props.formValues.items.length <= 25,
                          activateOverlade: props.activateOverlade,
                          search: props.search,
                          getSearchOptionsProducts: props.getSearchOptions,
                          handleChangeMultipleSelect:
                            props.handleChangeMultipleSelect,
                          hasCompanyWareHouse: props.hasCompanyWareHouse,
                          columns: () =>
                            tableColumns({
                              searchProductsOptions: props.search["product"],
                              taxIva: props.search["taxIva"],
                              taxesRete: props.search["taxRetefuente"],
                              isCaused: props.isCaused,
                              costCenter: props.search["costCenter"],
                              getSearchOptionsSupplier:
                                props.getSearchOptionsSupplier,
                              setValue: props.setValue,
                              ...props,
                            }),
                          multipleSelectColumns: () =>
                            multipleSelectColumns({
                              getSearchOptionsProducts: props.getSearchOptions,
                              products: props.search["product"],
                              taxesIva: props.search["taxIva"],
                              taxesRete: props.search["taxRetefuente"],
                              costCenter: props.search["costCenter"],
                              getSearchOptionsSupplier:
                                props.getSearchOptionsSupplier,
                              ...props,
                            }),
                          addNewTableInfo: props.addNewTableInfo,
                          sx: {
                            "& .MuiDataGrid-cell": {
                              color: props.isCaused
                                ? "rgba(0, 0, 0, 0.38)"
                                : "#344054",
                              fontSize: "14px",
                              borderBottom: "1px solid #EAECF0 !important",
                              borderLeft: "1px solid #EAECF0",
                              padding: "0px 10px !important",
                            },
                            "& .MuiDataGrid-columnHeader": {
                              fontSize: "12px",
                              color: "#344054",
                              fontWeight: 500,
                              borderBottom: "1px solid #EAECF0",
                            },
                            "& .MuiDataGrid-row": {
                              "&:hover": {
                                backgroundColor: "#F9FAFB",
                              },
                              maxHeight: "40px !important",
                            },
                            "& .suggested-cell": {
                              backgroundColor: "#FAFAFF",
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#BDB4FE",
                              },
                              "& input": {
                                backgroundColor: "#FAFAFF",
                              },
                              "&:hover": {
                                backgroundColor: "#F5F5FF",
                              },
                            },
                            "& .MuiDataGrid-columnHeaders": {
                              borderBottom: "none",
                            },
                            "& .MuiDataGrid-columnHeader:last-child": {
                              border: "none",
                            },
                            "& .MuiDataGrid-row.Mui-selected": {
                              backgroundColor: "transparent !important",
                            },
                            ...(!props.isCaused && {
                              "& .MuiDataGrid-cell:last-child": {
                                borderLeft: "1px solid #EAECF0",
                                borderTop: "none !important",
                                borderBottom: "none !important",
                              },
                            }),
                            ...(props.isCaused && {
                              "& .MuiDataGrid-cell:last-child": {
                                borderTop: "1px solid #EAECF0",
                                borderRight: "1px solid #EAECF0",
                              },
                            }),
                            border: "none",
                          },
                          style: {
                            borderRadius: "8px",
                          },
                        }),
                      },
                    ],
                  },
                },
              },
              {
                type: "container",
                name: "payments-table",
                cols: 6,
                style: {
                  padding: "24px",
                  margin: "0px auto",
                },
                children: {
                  components: {
                    fields: () => [
                      {
                        type: "paymentMethods",
                        name: "paymentMethods",
                        cols: 12,
                        isCaused: params.isCaused,
                        style: {
                          paddingBottom: "8px",
                        },
                        show: false,
                        props: (props) => ({
                          paymentsSearch: props.search.paymentMethod,
                          paymentsSelected: props.payments,
                          amountNeto: props.amountTotalNeto,
                          paymentsAdvances: props.paymentsAdvances,
                          handleSetPaymentsAdvances:
                            props.handleSetPaymentsAdvances,
                          handleSetPayments: props.handleSetPayments,
                          dueDate: props.formValues.dueDate,
                          formControl: props.control,
                        }),
                        columns: (params) => {
                          const columns = [
                            {
                              columns: 5,
                              renderCell: (
                                <PaymentSelect
                                  paymentsSearch={params.paymentsSearch}
                                  payment={params.payment}
                                  index={params.index}
                                  handleOnChangePayment={
                                    params.handleOnChangePayment
                                  }
                                  name={`payments.${params.index}.payment`}
                                  required={true}
                                />
                              ),
                            },
                            {
                              columns: 3,
                              renderCell: (
                                <InputShareNumber
                                  payment={params.payment}
                                  index={params.index}
                                  handleOnChangePayment={
                                    params.handleOnChangePayment
                                  }
                                  name={`payments.${params.index}.amount`}
                                />
                              ),
                            },
                            {
                              columns: 3,
                              renderCell: (
                                <InputAmount
                                  payment={params.payment}
                                  index={params.index}
                                  handleOnChangePayment={
                                    params.handleOnChangePayment
                                  }
                                  name={`payments.${params.index}.amount`}
                                  required={true}
                                />
                              ),
                            },
                          ];

                          if (
                            params?.payment?.accounting_concept ===
                            "Cruzar anticipo"
                          ) {
                            columns.splice(1, 0, {
                              columns: 3,
                              renderCell: (
                                <SelectAdvance
                                  paymentsAdvances={params.paymentsAdvances}
                                  payment={params.payment}
                                  index={params.index}
                                  handleOnChangePayment={
                                    params.handleOnChangePayment
                                  }
                                  name={`payments.${params.index}.advance`}
                                />
                              ),
                            });
                          } else {
                            columns.splice(1, 0, {
                              columns: 3,
                              renderCell: (
                                <InputDate
                                  payment={params.payment}
                                  index={params.index}
                                  handleOnChangePayment={
                                    params.handleOnChangePayment
                                  }
                                  name={`payments.${params.index}.date`}
                                />
                              ),
                            });
                          }

                          return columns;
                        },
                      },
                      {
                        type: "generalInformation",
                        name: "generalInformation",
                        label: "Información general",
                        cols: 12,
                        props: (props) => ({
                          content: props.content,
                          handleOnChangeGeneralTax:
                            props.handleOnChangeGeneralTax,
                          taxReteIva: props.search.taxReteIva,
                          taxReteIca: props.search.taxReteIca,
                          amountTotalNeto: props.amountTotalNeto,
                          isCaused: props.isCaused,
                          state: props.state,
                          allowReteIva: true,
                          allowReteIca: true,
                        }),
                      },
                    ],
                  },
                },
              },
            ],
          },
        },
      },
    ],
  },
};
