import React, { useEffect, useMemo } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import { Stack, TextField, Typography, Autocomplete, Box } from "@mui/material";
import { styles } from "../../../Table/styles";
import {
  documentType,
  serviceType,
  getAccountTypeLabel,
  minimumLengthForSearch,
  serviceTypeArray,
  permissions,
} from "../../../../constants";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { es } from "date-fns/locale";
import { WithoutBorderButton } from "../../../buttons";
import { objectIsEmpty } from "../../../../utils/objectUtils";
import FileUpload from "./FileUpload";
import InvoicesNotes from "./InvoicesNotes";
import SupplierDetailsInvoiceForm from "./supplierDetailsInvoiceForm";
import ServiceDetailsInvoiceForm from "./serviceDetailsInvoiceForm";
import SupplierIncompletedMessage from "./SupplierIncompletedMessage";
import { InvoiceDetail } from "./InvoiceDetail";
import { Show, TagsSelector } from "../../../components";
import isSupplierIncomplete from "../../../../screens/InvoicesScreen/isSupplierIncomplete";
import isSupplierInvoice from "../../../../screens/InvoicesScreen/isSupplierInvoice";
import { useIsUserAllowedTo } from "../../../../utils";
import { CalendarIcon } from "assets";
import CurrentAccount from "./CurrentAccount";
import { InvoiceTotalContainer } from "./InvoiceTotalContainer";

export const InvoiceForm = ({
  isPayanaInvoice,
  formValues,
  invoiceId,
  setFormValues,
  invoice,
  supplier,
  setSupplier,
  suppliers,
  setService,
  service,
  selectedFiles,
  setSelectedFiles,
  createdSupplier,
  setDrawerIsOpen,
  getOptions,
  approvationNeeded = false,
  tags,
  setTags,
  onSelect,
  onRemove,
  setTagsSelected,
  conceptError,
  setConceptError,
  collectionNumberError,
  setCollectionNumberError,
  openSupplierForm,
  refreshInvoiceData,
}) => {
  const { isUserAllowedTo } = useIsUserAllowedTo();
  const invoiceStatusIncludes = (...states) =>
    states.includes(invoice.status.toLowerCase());
  const location = useLocation();
  const supplierName = location?.state?.supplierName || createdSupplier?.name;
  const isMobile = useMediaQuery({ query: "(max-width: 481px)" });
  const hasCurrentAccount = invoice?.current_account?.length > 0;
  const currentAccount = (
    <Show condition={hasCurrentAccount}>
      <div style={styles.invoiceRow}>
        <CurrentAccount invoice={invoice} onSuccess={refreshInvoiceData} />
      </div>
    </Show>
  );
  useEffect(() => {
    if (!service && supplierName) {
      const supplierNew = suppliers.find(
        (supplier) => supplier?.name === supplierName
      );
      if (!objectIsEmpty(supplierNew)) {
        setFormValues({
          ...formValues,
          provider_id: supplier?.id,
        });
      }
    }
  }, [supplierName, suppliers]);

  const canModifySupplier =
    invoice.status === "pending" || invoice.status === "outstanding_balance";

  const handleOnChangeAutocomplete = (event, newValue) => {
    if (invoice?.type === serviceType && newValue?.id) {
      setFormValues({
        ...formValues,
        service_id: newValue.id,
      });
      setService(suppliers.find((supplier) => supplier.id === newValue.id));
    } else if (newValue?.id) {
      setFormValues({
        ...formValues,
        provider_id: newValue.id,
      });
      setSupplier(suppliers.find((supplier) => supplier.id === newValue.id));
    } else {
      setFormValues({
        ...formValues,
        provider_id: "",
      });
    }
  };

  const selectOptions = useMemo(
    () => (invoice?.type === serviceType ? serviceTypeArray : documentType),
    [invoice?.type]
  );

  const disable =
    invoice.status === "pending"
      ? false
      : invoice.status === "outstanding_balance"
      ? false
      : true;

  return (
    <>
      <Box
        sx={{
          minWidth: isMobile ? 360 : 544,
          maxWidth: isMobile ? 360 : 544,
          margin: "auto",
          marginBottom: "24px",
        }}
      >
        {invoiceStatusIncludes(
          "pending",
          "outstanding_balance",
          "to_be_approved",
          "to_be_checked"
        ) && isUserAllowedTo(permissions.INVOICES_UPDATE) ? (
          <>
            {approvationNeeded && invoice.approved && invoice.approved_by && (
              <Stack spacing={1}>
                <Typography sx={styles.invoiceCardTitleRevert}>
                  Aprobador
                </Typography>
                <TextField
                  size="small"
                  variant="outlined"
                  value={invoice.approved_by}
                  disabled={true}
                  style={{ marginBottom: "24px" }}
                />
              </Stack>
            )}
            <Stack spacing={1}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={styles.invoiceCardTitleRevert}>
                  Proveedor
                </Typography>
                {canModifySupplier && !isPayanaInvoice && (
                  <WithoutBorderButton
                    text="Crear nuevo"
                    action={() => setDrawerIsOpen(true)}
                    width="90px"
                  />
                )}
              </div>
              <Autocomplete
                size="small"
                options={suppliers}
                freeSolo
                getOptionLabel={(option) => option?.name || option?.alias || ""}
                onChange={handleOnChangeAutocomplete}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { fontSize: 14 },
                    }}
                    placeholder={"Ingresa el nombre"}
                  />
                )}
                value={
                  !objectIsEmpty(supplier)
                    ? supplier
                    : !objectIsEmpty(service)
                    ? service
                    : { name: "" }
                }
                disabled={!canModifySupplier || isPayanaInvoice}
                onInputChange={(event) => {
                  if (event?.target?.value?.length >= minimumLengthForSearch) {
                    getOptions(event?.target?.value);
                  }
                }}
              />
              {isSupplierInvoice(invoice) &&
              isSupplierIncomplete({ original: invoice }) ? (
                <SupplierIncompletedMessage
                  openSupplierForm={openSupplierForm}
                />
              ) : (
                formValues.provider_id && (
                  <SupplierDetailsInvoiceForm
                    bankName={supplier?.bank_name}
                    accountTypeTitle={
                      supplier
                        ? supplier.account_type
                          ? getAccountTypeLabel(supplier.account_type)
                          : "Número de celular"
                        : "Tipo de cuenta"
                    }
                    accountNumber={supplier?.account_number}
                    documentType={supplier?.document_type}
                    documentNumber={supplier?.document_number}
                    email={supplier?.email}
                  />
                )
              )}

              {formValues.service_id && (
                <ServiceDetailsInvoiceForm
                  referralCode={service?.referral_code}
                />
              )}
            </Stack>
            <hr style={styles.separator} />
            <div style={styles.invoiceRow}>
              <div style={styles.invoiceColumn}>
                <Stack spacing={1}>
                  <Typography sx={styles.invoiceCardTitle}>
                    Número de comprobante
                  </Typography>
                  <TextField
                    size="small"
                    variant="outlined"
                    onChange={(e) => {
                      setFormValues({
                        ...formValues,
                        invoice_number: e.target.value,
                      });
                      if (e?.target?.value?.length === 0) {
                        setCollectionNumberError({
                          helperText: "Este campo no puede estar vacío",
                          error: true,
                        });
                      } else {
                        if (!e.target.value.match(/^[A-Za-z0-9_-]+$/)) {
                          setCollectionNumberError({
                            helperText: "El formato del campo no es válido",
                            error: true,
                          });
                        } else {
                          setCollectionNumberError({
                            helperText: "",
                            error: false,
                          });
                        }
                      }
                    }}
                    value={formValues.invoice_number}
                    InputProps={{
                      style: { fontSize: 14 },
                    }}
                    error={collectionNumberError.error}
                    disabled={isPayanaInvoice}
                  />
                  {collectionNumberError.error && (
                    <Typography sx={styles.inputError} noWrap>
                      {collectionNumberError.helperText}
                    </Typography>
                  )}
                </Stack>
              </div>
            </div>
            <div style={styles.invoiceRow}>
              <div style={styles.invoiceColumn}>
                <Stack spacing={1} sx={{ marginRight: "12px" }}>
                  <Typography sx={styles.invoiceCardTitle}>
                    Fecha de emisión
                  </Typography>
                  <LocalizationProvider
                    adapterLocale={es}
                    dateAdapter={AdapterDateFns}
                  >
                    <DatePicker
                      disabled={isPayanaInvoice}
                      value={formValues.issue_date}
                      format="dd/MM/yyyy"
                      onChange={(value) => {
                        setFormValues({
                          ...formValues,
                          issue_date: value,
                        });
                      }}
                      slotProps={{
                        textField: {
                          InputProps: {
                            style: { fontSize: 14 },
                          },
                          size: "small",
                          error: false,
                        },
                      }}
                      slots={{
                        openPickerIcon: () => <CalendarIcon stroke="#667085" />,
                      }}
                    />
                  </LocalizationProvider>
                </Stack>
              </div>
              <div style={styles.invoiceColumn}>
                <Stack spacing={1}>
                  <Typography sx={styles.invoiceCardTitle}>
                    Fecha de vencimiento
                  </Typography>
                  <LocalizationProvider
                    adapterLocale={es}
                    dateAdapter={AdapterDateFns}
                  >
                    <DatePicker
                      disabled={isPayanaInvoice}
                      value={formValues.expiration_date}
                      format="dd/MM/yyyy"
                      onChange={(value) => {
                        setFormValues({
                          ...formValues,
                          expiration_date: value,
                        });
                      }}
                      slotProps={{
                        textField: {
                          InputProps: {
                            style: { fontSize: 14 },
                          },
                          size: "small",
                          error: false,
                        },
                      }}
                      slots={{
                        openPickerIcon: () => <CalendarIcon stroke="#667085" />,
                      }}
                    />
                  </LocalizationProvider>
                </Stack>
              </div>
            </div>
            <div style={styles.invoiceRow}>
              <div style={styles.invoiceColumn}>
                <Stack spacing={1}>
                  <Typography sx={styles.invoiceCardTitle}>Concepto</Typography>
                  <TextField
                    size="small"
                    variant="outlined"
                    onChange={(e) => {
                      setFormValues({
                        ...formValues,
                        concept: e.target.value,
                      });
                      if (e?.target?.value?.length === 0) {
                        setConceptError({
                          helperText: "",
                          error: false,
                        });
                      } else {
                        if (!e.target.value.match(/^[\p{L}\p{N}\p{Zs}]+$/gmu)) {
                          setConceptError({
                            helperText: "El formato del campo no es válido",
                            error: true,
                          });
                        } else {
                          setConceptError({
                            helperText: "",
                            error: false,
                          });
                        }
                      }
                    }}
                    value={formValues.concept}
                    placeholder="Ej: Anticipo por reparación maquinaria"
                    InputProps={{
                      style: { fontSize: 14 },
                    }}
                    error={conceptError.error}
                  />
                  {conceptError.error && (
                    <Typography sx={styles.inputError} noWrap>
                      {conceptError.helperText}
                    </Typography>
                  )}
                </Stack>
              </div>
            </div>
            <div style={styles.invoiceRow}>
              <div style={styles.invoiceColumn}>
                <Stack spacing={1}>
                  <Typography sx={styles.invoiceCardTitle}>
                    Etiquetado
                  </Typography>
                  <TagsSelector
                    options={tags}
                    selectedValues={invoice.tags}
                    onSelect={onSelect}
                    onRemove={onRemove}
                    searchBoxStyle={{ borderRadius: "8px" }}
                    inputFieldStyle={{
                      fontSize: "14px",
                      marginLeft: "6px",
                      marginBottom: "2px",
                      color: "black",
                    }}
                    disable={disable}
                    setTags={setTags}
                    setTagsSelected={setTagsSelected}
                    disablePlaceholder={disable}
                    className={"checkbox"}
                  />
                </Stack>
              </div>
            </div>
            {currentAccount}
            <div style={styles.invoiceRow}>
              <div style={styles.invoiceColumn}>
                <Stack spacing={1}>
                  <Typography sx={styles.invoiceCardTitle}>
                    {invoice.file_path
                      ? "Reemplazar documento"
                      : "Agregar documento"}
                  </Typography>
                  <FileUpload
                    selectedFiles={selectedFiles}
                    setSelectedFiles={setSelectedFiles}
                    isInvoiceFile={true}
                  />
                </Stack>
              </div>
            </div>
            <hr style={styles.separator} />
            <Show condition={!hasCurrentAccount}>
              <InvoiceTotalContainer invoice={invoice} />
            </Show>
          </>
        ) : (
          <InvoiceDetail
            invoice={invoice}
            approvationNeeded={approvationNeeded}
            supplier={supplier}
            currentAccount={currentAccount}
          />
        )}
      </Box>
      <InvoicesNotes invoiceId={invoiceId} />
    </>
  );
};
