import { NumericFormat } from "react-number-format";
import { renderToString } from "react-dom/server";
import { styles } from "../../../Table/styles";

export const InvoiceTotalContainer = ({ invoice }) => {
  return (
    <div style={styles.invoiceTotalsContainer}>
      <div style={styles.invoiceTotalsRow}>
        <div style={styles.invoiceTotalsColumn}>
          <p
            style={styles.invoiceTotalsPrimaryText}
            className="card-subtitle mb-2"
          >
            Saldo pendiente
          </p>
        </div>
        <div style={styles.invoiceTotalsColumn}>
          <p
            style={styles.invoiceTotalsPrimaryText}
            className="card-subtitle mb-2"
          >
            <NumericFormat
              value={parseInt(renderToString(invoice.amount_total))}
              displayType="text"
              thousandSeparator="."
              decimalSeparator=","
              prefix="$"
              decimalScale={2}
            />
          </p>
        </div>
      </div>
    </div>
  );
};
