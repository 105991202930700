import React from "react";
import { styles } from "./styles";
import { SUBSCRIPTION_TYPE } from "constants";

export const AdviserDisclaimer = ({ type }) => {
  return (
    <div style={styles.contactHelpCausationContainer}>
      <p style={styles.contactHelpCausationText}>
        Nuestro asesor se pondrá en contacto contigo para presentarte la
        propuesta comercial.
      </p>
      <p style={styles.contactHelpCausationText}>
        Al continuar, deberás cargar tu RUT para seguir{" "}
        {type === SUBSCRIPTION_TYPE.CAUSATION ? "causando" : "pagando"}. Tu
        primera factura se generará en el próximo ciclo de facturación.
      </p>
    </div>
  );
};
