import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import { INITIAL_ITEM_VALUES } from "../../constants";
import { MagicWandIcon } from "assets";
import { styles } from "./style-multiple-selected";

export const InputsSelect = ({
  products,
  taxesIva,
  taxesRete,
  getSearchOptionsProducts,
  handleChangeMultipleSelect,
  numberItemSelected = 0,
  hasCompanyWareHouse,
  multipleSelectColumns,
  costCenter,
}) => {
  return (
    <div
      style={{
        marginBottom: "8px",
        borderRadius: "8px",
        backgroundColor: "#F9FAFB",
        border: "1px solid #BDB4FE",
        padding: "16px",
        height: "112px",
        display: "flex",
        gap: "8px",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          flexDirection: "row",
        }}
      >
        <MagicWandIcon />
        <p style={styles.buttonOpenInputsText}>
          Asigna el mismo valor a varios productos -
        </p>

        <p
          style={{
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "20px",
            color: "#6938EF",
            marginBottom: "1px",
            marginLeft: "-4px",
          }}
        >
          {`(${numberItemSelected}) ítems seleccionados`}
        </p>
      </div>
      <DataGrid
        id={"select-multiple"}
        hideHeader
        columns={multipleSelectColumns()}
        rows={[{ ...INITIAL_ITEM_VALUES }]}
        hideFooter={true}
        sx={{
          "& .cell-hidden": {
            visibility: "hidden",
          },
          "& .MuiDataGrid-root .MuiDataGrid-main": {
            border: "none",
          },
          "& .MuiDataGrid-row": {
            maxHeight: "40px !important",
            minHeight: "40px !important",
          },
          "& .MuiDataGrid-cell": {
            maxHeight: "40px !important",
            minHeight: "40px !important",
            backgroundColor: "#ffffff",
          },
          "& .MuiDataGrid-columnHeaders": {
            display: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            marginTop: "0px !important",
          },
          "& .MuiDataGrid-cell:before, & .MuiDataGrid-cell:after": {
            content: "none",
          },
          "& .MuiDataGrid-cell:hover": {
            background: "transparent",
            cursor: "default",
          },
          "& .MuiDataGrid-row.Mui-selected": {
            background: "transparent",
          },
          "& .MuiDataGrid-row.Mui-selected:hover": {
            background: "transparent",
          },
          "& .MuiDataGrid-row:hover": {
            backgroundColor: "inherit",
          },
          "& .MuiDataGrid-row.Mui-hovered": {
            backgroundColor: "inherit",
          },
        }}
        style={{
          border: "none",
        }}
      />
    </div>
  );
};
