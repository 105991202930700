import React from "react";
import { IconButton } from "@mui/material";
import { DeleteIcon } from "assets";
import { styles } from "./styles";

export const PaymentRow = ({
  paymentsSelected,
  handleOnChangePayment,
  paymentsSearch,
  paymentsAdvances,
  handleDeletePayment,
  columns,
  control,
  isCaused,
  isSuggested,
  setValue,
}) => {
  const params = {
    paymentsSearch,
    paymentsAdvances,
    handleOnChangePayment,
    control,
  };

  return (
    <div style={styles.container}>
      {paymentsSelected.map((payment, index) => (
        <div key={index} style={styles.row}>
          {columns({ ...params, payment }).map((column, columnIndex) => (
            <div
              key={columnIndex}
              style={{
                ...styles.column,
                flex: column.columns,
              }}
            >
              {React.cloneElement(column.renderCell, {
                payment,
                index,
                handleOnChangePayment,
                paymentsSearch,
                paymentsAdvances,
                control,
                name: column.name,
                isCaused,
                isSuggested: isSuggested && index === 0,
                setValue,
              })}
            </div>
          ))}
          <div style={styles.deleteButton}>
            {paymentsSelected.length > 1 && !isCaused && (
              <IconButton onClick={() => handleDeletePayment(index)}>
                <DeleteIcon />
              </IconButton>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
