import React, { useState } from "react";
import { ConnectorIcon, CrownIcon, MoneyFinishedIcon } from "assets";
import { PrimaryButton } from "commons/buttons";
import {
  ContactHelp,
  Footer,
  CardInformation,
  SubscriptionTitle,
  SubscriptionIcon,
} from "./";
import { TRIAL_TITLE, AFTER_TRIAL_SUBSCRIPTION_PRICE } from "constants";
import { styles } from "./styles";
import { MoneyCurrentStepIcon } from "assets/svg/MoneyCurrentStepIcon";
import { toast } from "react-toastify";
import { toastOptions } from "constants";
import * as SubscriptionService from "services/api/subscription";

export const ModalTrialSuscriber = ({ type, subscription }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleActiveTrial = async () => {
    try {
      setIsLoading(true);
      const body = {
        featureType: type,
      };
      const respTrial = await SubscriptionService.activateTrial(body);
      if (respTrial === `Subscription with type ${type} store success`) {
        subscription?.getMe();
        subscription?.hadleCloseModalSubscription();
        subscription?.callback();
      } else if (respTrial === "Subscriptions store failed") {
        toast.error(
          "No pudimos iniciar tu subscripcion gratuita",
          toastOptions
        );
      }
    } catch (error) {
      toast.error("Ocurrió un error al activar la prueba gratis", toastOptions);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <SubscriptionIcon icon={<CrownIcon size="medium" />} />
      <SubscriptionTitle title={TRIAL_TITLE[type]} />
      <div style={styles.cardContainer}>
        <CardInformation type={type} />
        <p style={{ ...styles.cardInformationTitle, marginTop: "24px" }}>
          ¿Cómo funciona la prueba gratis?
        </p>
        <div style={styles.journeyRow}>
          <div style={styles.column}>
            <MoneyCurrentStepIcon />
            <div style={{ marginTop: "6px" }}>
              <ConnectorIcon color="#6938EF" height="54" viewBox="0 0 3 54" />
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ ...styles.journeyRow, gap: "6px" }}>
              <p style={styles.journeyTitle}>
                Hoy: activamos tu período de prueba gratis.
              </p>
            </div>
            <p style={styles.journeyText}>
              Accede de forma gratuita y sin compromiso a nuestra funcionalidad
              Premium, no te cobraremos nada.
            </p>
          </div>
        </div>
        <div style={{ ...styles.journeyRow, marginLeft: "2px" }}>
          <MoneyFinishedIcon />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ ...styles.journeyRow, gap: "6px" }}>
              <p style={styles.journeyTitle}>
                En 14 días: termina tu período de prueba gratis.
              </p>
            </div>
            <p style={styles.journeyText}>
              Selecciona como quieres pagar y continúa utilizando la solución
              ilimitadamente.
            </p>
          </div>
        </div>
        <Footer>
          <div>
            <PrimaryButton
              text={"Iniciar prueba gratis"}
              style={{ width: "100%", fontSize: "14px" }}
              action={handleActiveTrial}
              loading={isLoading}
            />
            {AFTER_TRIAL_SUBSCRIPTION_PRICE[type] && (
              <span
                style={{
                  color: "#697586",
                  fontSize: "10px",
                  fontWeight: 500,
                }}
              >
                {AFTER_TRIAL_SUBSCRIPTION_PRICE[type]}
              </span>
            )}
          </div>
          <ContactHelp type={type} isTrial={true} />
        </Footer>
      </div>
    </>
  );
};
