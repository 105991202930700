import { Typography } from "@mui/material";
import { UpdateIcon } from "assets";
import { ButtonWithIcon, SyncBanner } from "commons/components";
import React, { useEffect, useState } from "react";
import { Spinner, Stack, Modal } from "react-bootstrap";
import { formatLocaleDate, handleSyncErpResponse } from "utils";
import { checkSiigoCredential, syncCausation } from "services";
import * as CausationService from "services/api/causation";
import { searchType } from "../../constants";
import { styles } from "./style";
import { urlBaseGoogleSheet } from "constants";
import { SecondaryButton } from "commons/buttons";

export const SyncInformation = ({
  hasErpCausation,
  lastSyncCausationAt,
  formValues,
  handleOnChangeAutoComplete,
  setSupplierFound,
  causationScreen,
  currentCompany,
  paramsText = "Parámetros",
  logo = null,
  updateText = "Última actualización",
  disabled,
}) => {
  const [synchronizingErp, setSynchronizingErp] = useState(false);
  const [lastSyncDate, setLastSyncDate] = useState(lastSyncCausationAt);
  const [errors, setErrors] = useState(null);
  const [modalShowErrors, setModalShowErrors] = useState(false);

  const syncBanner = causationScreen?.syncBanner?.value;
  const setSyncBanner = causationScreen?.syncBanner?.setter;

  const syncErp = () => {
    setSynchronizingErp(true);
    syncCausation().then((res) => {
      handleSyncErpResponse(res, onSuccess, setSyncBanner);
      setErrors(res?.errors);
      setSynchronizingErp(false);
    });
  };
  const onSuccess = () => {
    setSynchronizingErp(false);
    checkSiigoCredential().then((res) => {
      setLastSyncDate(res?.syncValidation?.lastSyncCausationAt);
      updateSiigoSupplier();
    });
  };

  const updateSiigoSupplier = async () => {
    if (formValues.supplier.document_number) {
      const res = await CausationService.search(
        searchType.supplier,
        formValues.supplier.document_number
      );
      if (res.at(0)?.id) {
        await handleOnChangeAutoComplete("supplier", res.at(0));
      }
      const storedSupplier = await CausationService.searchErpSupplier({
        documentNumber: formValues.supplier.document_number,
      });
      setSupplierFound(storedSupplier);
    }
  };

  useEffect(() => {
    updateSiigoSupplier();
  }, []);

  const openSheetParams = () => {
    window.open(
      `${urlBaseGoogleSheet}${currentCompany?.erp_sheet_id}`,
      "_blank"
    );
  };

  return (
    <Stack spacing={1}>
      <div style={styles.container}>
        <div style={styles.flexRow}>
          <div style={styles.siigoLogo}>{logo}</div>
          {lastSyncDate && (
            <div style={styles.lastSyncDate}>
              <Typography sx={styles.lastUpdateText}>{updateText}</Typography>
              <Typography sx={styles.dateText}>
                {formatLocaleDate(lastSyncDate, "d MMM yyyy, HH:mm")}
              </Typography>
            </div>
          )}
          {hasErpCausation && (
            <div style={styles.lastSyncDate}>
              <Typography
                sx={styles.sheetText}
                onClick={() => openSheetParams()}
              >
                {paramsText}
              </Typography>
            </div>
          )}
        </div>
        {synchronizingErp ? (
          <Spinner
            style={styles.spinner}
            animation="border"
            size="sm"
            variant="primary"
          />
        ) : !disabled ? (
          <ButtonWithIcon
            buttonLabel={"Actualizar"}
            icon={<UpdateIcon stroke="#5925DC" />}
            width="fit-content"
            style={styles.updateButton}
            action={() => {
              setSynchronizingErp(true);
              syncErp();
            }}
            innerTextStyle={{ color: "#5925DC" }}
          />
        ) : null}
      </div>
      {syncBanner?.visible && (
        <SyncBanner
          title={syncBanner.title}
          description={syncBanner.description}
          type={syncBanner.type}
          setSyncBanner={setSyncBanner}
          customStyle={styles.syncBanner}
          errors={errors}
          handleSetModalShowErrors={(value) => setModalShowErrors(value)}
        />
      )}
      {syncBanner?.visible && errors && (
        <ErrorModal
          show={modalShowErrors}
          onHide={() => setModalShowErrors(false)}
          errors={errors}
        />
      )}
    </Stack>
  );
};

const ErrorModal = ({ show, onHide, errors }) => (
  <Modal show={show} onHide={onHide} centered>
    <Modal.Header
      closeButton
      style={{
        backgroundColor: "#f8f9fa",
        borderBottom: "1px solid #dee2e6",
      }}
    >
      <Modal.Title style={{ color: "#343a40" }}>
        Errores de sincronización
      </Modal.Title>
    </Modal.Header>
    <Modal.Body style={{ padding: "20px", backgroundColor: "#ffffff" }}>
      {Object.entries(errors).map(([category, messages]) => (
        <div key={category} style={{ marginBottom: "15px" }}>
          <h6 style={{ marginBottom: "10px" }}>{category}</h6>
          <ul
            style={{
              paddingLeft: "20px",
              fontSize: "12px",
              listStyleType: "none",
            }}
          >
            {messages.map((message, index) => (
              <li key={index} style={{ marginBottom: "5px", color: "#495057" }}>
                {message}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </Modal.Body>
    <Modal.Footer
      style={{ backgroundColor: "#f8f9fa", borderTop: "1px solid #dee2e6" }}
    >
      <SecondaryButton text="Cerrar" action={onHide} />
    </Modal.Footer>
  </Modal>
);
