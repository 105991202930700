import { Autocomplete, Box, TextField } from "@mui/material";
import React from "react";
import { isAdvance, typePaymentOnChange } from "../../constants";
import { Controller } from "react-hook-form";
import { Suggestion } from "../Suggestion";

export const PaymentSelect = ({
  paymentsSearch,
  payment,
  handleOnChangePayment,
  index,
  control,
  name = `payments.${index}.payment`,
  isCaused,
  isSuggested,
  setValue,
  required = false,
}) => {
  const getOptionLabel = (option) => {
    if (!option) return "";
    if (option?.accounting_concept === isAdvance) return "Cruzar anticipo";
    if (option?.name && option?.accounting_concept) {
      return `${option.name} - ${option.accounting_concept}`;
    }
    if (option?.accounting_concept) return option.accounting_concept;
    return "";
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={payment || null}
      rules={{
        required: required ? "Este campo no puede quedar incompleto." : false,
        validate: () => {
          if (
            !payment ||
            typeof payment !== "object" ||
            !payment.accounting_concept ||
            payment.accounting_concept === ""
          ) {
            return "Este campo no puede quedar incompleto.";
          }
          return true;
        },
      }}
      render={({ field: { onChange, value, ref }, fieldState: { error } }) => {
        return (
          <Autocomplete
            size="small"
            options={paymentsSearch}
            value={payment || null}
            onChange={(_, newValue) => {
              onChange(newValue);
              handleOnChangePayment(
                index,
                newValue,
                typePaymentOnChange.payment
              );
              if (index === 0) {
                setValue("isSuggested.paymentMethod", false, {
                  shouldValidate: true,
                });
              }
            }}
            disabled={isCaused}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={(option, value) => {
              if (!option || !value) return false;
              return option.accounting_concept === value.accounting_concept;
            }}
            renderOption={(props, option) => (
              <li
                {...props}
                key={option?.accounting_concept}
                style={{ fontSize: "14px" }}
              >
                {option?.name
                  ? `${option.name} - ${option.accounting_concept}`
                  : option.accounting_concept}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                inputRef={ref}
                error={!!error}
                helperText={error?.message}
                required
                InputProps={{
                  ...params.InputProps,
                  style: { fontSize: 14 },
                  ...(isSuggested && {
                    startAdornment: (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                          mr: 2,
                          backgroundColor: "transparent",
                        }}
                      >
                        <Suggestion />
                      </Box>
                    ),
                  }),
                }}
                placeholder="Ingresa la forma de pago"
                sx={{
                  "& .MuiAutocomplete-input": {
                    fontSize: "14px",
                    color: isSuggested ? "#6938EF" : "inherit",
                    fontWeight: isSuggested ? "500" : "inherit",
                  },
                  ...(isSuggested && {
                    backgroundColor: "#FAFAFF",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#BDB4FE",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#BDB4FE",
                    },
                  }),
                }}
              />
            )}
            sx={{
              backgroundColor: "#FFF",
              borderRadius: "8px",
              width: "100%",
            }}
          />
        );
      }}
    />
  );
};
