import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { styles } from "./styles";
import { useNavigate } from "react-router-dom";
import { CausationContext } from "contexts";
import SuccessCausation from "./SuccessCausation";
import ErrorCausation from "./ErrorCausation";
import Loading from "./Loading";

export function CausationResponseModal({
  open,
  type,
  text = "",
  statusCode,
  onClose = () => {},
  id,
  supportDocument = false,
  checkSupportDocument = false,
}) {
  const navigate = useNavigate();
  const { hasErpCausation, handleNextInvoice } = useContext(CausationContext);

  const [modalData, setModalData] = useState({
    title: "No pudimos causar tu factura.",
    description: "Por favor, inténtalo de nuevo en unos minutos.",
    twoButtons: false,
  });

  const action = {
    success: () => {
      onClose();
      navigate("/payments/invoices", { replace: true });
    },
    error: onClose,
  };

  const handleErrorMessage = {
    CAUSATION_ERROR: () => {
      setModalData({
        title: "No pudimos causar tu factura.",
        description: "Por favor, inténtalo de nuevo en unos minutos.",
        twoButtons: false,
      });
    },
    SERVICE_SIIGO_ERROR: () => {
      setModalData({
        title: "No pudimos causar tu factura.",
        description:
          "Siigo está con intermitencias. Por favor, vuelve a intentar de nuevo en unos minutos.",
        twoButtons: false,
      });
    },
    DUPLICATE_INVOICE: () => {
      setModalData({
        title: "El comprobante ya existe",
        description:
          "Para poder causar tu factura, es necesario que ingreses en Siigo y edites el consecutivo.",
        twoButtons: false,
      });
    },
    INVALID_OBJECT: () => {
      setModalData({
        title: "No pudimos procesar tu factura.",
        description:
          "Existen diferencias entre los valores de débito y crédito. Nuestro equipo está trabajando en solucionarlo. Por favor, inténtalo nuevamente en unos minutos.",
        twoButtons: false,
      });
    },
    NUMBERS_DIFFERENTS: () => {
      setModalData({
        title: "No pudimos causar tu factura.",
        description: "Por favor, inténtalo de nuevo en unos minutos.",
        twoButtons: false,
      });
    },
    EXPIRED_SUBSCRIPTION: () => {
      setModalData({
        title: "Suscripción vencida en Siigo.",
        description:
          "Para poder causar tu factura, es necesario que actualices la suscripción en Siigo.",
        twoButtons: false,
      });
    },
    METHOD_PAYMENT_UNABLED: () => {
      setModalData({
        title: "El método de pago no está activo en Siigo.",
        description:
          "Para poder causar tu factura, es necesario que actualices el método de pago en Siigo.",
        twoButtons: false,
      });
    },
    UPDATE_SIIGO_INFO: () => {
      setModalData({
        title: "No pudimos causar tu factura.",
        description:
          "Para poder causar tu factura, es necesario que actualices tu información en Siigo.",
        twoButtons: false,
      });
    },
  };

  useEffect(() => {
    if (open && text) handleErrorMessage[text]?.();
  }, [open, text]);

  const modalViews = {
    success: (
      <SuccessCausation
        onClose={action[type]}
        statusCode={statusCode}
        hasErpCausation={hasErpCausation}
        handleNextInvoice={handleNextInvoice}
        onCloseModal={onClose}
        id={id}
        supportDocument={supportDocument}
        checkSupportDocument={checkSupportDocument}
      />
    ),
    error: (
      <ErrorCausation onClose={action[type]} modalData={modalData} id={id} />
    ),
    loading: <Loading hasErpCausation={hasErpCausation} />,
  };

  return (
    <Modal show={open} onClose={onClose} centered>
      <Modal.Body style={styles.body}>{modalViews[type]}</Modal.Body>
    </Modal>
  );
}
