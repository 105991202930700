import React, { useContext, useState } from "react";
import { CrownIcon } from "assets";
import { PrimaryButton } from "commons/buttons";
import {
  ContactHelp,
  Footer,
  CardInformation,
  SubscriptionTitle,
  SubscriptionIcon,
  TransactionCost,
} from "./";
import { TITLE, stepsBilling, SUBSCRIPTION_TYPE } from "constants";
import { UserContext } from "contexts";
import { AdviserDisclaimer } from "./AdviserDisclaimer";

export const ModalSuscriber = ({
  type,
  handleChangeStep,
  createSubscription,
}) => {
  const { currentCompany } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(false);

  const handleSubscription = async () => {
    setIsLoading(true);
    await createSubscription();
    setIsLoading(false);
  };

  const handleSubscriptionV2 = async () => {
    if (!currentCompany?.billingId) {
      handleChangeStep(stepsBilling.billing);
    } else {
      handleSubscription();
    }
  };

  return (
    <>
      <SubscriptionIcon icon={<CrownIcon size="medium" />} />
      <SubscriptionTitle title={TITLE[type]} />
      <div
        style={{
          width: "474px",
          padding: "16px",
          borderRadius: "8px",
          border: "1px solid #D0D5DD",
        }}
      >
        <CardInformation type={type} />
        {SUBSCRIPTION_TYPE.TREASURY === type && <TransactionCost />}
        <Footer>
          <AdviserDisclaimer type={type} />
          <PrimaryButton
            text={"Continuar"}
            style={{ width: "100%", fontSize: "14px" }}
            action={handleSubscriptionV2}
            loading={isLoading}
          />
          {type !== SUBSCRIPTION_TYPE.CAUSATION && <ContactHelp type={type} />}
        </Footer>
      </div>
    </>
  );
};
